import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

const ArrowLeft = ({
  disabled,
  hidden
}: {
  disabled: boolean;
  hidden: boolean;
}) => (
  <svg
    className={cx(styles.Arrow, { [styles.Disabled]: disabled })}
    width="10"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.234.418l1.132 1.164L3.132 8l6.234 6.418-1.132 1.164L.87 8z"
      fill="#FFF"
      className={cx(styles.Arrow, {
        [styles.Disabled]: disabled,
        [styles.Hidden]: hidden
      })}
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowLeft;
