import React from "react";
import HeadIcon from "./HeadIcon";
import HandIcon from "./HandIcon";
import HeartIcon from "./HeartIcon";

//import { DimensionType } from "../../types";

type PropsType = {
  dimension: string;
};

const DimensionIcon = ({ dimension }: PropsType) => {
  if (dimension === "head") {
    return <HeadIcon />;
  } else if (dimension === "hand") {
    return <HandIcon />;
  } else return <HeartIcon />;
};

export default DimensionIcon;
