import React from "react";

const IconChecked = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient
        x1="20.053%"
        y1="4.135%"
        x2="69.512%"
        y2="95.859%"
        id="gradient"
      >
        <stop stopColor="#57F1DC" offset="0%" />
        <stop stopColor="#FFF" offset="100%" />
      </linearGradient>
    </defs>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <circle stroke="#FFF" cx="7" cy="7" r="7" />
      <circle fill="url(#gradient)" cx="7" cy="7" r="5" />
    </g>
  </svg>
);

export default IconChecked;
