import React from "react";
import cx from "classnames";

import { SmallSerifHeading } from "../../Headings";
import Text from "../../Text";

import DimensionIcon from "../../DimensionIcon";

import { OverviewConsumer } from "../../../contexts/Overview";

import { skillGroupHoverHex } from "../../../helpers/visualizationStyles";

import styles from "./index.module.scss";

const LegendPanel = () => {
  const { hoveredNode, skillSetType } = React.useContext(OverviewConsumer);
  let hoverColor = skillGroupHoverHex;

  if (hoveredNode) {
    hoverColor = hoveredNode.color;
  }

  return (
    <div className={styles.Wrapper}>
      <h3 className={styles.Legend}>Legend</h3>

      <div className={styles.FluencySection}>
        <SmallSerifHeading>Fluency Levels</SmallSerifHeading>
        <div className={styles.LegendItem}>
          <div
            className={cx(styles.Icon, styles.FluencyIcon, styles.Fluency100)}
            style={{ backgroundColor: hoverColor }}
          />
          <Text className={styles.Text}>50% - 100% Fluency</Text>
        </div>

        <div className={styles.LegendItem}>
          <div
            className={cx(styles.Icon, styles.FluencyIcon, styles.Fluency50)}
            style={{ backgroundColor: hoverColor }}
          />
          <Text className={styles.Text}>1 - 50% Fluency</Text>
        </div>

        <div className={styles.LegendItem}>
          <div
            className={cx(styles.Icon, styles.FluencyIcon, styles.Fluency20)}
            style={{ backgroundColor: hoverColor }}
          />
          <Text className={styles.Text}>0% Fluency</Text>
        </div>
      </div>
      {skillSetType === "leap" && (
        <div className={cx(styles.DomainSection)}>
          <SmallSerifHeading>Skill Labels</SmallSerifHeading>
          <div className={styles.LegendItem}>
            <DimensionIcon dimension="head" />
            <Text className={styles.Text}>Head</Text>
          </div>
          <div className={styles.LegendItem}>
            <DimensionIcon dimension="hand" />
            <Text className={styles.Text}>Hand</Text>
          </div>
          <div className={styles.LegendItem}>
            <DimensionIcon dimension="heart" />

            <Text className={styles.Text}>Heart</Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default LegendPanel;
