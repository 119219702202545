import React, { useState } from "react";
import cx from "classnames";

import styles from "./index.module.scss";

import ArrowRight from "./Arrows/ArrowRight";
import ArrowLeft from "./Arrows/ArrowLeft";
import { OverviewConsumer } from "../../contexts/Overview";
import { TinySansSerifHeading } from "../Headings";

const DataUpdatedCard = () => {
  const {
    skillData,
    leapVersion,
    setLeapVersion,
    leapVersions,
    exponentialVersion,
    setExponentialVersion,
    exponentialVersions,
    skillSetType
  } = React.useContext(OverviewConsumer);

  const [transitionState, setTransitionState] = useState("");

  if (!skillData) {
    return null;
  }

  let dataVersion = leapVersion;
  let setDataVersion = setLeapVersion;
  let dataVersions = leapVersions;

  if (skillSetType === "exponential") {
     dataVersion = exponentialVersion;
     setDataVersion = setExponentialVersion;
     dataVersions = exponentialVersions;
  }

  return (
    <div className={styles.Card}>
      <TinySansSerifHeading className={styles.Heading}>
        Data set
      </TinySansSerifHeading>
      <span className={styles.Toggle}>
        <button
          className={styles.Button}
          onClick={() => {
            setTransitionState("out-right");
            setTimeout(() => {
              (dataVersion > 1 ) ? setDataVersion(dataVersion - 1) : '';
              setTransitionState("in");
            }, 500);
          }}
        >
          <ArrowLeft
            hidden={false}
            disabled={dataVersion === 1}
          />
          <span className={dataVersion === dataVersions ? styles.ButtonGlow : ""} />
        </button>
        <span className={styles.ToggleWrapper}>
          <span
            className={cx(styles.Data, {
              [styles.TransitionOutRight]:
                dataVersion === dataVersions && transitionState === "out-right",
              [styles.TransitionOutLeft]:
                dataVersion === 1 && transitionState === "out-left",
              [styles.TransitionIn]: transitionState === "in"
            })}
          >
            {skillData.dataUpdated}
          </span>
        </span>
        <button
          className={styles.Button}
          onClick={() => {
            setTransitionState("out-left");
            setTimeout(() => {
              (dataVersion < dataVersions ) ? setDataVersion(dataVersion + 1) : '';
              setTransitionState("in");
            }, 500);
          }}
        >
          <ArrowRight
            hidden={false}
            disabled={dataVersion === dataVersions}
          />
          <span className={dataVersion === 1 ? styles.ButtonGlow : ""} />
        </button>
      </span>
    </div>
  );
};

export default DataUpdatedCard;
