export const exponentialTechHex = "#B492E7";
export const skillGroupHoverHex = "#EBF2F1";
export const headHex = "#1A6D86";
export const handHex = "#46BFBE";
export const heartHex = "#98C9CA";
const neutralHex = "#48415D";

export function getLegendColor(node: any, isEmergingTech: boolean) {
  if (node) {
    if (isEmergingTech && !!node.children) {
      return exponentialTechHex;
    } else return node.hoverColor;
  } else {
    if (!isEmergingTech) {
      return skillGroupHoverHex;
    } else return exponentialTechHex;
  }
}

export function getNeutralStyle() {
  return {
    fill: neutralHex,
    fillOpacity: 1
  };
}

export function getFluencyStyle(skillWeight: number) {
  return {
    fill: skillGroupHoverHex,
    fillOpacity: getOpacity(skillWeight)
  };
}

export function getHeadHandHeartFluencyStyle(
  skillWeight: number,
  domain: string
) {
  return {
    fill: domain !== "" ? getHeadHandHeartColor(domain) : exponentialTechHex,
    fillOpacity: getOpacity(skillWeight)
  };
}

export function getExponentialTechStyle(skillWeight: number) {
  return {
    fill: exponentialTechHex,
    fillOpacity: getOpacity(skillWeight)
  };
}

export function getHeadHandHeartColor(domain: string) {
  switch (domain) {
    case "head":
      return headHex;
    case "hand":
      return handHex;
    case "heart":
      return heartHex;
    default:
      return neutralHex;
  }
}

export function getOpacity(skillWeight: number) {
  if (skillWeight === 1) {
    return 0.4;
  } else if (skillWeight === 2) {
    return 0.6;
  } else {
    return 1;
  }
}
