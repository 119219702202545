import React from "react";

import styles from "./index.module.scss";

const Chevron = () => {
  return (
    <svg
      className={styles.Svg}
      width="7"
      height="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.286.857L5.57 6l-4.285 5.143"
        stroke="#57F1DC"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Chevron;
