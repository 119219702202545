import React from "react";
import cx from "classnames";

import { Redirect } from "react-router-dom";

import { OverviewConsumer } from "../../../contexts/Overview";
import { DetailsConsumer } from "../../../contexts/Details";

import SkillDetailsCard from "./SkillDetailsCard";
import SkillScoreCard from "./SkillScoreCard";
import SkillList from "./SkillList";

import { generateSkillGroupConfig } from "../../../helpers/skillGroupConfigGenerators";

import styles from "./index.module.scss";

const SkillGroupDetails = () => {
  const {
    config,
    setConfig,
    clickedSkillId,
    detailsAnimationState
  } = React.useContext(DetailsConsumer);

  const { activeSkillGroup, fluency, department, skillData } = React.useContext(
    OverviewConsumer
  );

  if (!activeSkillGroup || !skillData) {
    return <Redirect push to="/" />;
  }

  React.useEffect(() => {
    if (!activeSkillGroup) {
      return;
    }
    let config;

    if (!activeSkillGroup.currentElement.children) {
      config = generateSkillGroupConfig(
        activeSkillGroup.currentElement.parent.data.id,
        department,
        fluency,
        skillData
      );
    } else {
      config = generateSkillGroupConfig(
        activeSkillGroup.skillGroup.id,
        department,
        fluency,
        skillData
      );
    }

    setConfig(config);
  }, [department, fluency, skillData.dataUpdated]);

  if (!config) {
    return null;
  }

  return (
    <div
      className={cx(styles.Wrapper, {
        [styles.WrapperSlide]:
          !!clickedSkillId && detailsAnimationState === "in"
      })}
    >
      <div className={styles.SkillList}>
        <h3 className={styles.GroupTitle}>{config.skillGroupName}</h3>
        <div className={styles.ListWrapper}>
          <SkillList
            skills={config.skills}
            skillGroupId={config.skillGroupId}
          />
        </div>
      </div>
      <div
        className={cx(styles.SkillDetail, {
          [styles.SkillDetailAnimateIn]:
            !!clickedSkillId && detailsAnimationState === "in"
        })}
      >
        <SkillDetailsCard skillId={clickedSkillId} skillData={skillData} />
        <SkillScoreCard department={department} skillId={clickedSkillId} />
      </div>
    </div>
  );
};

export default SkillGroupDetails;
