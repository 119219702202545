import React, { ReactNode } from "react";
import cx from "classnames";

import { TinySansSerifHeading } from "../Headings";

import styles from "./index.module.scss";

type PropsType = {
  small?: boolean;
  heading: string;
  data?: string | number;
  children?: ReactNode;
  noBorder?: boolean;
};

const DataPointCard = ({
  small,
  children,
  heading,
  data,
  noBorder
}: PropsType) => (
  <div
    className={cx(styles.Wrapper, {
      [styles.NoBorder]: noBorder,
      [styles.Small]: small
    })}
  >
    <TinySansSerifHeading>{heading}</TinySansSerifHeading>
    <span className={styles.Data}>{data}</span>
    {children}
  </div>
);

export default DataPointCard;
