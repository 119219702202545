import React, { ReactNode, useMemo } from "react";
import leapSkill from "../../data/leapSkill.json";
import exponential from "../../data/exponential.json";

import { OverviewContext } from "./OverviewConsumer";

import {
  AnimationTransitionStateType,
  ActiveSkillGroupDataType,
  DepartmentType,
  FluencyToggleType,
  HoveredNodeType,
  SkillDataType
} from "../../types";

const OverviewProvider = ({ children }: { children: ReactNode }) => {
  const [leapVersion, setLeapVersion] = React.useState<number>(leapSkill.length);
  const [exponentialVersion, setExponentialVersion] = React.useState<number>(exponential.length);
  const [skillSetType, setSkillSetType] = React.useState<
    "leap" | "exponential"
  >("leap");
  const [leapVersions, setLeapVersions] = React.useState<number>(leapSkill.length);
  const [exponentialVersions, setExponentialVersions] = React.useState<number>(exponential.length);

  const [skillData, setSkillData] = React.useState<SkillDataType>(
    leapSkill[leapVersion - 1]
  );

  const [activeSkillGroup, setActiveSkillGroup] = React.useState<
    ActiveSkillGroupDataType | undefined
  >();

  const [overviewAnimationState, setOverviewAnimationState] = React.useState<
    AnimationTransitionStateType
  >(undefined);

  const [department, setDepartmentState] = React.useState<DepartmentType>(
    "allCompany"
  );

  const [fluency, setFluencyState] = React.useState<FluencyToggleType>("all");

  const [hoveredNode, setHoveredNode] = React.useState<
    HoveredNodeType | undefined
  >();

  useMemo(() => {
    if (skillSetType === "exponential") {
      setSkillData(exponential[exponentialVersion - 1]);
    } else {
      setSkillData(leapSkill[leapVersion - 1]);
    }
  }, [leapVersion, exponentialVersion, skillSetType, fluency, department]);

  const setDepartment = (department: DepartmentType) => {
    setDepartmentState(department);

    if (fluency === "goal" && department !== "allCompany") {
      setFluency("all");
    }
  };

  const setFluency = (fluency: FluencyToggleType) => {
    setFluencyState(fluency);

    if (fluency === "goal") {
      setDepartment("allCompany");
    }
  };

  const providerState = {
    skillSetType,
    leapVersion,
    exponentialVersion,
    activeSkillGroup,
    overviewAnimationState,
    department,
    fluency,
    hoveredNode,
    skillData,
    leapVersions,
    exponentialVersions,
    setLeapVersion,
    setExponentialVersion,
    setSkillSetType,
    setActiveSkillGroup,
    setOverviewAnimationState,
    setDepartment,
    setFluency,
    setHoveredNode,
    setSkillData,
    setLeapVersions,
    setExponentialVersions
  };

  return (
    <OverviewContext.Provider value={providerState}>
      {children}
    </OverviewContext.Provider>
  );
};

export default OverviewProvider;
