import { DepartmentType, FluencyToggleType, SkillDataType } from "../types";

import {
  getDepartmentFluencyWeight,
  getIndividualSkillWeight
} from "./weightCalculations";
import {
  getHeadHandHeartFluencyStyle,
  getNeutralStyle,
  getExponentialTechStyle
} from "./visualizationStyles";

import { SkillGroupDataType, SkillStatsType } from "../types";

export type SingleSkillConfigType = {
  domain: string;
  id: string;
  name: string;
  style: { backgroundColor: string; opacity: number };
  dimStyle: { backgroundColor: string; opacity: number };
};

export type SkillGroupConfigType = {
  skillGroupId: string;
  skillGroupName: string;
  skills: Array<SingleSkillConfigType>;
};

function configFactory(
  foundSkillGroup: SkillGroupDataType,
  styleGenerator: (
    skill: SkillStatsType
  ) => { fill: string; fillOpacity: number }
) {
  return {
    skillGroupId: foundSkillGroup.groupId,
    skillGroupName: foundSkillGroup.groupName,
    skills: foundSkillGroup.skills.map((skill: SkillStatsType) => {
      const skillStyle = styleGenerator(skill);
      const dimStyle = getNeutralStyle();

      return {
        domain: skill.domain,
        id: skill.skillId,
        name: skill.skillName,
        style: {
          backgroundColor: skillStyle.fill,
          opacity: skillStyle.fillOpacity
        },
        dimStyle: {
          backgroundColor: dimStyle.fill,
          opacity: dimStyle.fillOpacity
        }
      };
    })
  };
}

function generateDepartmentOverview(
  foundSkillGroup: SkillGroupDataType,
  department: DepartmentType,
  skillData: SkillDataType
) {
  const styleGenerator = (skill: SkillStatsType) => {
    const weight = getIndividualSkillWeight(
      skill[department].learning,
      skill[department].fluent,
      skillData.employeeCounts[department]
    );
    const style =
      skillData.skillSetType === "leap"
        ? getHeadHandHeartFluencyStyle(weight, skill.domain)
        : getExponentialTechStyle(weight);

    return style;
  };

  return configFactory(foundSkillGroup, styleGenerator);
}

function generateDepartmentProgressConfig(
  foundSkillGroup: SkillGroupDataType,
  department: DepartmentType,
  fluency: FluencyToggleType
) {
  const styleGenerator = (skill: SkillStatsType) => {
    return getHeadHandHeartFluencyStyle(
      getDepartmentFluencyWeight(fluency, department, skill),
      skill.domain
    );
  };

  return configFactory(foundSkillGroup, styleGenerator);
}

export function generateSkillGroupConfig(
  activeSkillGroupId: string,
  department: DepartmentType,
  fluency: FluencyToggleType,
  skillData: SkillDataType
): SkillGroupConfigType {
  const foundSkillGroup = skillData.skillGroups.find(
    skillGroup => skillGroup.groupId === activeSkillGroupId
  );

  if (!foundSkillGroup) {
    throw new Error("Could not find a matching skill group.");
  }

  let config = generateDepartmentOverview(
    foundSkillGroup,
    "allCompany",
    skillData
  );

  if (department !== "allCompany") {
    config = generateDepartmentOverview(foundSkillGroup, department, skillData);
  }

  if (fluency === "fluent" || fluency === "learning" || fluency === "goal") {
    config = generateDepartmentProgressConfig(
      foundSkillGroup,
      department,
      fluency
    );
  }

  return config;
}
