import React from "react";
import cx from "classnames";

import { SmallSerifHeading } from "../../Headings";

import styles from "./index.module.scss";

type PropsType<T = string> = {
  options: Array<{ label: string; value: T }>;
  inputValue: T;
  setValue: (val: T) => void;
  label: string;
};

const Select = <T, _ = undefined>({
  options,
  inputValue,
  setValue,
  label
}: PropsType<T>) => {
  return (
    <label className={styles.Wrapper}>
      <SmallSerifHeading className={styles.Label}>{label}</SmallSerifHeading>
      <select
        className={styles.Dropdown}
        onChange={(event: React.ChangeEvent<any>) => {
          setValue(event.target.value);
        }}
        value={inputValue.toString()}
      >
        {options.map(({ value, label }) => (
          <option
            key={value.toString()}
            className={cx(styles.Option, {
              [styles.OptionSelected]: inputValue === value
            })}
            value={value.toString()}
            aria-selected={inputValue === value}
          >
            {label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default Select;
