import React from "react";
import cx from "classnames";
import { OverviewConsumer } from "../../contexts/Overview";

import styles from "./index.module.scss";

const SkillTypePicker = () => {
  const { setSkillSetType, skillSetType } = React.useContext(OverviewConsumer);

  const [animationState, setAnimationState] = React.useState(
    skillSetType === "leap" ? "left" : "right"
  );

  return (
    <div className={styles.Wrapper}>
      <button
        className={cx(styles.Button, {
          [styles.ButtonActive]: animationState === "left"
        })}
        onClick={() => {
          setAnimationState("left");
          setTimeout(() => setSkillSetType("leap"), 250);
        }}
      >
        Embedded <span className={styles.SkillLabel}>skills</span>
      </button>
      <button
        className={cx(styles.Button, {
          [styles.ButtonActive]: animationState === "right"
        })}
        onClick={() => {
          setAnimationState("right");
          setTimeout(() => setSkillSetType("exponential"), 250);
        }}
      >
        Exponential <span className={styles.SkillLabel}>skills</span>
      </button>
      <div
        className={cx(styles.Slider, {
          [styles.SliderLeft]: animationState === "left",
          [styles.SliderRight]: animationState === "right"
        })}
      />
    </div>
  );
};

export default SkillTypePicker;
