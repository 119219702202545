import React, { ReactNode } from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  className?: string;
  children: ReactNode;
};

const Text = ({ children, className }: PropsType) => (
  <span className={cx(styles.Text, className)}>{children}</span>
);

export default Text;
