import React from "react";

type PropsType = {
  className?: string;
};

const HeartIcon = ({ className = "" }: PropsType) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
  >
    <path fill="#98C9CA" fillRule="evenodd" d="M7 0L1 3.5v7L7 14l6-3.5v-7z" />
  </svg>
);

export default HeartIcon;
