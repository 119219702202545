import React from "react";

import { SkillGroupConfigType } from "../../helpers/skillGroupConfigGenerators";
import { AnimationTransitionStateType } from "../../types";

type DetailsContextType = {
  detailsAnimationState: AnimationTransitionStateType;
  animateSlide: boolean;
  setDetailsAnimationState: (arg0: AnimationTransitionStateType) => void;
  setAnimateSlide: (arg0: boolean) => void;
  config: SkillGroupConfigType | undefined;
  setConfig: (config: SkillGroupConfigType | undefined) => void;
  clickedSkillId: string | undefined;
  setClickedSkillId: (arg0: string | undefined) => void;
};

export const DetailsContext = React.createContext<DetailsContextType>({
  detailsAnimationState: undefined,
  animateSlide: false,
  setDetailsAnimationState: () => {},
  setAnimateSlide: () => {},
  config: undefined,
  setConfig: () => {},
  clickedSkillId: undefined,
  setClickedSkillId: () => {}
});

export default DetailsContext;
