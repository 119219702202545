import React from "react";

import { TinySansSerifHeading } from "../../../components/Headings";
import { OverviewConsumer } from "../../../contexts/Overview";

import styles from "./index.module.scss";

const getSkillsCount = (department, skillData) => {
  let activatedCount = 0;

  skillData.skillGroups.forEach(skillGroup => {
    skillGroup.skills.forEach(skill => {
      if (skill[department].fluent >= 1) {
        activatedCount = activatedCount + 1;
      }
    });
  });

  return activatedCount;
};

const getCapabilitiesCount = (department, skillData) => {
  let activatedCount = 0;

  skillData.skillGroups.map(skillGroup => {
    const skillsAreActivated = skillGroup.skills.map(
      skill => skill[department].fluent >= 1
    );

    if (
      skillsAreActivated.find(isActivated => isActivated === false) ===
      undefined
    ) {
      return (activatedCount = activatedCount + 1);
    }
  });

  return activatedCount;
};

const getCapabilitiesActivated = skillData => {
  let skillCount = 0;
  const skillsCount = skillData.skillGroups.forEach(skillGroup => {
    skillCount = skillCount + skillGroup.skills.length;
  });

  const fluencyCounts = {
    skillCount: skillCount,
    capabilitiesCount: skillData.skillGroups.length,
    allCompany: {
      activatedSkills: getSkillsCount("allCompany", skillData),
      activatedCapabilities: getCapabilitiesCount("allCompany", skillData)
    },
    engineering: {
      activatedSkills: getSkillsCount("engineering", skillData),
      activatedCapabilities: getCapabilitiesCount("engineering", skillData)
    },
    product: {
      activatedSkills: getSkillsCount("product", skillData),
      activatedCapabilities: getCapabilitiesCount("product", skillData)
    },
    strategy: {
      activatedSkills: getSkillsCount("strategy", skillData),
      activatedCapabilities: getCapabilitiesCount("strategy", skillData)
    },
    business: {
      activatedSkills: getSkillsCount("business", skillData),
      activatedCapabilities: getCapabilitiesCount("business", skillData)
    },
    operations: {
      activatedSkills: getSkillsCount("operations", skillData),
      activatedCapabilities: getCapabilitiesCount("operations", skillData)
    }
  };

  return fluencyCounts;
};

const ActivatedScorecard = () => {
  const { skillData, department } = React.useContext(OverviewConsumer);

  const [activatedData, setActivatedData] = React.useState(
    React.useMemo(() => getCapabilitiesActivated(skillData), [skillData])
  );

  React.useMemo(() => {
    setActivatedData(getCapabilitiesActivated(skillData));
  }, [skillData]);

  const [capabilitiesHovered, setCapabilitiesHovered] = React.useState(false);
  const [skillsHovered, setSkillsHovered] = React.useState(false);

  const getData = dataSet => {
    if (dataSet === "skill") {
      if (skillsHovered) {
        return (
          <div className={styles.Hovered}>
            <span className={styles.Activated}>
              {activatedData[department].activatedSkills}
            </span>
            /{activatedData.skillCount}
          </div>
        );
      } else {
        return `${Math.round(
          (activatedData[department].activatedSkills /
            activatedData.skillCount) *
            100
        )}%`;
      }
    } else {
      if (capabilitiesHovered) {
        return (
          <div className={styles.Hovered}>
            <span className={styles.Activated}>
              {activatedData[department].activatedCapabilities}
            </span>
            /{activatedData.capabilitiesCount}
          </div>
        );
      } else {
        return `${Math.round(
          (activatedData[department].activatedCapabilities /
            activatedData.capabilitiesCount) *
            100
        )}%`;
      }
    }
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.ScoreItem}>
        <span className={styles.Data}>
          {skillData.employeeCounts[department]}
        </span>
        <TinySansSerifHeading className={styles.Heading}>
          Team <span className={styles.NewLine}>Members</span>
        </TinySansSerifHeading>
      </div>
      <div className={styles.ScoreItem}>
        <span className={styles.Data}>{skillData.learningConversion}</span>
        <TinySansSerifHeading className={styles.Heading}>
          Learning <span className={styles.NewLine}>Conversion</span>
        </TinySansSerifHeading>
      </div>
      <div
        className={styles.ScoreItem}
        onMouseEnter={() => setCapabilitiesHovered(true)}
        onMouseLeave={() => setCapabilitiesHovered(false)}
      >
        <span className={styles.Data}>{getData("capabilities")}</span>
        <TinySansSerifHeading className={styles.Heading}>
          Capabilities <div className={styles.NewLine}>Activated</div>
        </TinySansSerifHeading>
      </div>
      <div
        className={styles.ScoreItem}
        onMouseEnter={() => setSkillsHovered(true)}
        onMouseLeave={() => setSkillsHovered(false)}
      >
        <span className={styles.Data}>{getData("skill")}</span>
        <TinySansSerifHeading className={styles.Heading}>
          Skills <span className={styles.NewLine}>Activated</span>
        </TinySansSerifHeading>
      </div>
    </div>
  );
};

export default ActivatedScorecard;
