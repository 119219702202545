import React from "react";

import { getSkillName } from "../../../../helpers/skillDataFetchers";
import leapSkillDescriptions from "../../../../data/leapSkillDescriptions";

import styles from "./index.module.scss";
import { SkillDataType } from "../../../../types";

type PropsType = {
  skillId?: string;
  skillData: SkillDataType;
};

type SimpleContentType = { type: "p" | "h4" | "li"; value: string };

type ListItemContentType = {
  type: "li";
  value: string;
  children?: Array<ListContentType>;
};

type ListContentType = {
  type: "ul" | "ol";
  value: Array<ListItemContentType>;
};

interface DataContentType {
  content: Array<SimpleContentType | ListContentType>;
}

interface DataType {
  [key: string]: DataContentType;
}

const SkillDetailsCard = ({ skillId, skillData }: PropsType) => {
  let foundSkill = undefined;
  let skillName = undefined;

  const typedSkillsData = leapSkillDescriptions as DataType;

  if (skillId) {
    foundSkill = typedSkillsData[skillId];
    skillName = getSkillName(skillId, skillData);
  }

  return (
    <div className={styles.OuterWrapper}>
      <h2 className={styles.SkillName}>{skillName}</h2>
      <div className={styles.Wrapper}>
        {foundSkill &&
          foundSkill.content.map((contentPiece, index: number) => {
            if (contentPiece.type === "p") {
              return (
                <p className={styles.Text} key={index}>
                  {contentPiece.value}
                </p>
              );
            } else if (contentPiece.type === "h4") {
              return (
                <h4 className={styles.Heading} key={index}>
                  {contentPiece.value}
                </h4>
              );
            } else if (contentPiece.type === "ul") {
              return (
                <ul className={styles.Ul} key={index}>
                  {contentPiece.value.map(li => {
                    return (
                      <li className={styles.Li} key={li.value}>
                        {li.value}
                        {li.children &&
                          li.children.map((child, index) => {
                            return (
                              <ol key={index}>
                                {child.value.map(li => (
                                  <li key={li.value} className={styles.Li}>
                                    {li.value}
                                  </li>
                                ))}
                              </ol>
                            );
                          })}
                      </li>
                    );
                  })}
                </ul>
              );
            } else if (contentPiece.type === "ol") {
              return (
                <ol>
                  {contentPiece.value.map(li => (
                    <li className={styles.Li}>{li.value}</li>
                  ))}
                </ol>
              );
            } else return null;
          })}
      </div>
    </div>
  );
};

export default SkillDetailsCard;
