import {
  SkillStatsType,
  DepartmentType,
  FluencyToggleType,
  SkillDataType
} from "../types";

function findSkill(skillId: string, skillData: SkillDataType) {
  //@ts-ignore TODO: fix me
  const foundSkillGroup = skillData.skillGroups.find(skillGroup => {
    return skillGroup.skills.find(skill => skill.skillId === skillId);
  });

  if (foundSkillGroup) {
    const foundSkill = foundSkillGroup.skills.find(
      (skill: SkillStatsType) => skill.skillId === skillId
    );

    return foundSkill;
  }

  return "";
}

export function getSkillName(skillId: string, skillData: SkillDataType) {
  const foundSkill = findSkill(skillId, skillData);

  if (foundSkill) {
    return foundSkill.skillName;
  }

  return "";
}

export function getSkillDomain(skillId: string, skillData: SkillDataType) {
  const foundSkill = findSkill(skillId, skillData);

  if (foundSkill) {
    return foundSkill.domain;
  }

  return "";
}

export function getFluencyCount(
  skillId: string,
  department: DepartmentType,
  fluency: FluencyToggleType,
  skillData: SkillDataType
) {
  const foundSkill = findSkill(skillId, skillData);

  //@ts-ignore TODO: fix me
  return foundSkill ? foundSkill[department][fluency] : undefined;
}
