const skillDetailsData = {
  "5b08476eb59100ac961e2ca5": {
    content: [
      {
        type: "p",
        value:
          "This skill is about consistently making adjustments to your organization's operating model in order to optimize its tactical and adaptive performance. An operating model at the organizational level includes its org structure, planning, and decision making processes. "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building adaptive organizations through pod-based org structures."
          },
          {
            type: "li",
            value:
              "Creating organizations resilient to executive changes (also through pod based org structures)."
          },
          {
            type: "li",
            value:
              "Designing multi-business org structures where the lines of business (LOBs) have freedom to be adaptive in their work."
          },
          {
            type: "li",
            value:
              "Building centralized capabilities that operate as platforms, not dictators, to the LOBs."
          },
          {
            type: "li",
            value:
              "Creating ownership. (Economies of scale and resource fungibility can be solved through less destructive levers)."
          },
          {
            type: "li",
            value:
              "Using strategic planning processes (top-down and bottom-up) to quickly scale insights from the frontlines."
          },
          {
            type: "li",
            value:
              "Ensuring that each unit has enough ownership to perform adaptively."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2ca9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about coaching executives and team leaders. Coaching executives can be difficult. Sometimes, these executive have more context than you do. They are also likely skilled in ways you are not, making it hard to come up with useful feedback. "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Coaching executives through monthly skill-development conversations."
          },
          {
            type: "li",
            value:
              "Ensuring your executives also set skill goals for their own development and to be positive role models to the whole organization."
          },
          {
            type: "li",
            value: "Focusing on improving skills rather than judging outcomes."
          },
          {
            type: "li",
            value:
              "Making specific and actionable recommendations through the skill maps."
          },
          {
            type: "li",
            value:
              "Writing down your advice to avoid possible miscommunications."
          },
          {
            type: "li",
            value:
              "Encouraging your executives to set public skill improvement goals."
          },
          { type: "li", value: "Keeping a high bar on endorsements." },
          { type: "li", value: "Knowing your colleagues’ skill maps." }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [{ type: "li", value: "Your colleagues' skill maps" }]
      }
    ]
  },
  "5b08476eb59100ac961e2ca3": {
    content: [
      {
        type: "p",
        value:
          "This skill is about enlisting executives who are not part of your organization. Enlisting at its best is not about convincing executives with indirect motives (e.g., quid pro quo trades or threats), but instead about making the case for higher play, purpose, and potential."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Making a strong case for why your vision is right for the organization and its customers."
          },
          {
            type: "li",
            value:
              "Understanding how your vision aligns with your colleagues’ visions."
          },
          {
            type: "li",
            value:
              "Seeking advice from colleagues about your organization’s vision."
          },
          {
            type: "li",
            value:
              "Helping your team members find play and purpose in the vision."
          },
          {
            type: "li",
            value:
              "Building longstanding, trusting relationships with your colleagues."
          },
          {
            type: "li",
            value:
              "Seeking to do the right thing for customers and the organization"
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cab": {
    content: [
      {
        type: "p",
        value:
          "This skill is about leading your immediate leadership team. Leadership teams are different from working teams. While working teams focus on delivering direct impact, leadership teams focus on creating harmony across each of the working teams."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Ensuring your leadership team is responsible for:",
            children: [
              {
                type: "ol",
                value: [
                  {
                    type: "li",
                    value:
                      "Managing the organization as 'platform' that supports the working teams. In other words, leadership teams should build a platform that unlocks people's impact more than another organization's platform could."
                  },
                  {
                    type: "li",
                    value:
                      "Amplifying learnings from one working team to the rest."
                  },
                  {
                    type: "li",
                    value:
                      "Bringing relevant context from the rest of the organization to the working teams."
                  },
                  {
                    type: "li",
                    value:
                      "Allocate resources across working teams based on the opportunity for impact within those teams."
                  }
                ]
              }
            ]
          },
          {
            type: "li",
            value:
              "Ensuring your leadership team is always role modeling the operating system you want your working teams to follow."
          },
          {
            type: "li",
            value:
              "Helping your team set common goals and drive large cross-functional experiments together."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cad": {
    content: [
      {
        type: "p",
        value: "This skill is about creating a high-tomo talent system."
      },
      {
        type: "h4",
        value: "An organization’s talent system includes:"
      },
      {
        type: "ol",
        value: [
          { type: "li", value: "How people get paid" },
          { type: "li", value: "How career ladders work." },
          { type: "li", value: "How people learn." }
        ]
      },
      {
        type: "p",
        value:
          "In most organizations, talent systems are low-tomo. Colleagues feel insecure and find no compelling reason to continue their work. They are busy chasing rewards or avoiding punishment. As a result, colleagues take less ownership, solve fewer problems, stop innovating, and end up ultimately gaming the system."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      { type: "p", value: "Building a talent system that:" },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Maximizes learning and amplification (i.e., adaptive performance)."
          },
          {
            type: "li",
            value: "Reduces bias and unfair pay distortions."
          },
          {
            type: "li",
            value:
              "Increases tomo for the work itself, rather than for emotional and economic reasons."
          },
          {
            type: "li",
            value: "Reduces the controlling nature of hierarchy."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2ca7": {
    content: [
      {
        type: "p",
        value:
          "This skill is about role modeling the rhythm for how your organization drives local adaptive performance on each and every team.  Adaptive performance implies continuous improvement, everyday problem solving, and innovation."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Ensuring every team has their own flexible goals to improve performance (e.g., their challenges)."
          },
          {
            type: "li",
            value:
              "Ensuring every team has a rhythm to manage their own ideas an experiments (e.g., performance cycles)."
          },
          {
            type: "li",
            value: "Role modeling these practices yourself on your team."
          },
          {
            type: "li",
            value: "Helping to amplify good ideas across the organization."
          },
          {
            type: "li",
            value: "Drive performance through learning not fear or pressure."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2ca1": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building a network of executives, inspire them to consider your firm for a role, and set them up for success."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building a network with executives who could be partners or members of your organization."
          },
          {
            type: "li",
            value:
              "Maintaining your reputation as a high-tomo leader and coach."
          },
          {
            type: "li",
            value: "Updating your network through social media."
          },
          {
            type: "li",
            value: "Befriending experts – not just family or friends."
          },
          {
            type: "li",
            value:
              "Assessing people’s executive skills rather than only looking at their accomplishments."
          },
          {
            type: "li",
            value:
              "Helping onboarding executives understand the culture and operating model of the organization."
          },
          {
            type: "li",
            value: "Giving new executives ways to create fast impact."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c9f": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating and teaching your whole organization its vision."
      },
      {
        type: "p",
        value:
          "An organization's vision isn’t a trite purpose statement. Instead, it’s a genuine reflection about what it means to create real impact for customers. At its best, the vision includes a theory of impact (TOI) that explains how the organization plans to solve its customers’ problems. A good TOI has two main components. First, it is specific enough to be meaningful but broad enough to cover the work of the entire organization. Second, it explains how each of the organizations’ components work together. In this skill, your goal is to build a sincere vision and TOI for your team."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building a mission that explains your customer and their problem."
          },
          {
            type: "li",
            value:
              "Creating a theory of impact that describes how your work intends to solve the customer’s problem."
          },
          {
            type: "li",
            value: "Clarifying your organization’s next major goals."
          },
          {
            type: "li",
            value:
              "Communicating the vision and proof points to your organization."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2ccb": {
    content: [
      {
        type: "p",
        value:
          "This skill is about coaching your team to drive impact through a process of on-the-job-apprenticeship."
      },
      {
        type: "p",
        value:
          "Coaching is a process that enables people to improve their performance over time. There are two components to performance—will and skill. Will is tomo, the driver of performance. Coaching, on the other hand, is about helping your colleagues grow in skill."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Ensuring your team members choose their own skills to develop (i.e., skill goals)."
          },
          {
            type: "li",
            value:
              "Having monthly skill development discussions (e.g., the 20:10 meeting where you spend 20 minutes helping your team member with their skill goals, and 10 minutes with your team member helping you)."
          },
          {
            type: "li",
            value:
              "Planning on-the-job opportunities with your team members to make progress on their skill goals."
          },
          {
            type: "li",
            value:
              "Routinely annotating feedback and next steps for your team's skill goals."
          },
          {
            type: "li",
            value:
              "Teaching colleagues how to use you as a coach: namely by encouraging them to talk to you about where they have and have not had impact, what skills they can improve, and how they can go about learning those skills."
          },
          {
            type: "li",
            value:
              "Providing your colleagues with continuous advice and positive reinforcement (e.g., telling them what they have been doing well)."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cc7": {
    content: [
      {
        type: "p",
        value:
          "This skill is about encouraging your team to take ownership over how their team works."
      },
      {
        type: "p",
        value:
          "Taking ownership over how the team works (which is different from taking ownership over the team's outcomes) implies that the team is constantly improving the processes, structures, and norms of the team."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Leading a periodic review of the habits and processes of the team."
          },
          {
            type: "li",
            value:
              "Encouraging honest discussion about what isn't working in the team."
          },
          {
            type: "li",
            value:
              "Role modeling honest discussion about what is and isn't working in the team."
          },
          {
            type: "li",
            value:
              "Encouraging volunteerism from your team to experiment with different ways of working."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cc9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about helping your team review, analyze, and draw inspiration from their performance outcomes, all without them feeling blame or pressure."
      },
      {
        type: "p",
        value:
          "Teams that are low-tomo feel like they need to hide their mistakes or ignore signs of deteriorating performance. They worry about being blamed for poor performance and sometimes feel as if they have no control over the outcome of their work. Teams that feel this way can't learn. They are afraid to point out performance related problems. They are wary about trying new ideas. In this skill, your goal is to reduce these feelings and help your teams learn from their outcomes."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Helping your team use your performance cycle meetings to identify and understand the cause of their performance gaps."
          },
          {
            type: "li",
            value:
              "Helping your team qualitatively see their own outcomes by interacting directly with the team's customers."
          },
          {
            type: "li",
            value:
              "Focusing on how you can improve your team's process, strategy, operating model, and skill rather than blaming individuals."
          },
          {
            type: "li",
            value:
              "Building mechanisms to gather qualitative or quantitative feedback."
          },
          {
            type: "li",
            value:
              "Building the play motive by making data more playful (e.g., setting up the work as a sequence of games and challenges)."
          },
          {
            type: "li",
            value:
              "Building the purpose motive by connecting data and observations to customer impact."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c9d": {
    content: [
      {
        type: "p",
        value:
          "This skill is about consistently increasing your team's direct motives, while decreasing their indirect motives (i.e., increase their tomo)."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Helping your colleagues find play in their work (e.g., through learning goals, finding novelty)."
          },
          {
            type: "li",
            value:
              "Helping your colleagues find purpose in their work by showing your colleagues the impact their work has had on the customer."
          },
          {
            type: "li",
            value:
              "Helping your colleagues find purpose in their work by ensuring they have a uniquely important role so they do not feel like a fungible cog."
          },
          {
            type: "li",
            value:
              "Helping your colleagues feel potential by connecting their work to their personal impact goals."
          },
          {
            type: "li",
            value:
              "Reducing emotional and economic pressure by creating an environment where your colleagues feel safe and supported economically."
          },
          {
            type: "li",
            value:
              "Reducing inertia by helping your team members understand why their work matters."
          }
        ]
      },
      {
        type: "h4",
        value: "Signs of someone still learning this skill might include:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Not giving colleagues honest feedback (i.e., being so worried about making others upset)."
          },
          {
            type: "li",
            value: "Being incapable of developing colleagues."
          },
          {
            type: "li",
            value: "Not pushing the team to strive for improvement."
          }
        ]
      },
      {
        type: "h4",
        value: "Notes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "A team’s tomo factor is not a way to determine if a leader has a certain skill or not. Tomo is influenced by context that is often far beyond a leader’s control."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cc5": {
    content: [
      {
        type: "p",
        value:
          "This skill is about leading a process of adaptive performance (i.e., performance cycles). This implies that your team is constantly improving its own performance through experimentation."
      },
      {
        type: "p",
        value:
          "Many leaders fear routines. Understandably so, given that most routines mean boring meetings and long stretches of wasted time. Routines, however, are crucial for enabling teams to stop, learn, and change direction, if needed. They are similar to brakes on a car. Instead of helping you slow down, effective, high-tomo routines give you the confidence to move faster. In this skill, your goal is to create and lead long-lasting routines that help your team drive their own continuous improvement."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Setting flexible goals for your team's impact and performance improvement (e.g., using challenge questions)."
          },
          {
            type: "li",
            value:
              "Establishing a routine (e.g., the performance cycle) to drive your team's agenda."
          },
          {
            type: "li",
            value:
              "Using an idea & experiment process to drive your team's work and decision making."
          },
          {
            type: "li",
            value:
              "Minimizing ideas to small experiments that get to the important learnings fast."
          },
          {
            type: "li",
            value:
              "Ensuring the team as a whole contributes ideas to how the team improves performance."
          },
          {
            type: "li",
            value:
              "Ensuring the team is committed to the experiments being planned."
          },
          {
            type: "li",
            value: "Experimenting safely and within the scope of the team."
          },
          {
            type: "li",
            value: "Setting time-boxes for your team's experiments."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2ccd": {
    content: [
      {
        type: "p",
        value:
          "This skill is about double checking your team's work to ensure it is high quality. As a leader, you can do this by pressure testing your team members' qualitative work product (e.g., strategies, memos, communications, designs). Pressure testing qualitative work involves catching type 1 (i.e., when you make a mistake), and type 2 (i.e., when you miss something entirely) errors."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Routinely reviewing your team's most important work."
          },
          {
            type: "li",
            value:
              "Forming your own perspective about how your colleagues can perform their work better and where they are most likely to make mistakes."
          },
          {
            type: "li",
            value: "Reviewing for structure, logic, clarity, and typos."
          },
          {
            type: "li",
            value: "Seeing errors as a learning opportunity."
          },
          {
            type: "li",
            value: "Identifying the root causes of a mistake."
          },
          {
            type: "li",
            value:
              "Helping your colleagues understand that you're checking their work to ensure high quality for the customer, not because you don’t trust them."
          },
          {
            type: "li",
            value:
              "Talking to your colleagues about how quality and efficiency in your work creates customer trust."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2ccf": {
    content: [
      {
        type: "p",
        value:
          "This skill is about pressure testing your team members' quantitative work product. Pressure testing quantitative work involves catching type 1 (i.e., when you make a mistake), and type 2 (i.e., when you miss something entirely) errors."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Forming my own perspective about how the work should be done and what is likely to go wrong in my colleagues’ work. (Most leaders like to do this without looking at the work to prevent against type 2 errors. Others also recommend making a list of the top 10 things that are likely to go wrong and sorting them by risk level)."
          },
          {
            type: "li",
            value:
              "Creating “double check” estimates for calculations and inputs (i.e., back-of-the-envelope math or sanity checking) instead of redoing the work."
          },
          {
            type: "li",
            value:
              "Checking that row counts and sums make sense when data sets are combined."
          },
          {
            type: "li",
            value:
              "Working with my colleagues to trace why there might be an error and how it can be prevented."
          },
          {
            type: "li",
            value:
              "Helping my colleagues understand that I’m checking their work to ensure high quality for the customer, not because I don’t trust them."
          },
          {
            type: "li",
            value:
              "Talking to my colleagues about how quality and efficiency in our work builds customer trust."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cd1": {
    content: [
      {
        type: "p",
        value:
          "This skill is about assessing candidates for open roles on your team."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "p",
        value: "Identifying candidates’ tomo:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Will they be curious (play), see the impact of their work (purpose) and find value in learning (potential)?"
          },
          {
            type: "li",
            value:
              "Will they be resilient to the emotional and economic pressure commonly faced in this role?"
          },
          {
            type: "li",
            value:
              "Will they feel comfortable in times of VUCA (volatility, uncertainty, complexity, and ambiguity)?"
          },
          {
            type: "li",
            value: "Will they be open to life long learning?"
          }
        ]
      },
      {
        type: "p",
        value: "Assessing candidates’ skills:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Using the role’s skill map as a tool for recruiting/promoting."
          },
          {
            type: "li",
            value:
              "Trying stimulations and exercises that they would be doing on the job."
          }
        ]
      },
      {
        type: "p",
        value: "Enlisting candidates:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Describing the play purpose, and potential of the role."
          },
          {
            type: "li",
            value:
              "Being able to passionately describe the vision, expectations, norms, and goals of the role."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cc3": {
    content: [
      {
        type: "p",
        value:
          "This skill is about setting a vision for your team about where it is heading and why."
      },
      {
        type: "p",
        value:
          "Most teams have a process or set of tasks that they routinely follow. But without a vision of the bigger picture, they often struggle adapting when they need to. By clarifying that vision, you can help your team discover the full impact of its work."
      },
      {
        type: "p",
        value:
          "Creating a team vision is like learning how to cook. Initially, it's easy to see only the set of ingredients that go into a dish. Over time, however, you gain a greater understanding of how those ingredients come together to create an outstanding meal. Similarly, setting a vision helps your team members discover how their individual work (the ingredients) contributes to the final product (the meal)."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Teaching your team the mission: what customer problem are you trying to solve? A poor mission is crafted by working backwards (e.g., 'Our customers' problem is that they need someone to do the work we do.') A good mission, on the other hand, gives teams the freedom to innovate. It articulates a problem that is emotional for the customer. It makes it clear as to why the customer would find your work valuable."
          },
          {
            type: "li",
            value:
              "Establishing the team's theory of impact (TOI): How does this team solve the customer's problem? A good TOI explains how your team's approach plans to solve for the mission. It enables colleagues to form hypotheses and test assumptions. It is specific enough that it wouldn't apply to any other team's approach."
          },
          {
            type: "li",
            value:
              "Describing the team's trajectory: how does your team expect to get better at accomplishing its mission over time?"
          },
          {
            type: "li",
            value:
              "Clarifying the next milestone: six months from now, what would this team have achieved? Milestones describe accomplishments – not metrics."
          },
          {
            type: "li",
            value:
              "Iterating on the vision with your team and main stakeholders."
          },
          {
            type: "li",
            value:
              "Teaching the vision to your team so that it becomes their own."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c85": {
    content: [
      {
        type: "p",
        value:
          "Creating large scale impact often requires aligning large teams on an execution plan. This skill is about balancing tactical and adaptive performance while executing complex multi-colleague projects."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Establishing project goals for a team of at least 5 colleagues."
          },
          {
            type: "li",
            value:
              "Laying out the work that needs to be completed before the next milestone (consistent with the performance cycle methodology used by the team, for example 'challenges' and 'experiments')"
          },
          {
            type: "li",
            value:
              "Ensuring the work is all designed as small experiments meant to drive learnings and increase adaptability."
          },
          {
            type: "li",
            value: "Using technology to track your team's progress."
          },
          {
            type: "li",
            value:
              "Creating routines to balance work, unblock obstacles, clarify expectations, and point out blind spots."
          },
          {
            type: "li",
            value:
              "Reminding colleagues of the high-tomo 'why' behind the project."
          },
          {
            type: "li",
            value:
              "Teaching your colleagues about how to use the routines and technology."
          },
          {
            type: "li",
            value: "Using weekly goals and milestones with your colleagues."
          },
          {
            type: "li",
            value:
              "Helping colleagues block out time and prioritize work for the given project."
          },
          {
            type: "li",
            value: "Reviewing your work to see if the plan needs adjustment."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c83": {
    content: [
      {
        type: "p",
        value:
          "Sometimes you will be leading difficult conversations with your colleagues and customers. This skill is about preparing, leading, and following-up after these types of conversations."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Preparing for the conversation by identifying reasons why a colleague may have acted the way he/she did. Start by assuming that the behavior was driven by the person' context. After that, see if their struggle with a skill might be the root cause of the problem. (This will create a learning orientation to the conversation)."
          },
          {
            type: "li",
            value:
              "Immediately raising difficult conversations (e.g., conflicts in working style or value)."
          },
          {
            type: "li",
            value:
              "Aligning your colleague on the impact your team has had with its customers. (This will help your colleague feel included as part of the team)."
          },
          {
            type: "li",
            value:
              "Making sure your colleague feels heard (e.g., by playing back)."
          },
          {
            type: "li",
            value:
              "Making sure your colleagues knows that you understand their emotional response (e.g., 'I imagine that could feel frustrating'), without creating an emotional echo-chamber."
          },
          {
            type: "li",
            value:
              "Giving everyone the opportunity to explain their perspective without ascribing malicious intent to anyone's actions."
          },
          {
            type: "li",
            value:
              "Explaining a high-tomo 'why' for the need to improve and take additional steps to resolve the issue."
          },
          {
            type: "li",
            value:
              "Helping the group come up with productive next steps - experiments or tests to run, changes to make, or skills to learn."
          },
          {
            type: "li",
            value:
              "Following up periodically on the next steps to ensure that the topic has been resolved."
          },
          {
            type: "li",
            value: "Managing your own emotions throughout the discussion."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c87": {
    content: [
      {
        type: "p",
        value:
          "Being part of a team means that you live by the norms that make the team cohesive and impactful. This skill is about role modeling the norms of any team that you join during the course of your career."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Working at the time and place that your team has established. If you don't know the norms, please ask!"
          },
          {
            type: "li",
            value:
              "Joining team routines on time like stand-ups, reflections, retrospectives, and campfires."
          },
          {
            type: "li",
            value:
              "Dressing in a way that's appropriate according to you team's norms."
          },
          {
            type: "li",
            value:
              "Being respectful and present when engaging with your colleagues. That means not multitasking during your team's routines."
          },
          {
            type: "li",
            value:
              "Consistently using the process and technologies that your team has agreed upon."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c89": {
    content: [
      {
        type: "p",
        value:
          "In many organizations, meetings are a dreaded part of work. This skill is about organizing and leading meetings that are high-tomo (i.e., they are filled with play, purpose, and potential, with no emotional pressure, economic pressure, or inertia). "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "p",
        value: "For leading meetings:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Organizing logistics that make it feel like the meeting is purposeful (e.g., clear subject, meeting locations and times, video conferencing, clickable phone numbers, etc.)."
          },
          {
            type: "li",
            value: "Not cancelling on the same day."
          },
          {
            type: "li",
            value: "Using colleagues' calendars to schedule time to meet."
          },
          {
            type: "li",
            value:
              "Sharing ahead of time any materials that meeting attendees should read prior to the discussion."
          },
          {
            type: "li",
            value:
              "Having ready (and ideally sharing ahead of time) the goals and agenda of the meeting. Make the goals high-tomo by explaining why the meeting matters."
          },
          {
            type: "li",
            value:
              "Requesting that only the people who are needed to achieve the goals of the meeting be in attendance."
          },
          {
            type: "li",
            value:
              "Starting the meeting with the goals/purpose, agenda, and norms."
          },
          {
            type: "li",
            value:
              "Keeping meetings focused on the goals by acknowledging and tabbing unrelated topics (i.e., rabbit holes)."
          },
          {
            type: "li",
            value:
              "Recognizing if the meeting's goal should change given the group's context. Intentionally state if that is the case."
          },
          {
            type: "li",
            value: "Creating time with your team to agree on clear next steps."
          },
          {
            type: "li",
            value:
              "Following up with a summary of the points, decisions made, and next steps that were discussed."
          },
          {
            type: "li",
            value: "Starting and ending every meeting on time."
          }
        ]
      },
      {
        type: "p",
        value: "For participating in meetings:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Not multitasking."
          },
          {
            type: "li",
            value: "Keeping your own calendar up-to-date."
          },
          {
            type: "li",
            value: "Helping colleagues identify their blind spots."
          },
          {
            type: "li",
            value: "Looking over any pre-read materials."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c8d": {
    content: [
      {
        type: "p",
        value:
          "Because every member of your firm is a carrier of the mission, every member should learn how to talk about the work they do in a way that conveys play and purpose. This skill is about accurately and compellingly describing to friends, family, and recruits what your firm does."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Describing your firm's work and the problem it hopes to solve."
          },
          {
            type: "li",
            value: "Teaching your personal network the firm's mission."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "The firm's primary customer and its problem."
          },
          {
            type: "li",
            value: "How the firm plans on solving that problem."
          },
          {
            type: "li",
            value: "How the Firm plans to improve."
          },
          {
            type: "li",
            value:
              "The firm's business model and how it aligns with the problem we are solving."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c8f": {
    content: [
      {
        type: "p",
        value:
          "The tools of master craftsmen are extensions of themselves. For example, a master woodworker spends the early part of an apprenticeship learning to build, use, and maintain the tools of the trade. Similarly, being an impactful professional means mastering your team's productivity tools."
      },
      {
        type: "p",
        value:
          "A truly impactful team maximizes its tactical and adaptive performance. Tactical performance is about convergence: how well we apply past learnings. Adaptive performance is about divergence: how well we collect future learnings. As opposites, they can destroy each other if not kept in balance. Most teams use technology in some way to help them manage tactical performance (e.g., documenting knowledge, following your process), or adaptive performance (e.g., problem solving tools and processes). As a member of an organization and team, it's up to you to learn these tools and use them well."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Learning the full capabilities of your team's productivity technologies."
          },
          {
            type: "li",
            value:
              "Using those technologies as often as your team expects you to."
          },
          {
            type: "li",
            value: "Taking care of your tools as if they were your own."
          }
        ]
      },
      {
        type: "h4",
        value: " Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "File sharing/document storage tools."
          },
          {
            type: "li",
            value: "Process/time management tools."
          },
          {
            type: "li",
            value: "Communications tools."
          },
          {
            type: "li",
            value: "Data security tools."
          },
          {
            type: "li",
            value: "Hardware/devices."
          }
        ]
      }
    ]
  },
  "5c40c06b8d9d1363f52b76da": {
    content: [
      {
        type: "p",
        value:
          "Recent findings in psychology have found that grit - which refers to a person’s ability to persevere in order to achieve long-term goals - is a key component of success in many domains of human performance, such as education, organizations, and the military (Duckworth, 2007). Fortunately, grit is not something that a person is either born with or not; it is a trait that can be learned and developed as a skill. This skill is about building up your personal grit and maintaining it so that you can persist through challenges and achieve long-term objectives."
      },
      {
        type: "p",
        value:
          "In terms of what’s within your control, how gritty your are largely depends on your beliefs - in yourself, your colleagues, and the situation at hand. Our beliefs come from our experiences. Therefore, this skill has two main components:"
      },
      {
        type: "ol",
        value: [
          {
            type: "li",
            value:
              "Creating experiences that help you form beliefs that make you grittier"
          },
          {
            type: "li",
            value:
              "Calling back on those beliefs to apply your grit to a difficult situation"
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Noticing when you’re facing a situation that may require grit"
          },
          {
            type: "li",
            value:
              "Asking yourself what beliefs of yours may hinder or help your perseverance through the situation at hand"
          },
          {
            type: "li",
            value:
              "Challenging any belief that may hinder you - is this belief accurate? Why might it not be? How could you find out?"
          },
          {
            type: "li",
            value:
              "Starting with small wins to change your beliefs (e.g., if you’re trying to build a relationship with a new client and you’re not confident in your ability to articulate your value proposition, start by having practice conversations with your colleagues, then your friends, then a friendly stranger, etc., slowly working up to the real thing)"
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c6f": {
    content: [
      {
        type: "p",
        value:
          "A process is a step by step approach you can use to outline your work. It’s easy to mistake a process for a milestone. Milestones are time restricted goals. For example, when writing a book, a milestone could be to complete the first draft of the book in four months. This is different from the _process_ for writing each chapter, which might comprise a few steps. For example, the first step is to compile stories, evidence, and data; the second step is to organize each of those elements, etc."
      },
      {
        type: "p",
        value:
          "Any type of work can have a process like this. An engineer’s process, for instance, includes understanding the product requirements, building a fast mockup, and creating the data model, among others. Jerry Seinfeld, one of the top comedians of all time, has a process where he tries to write five jokes every day. His goal isn't to write five funny jokes, just five jokes (whether they're good or bad). "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Building a process to help you do high quality work."
          },
          {
            type: "li",
            value:
              "Breaking up your work into small daily chunks of activity that guide you towards your vision and plan."
          },
          {
            type: "li",
            value:
              "Checking in with colleagues or leaders throughout the process."
          },
          {
            type: "li",
            value: "Adapting your process as you learn."
          },
          {
            type: "li",
            value:
              "Designing each chunk of work to be useful in driving a learning our outcome."
          },
          {
            type: "li",
            value:
              "Following the 'pencils-down' principle so that if you were asked to stop your work at anytime, what you have would be useful to your customer."
          },
          {
            type: "li",
            value: "Using technology to help you manage your process."
          },
          {
            type: "li",
            value: "Habitually creating processes for any large body of work."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c71": {
    content: [
      {
        type: "p",
        value:
          "The most impactful performers don't just 'do their job'; they learn how they can get better at doing their job. That means continuously asking for advice from mentors, coaches, and colleagues."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Setting up routine time with your coach to ask about how you can perform your work better."
          },
          {
            type: "li",
            value:
              "Sharing with your coach your own perspective about how you can improve and which skills you should prioritize."
          },
          {
            type: "li",
            value: "Setting learning goals on specific skills."
          },
          {
            type: "li",
            value: "Knowing different ways of doing your work."
          },
          {
            type: "li",
            value: "Asking your coach for advice."
          },
          {
            type: "li",
            value:
              "Using data (if available) to form new ideas about how you can improve your performance."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c73": {
    content: [
      {
        type: "p",
        value:
          "Structured communication among colleagues and customers is an important part of building an effective team or organization. This skill is about communicating with structure using a method called 'pyramid principle.'"
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Starting your communication with a brief explanation of the main point."
          },
          {
            type: "li",
            value:
              "Laying out your sub-points before going into a meeting – also known as 'signposting' (e.g., 'the third point is...')."
          },
          {
            type: "li",
            value: "Using concise language and proper grammar."
          },
          {
            type: "li",
            value:
              "Keeping the pace slow enough for readers/listeners to follow along."
          },
          {
            type: "li",
            value:
              "Returning to the top when the listener is feeling lost. (Each of the above points applies to verbal and brief written communications such as emails or short memos)."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Brand standards."
          },
          {
            type: "li",
            value:
              "The core beliefs, mission, and theory of impact of the organization."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c48": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating and testing hypotheses used to improve yours or your team's performance. A hypothesis is a potential explanation that is tested through experimentation. The word, 'explanation' here is key. When looking to improve the performance of your team, first form a hypothesis (e.g., 'if we did X, then Y should happen, which will result in Z increase in impact'). Then, think about what experiment you could perform that would efficiently test that hypothesis."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Forming an explanatory hypothesis once you've taken a look at the data."
          },
          {
            type: "li",
            value: "Building hypotheses habitually."
          },
          {
            type: "li",
            value: "Expressing uncertain opinions in your hypothesis."
          },
          {
            type: "li",
            value:
              "Constructing an experiment that can be used to test your hypothesis."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Your team and company processes, for example all frontline sales and service processes. It is hard to form a good hypothesis without having a clear understanding of the current state of your company."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c4a": {
    content: [
      {
        type: "p",
        value:
          "This skill is about assessing your competitors and using that assessment to generate ideas for how you our your team can improve performance."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Web searching (i.e., mystery shopping) competitors to compare approaches."
          },
          {
            type: "li",
            value:
              "Interviewing customers about their experience with various competitors."
          },
          {
            type: "li",
            value:
              "Generating ideas for your own company based on your observations from the above points."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Your end-to-end processes (e.g., customer sales and service)."
          },
          {
            type: "li",
            value: "Your team's specific competitors."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c4c": {
    content: [
      {
        type: "p",
        value:
          "This skill is about using data (qualitative and quantitative) to generate ideas to improve yours or your team's performance. That means using data to generate ideas for performance improvement. Without data, it's hard to know whether or not an idea should be prioritized."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Treating data as sources for ideas, not sources of pressure."
          },
          {
            type: "li",
            value:
              "Understanding the limits of measurable information (i.e., avoiding the pitfall of managing only to numbers)."
          },
          {
            type: "li",
            value:
              "Collecting data to understand where your process or strategy can be improved to have greater customer impact."
          },
          {
            type: "li",
            value:
              "Coming up with new ideas about how to better leverage each strength."
          },
          {
            type: "li",
            value:
              "Thinking about how you can get better at each area for improvement."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Your end-to-end process (e.g., customer sales and service processes)."
          },
          {
            type: "li",
            value:
              "Your performance data and dashboards (e.g., sales and service data; operations data)."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c4e": {
    content: [
      {
        type: "p",
        value:
          "As a member of this community, your actions will impact the reputation of the organization. As a professional, therefore, you will need to actively manage the risks you may be creating."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Ensuring that your online presence reflects well on the whole organization."
          },
          {
            type: "li",
            value:
              "Taking the appropriate steps to avoid losing data (e.g., avoiding phishing attempts, using multi-factor authentication, encrypting all data storing devices, ensuring passwords are protected)"
          },
          {
            type: "li",
            value:
              "Learning the policies that are pertinent to your role, along with recognizing if you may need to learn these policies."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of: "
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Organizational policies that are relevant to your role."
          },
          {
            type: "li",
            value: "How to maintain data security on your specific devices."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c75": {
    content: [
      {
        type: "p",
        value:
          "True professionals are experts at managing their own time. This means writing down all their work, scoping it to balance quality and quantity, and prioritizing it in order to ensure that they keep their promises."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Having a systemic approach to capture all your to-do items, promises, and expectations. That means not assuming you can just remember things in your head."
          },
          {
            type: "li",
            value: "Using your approach consistently."
          },
          {
            type: "li",
            value: "Writing down when you promise to deliver your next steps."
          },
          {
            type: "li",
            value: "Checking your to do list before making promises."
          },
          {
            type: "li",
            value:
              "Working with your coaches to figure out when you can deliver an 'ugly first draft' (UFD) versus when you should deliver polished work."
          },
          {
            type: "li",
            value:
              "Sharing your to do list with colleagues to help reprioritize if you still feel overwhelmed."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c77": {
    content: [
      {
        type: "p",
        value:
          "Your environment has a material effect on your tomo (i.e., your mindset to perform). However, with time and practice, you can develop the skill to be resilient to your environment. This skill is about taking the steps to diagnose your own tomo and keep it as high as possible."
      },
      {
        type: "p",
        value:
          "As a reminder, tomo is an encapsulation of why you are doing an activity. You are high-tomo if you love what you're doing (play), believe in the impact of your work (purpose), and see your work as helping you grow (potential). Low tomo, on the other hand, is when you are busy pleasing your boss (emotional pressure), seeking rewards or avoiding punishments (economic pressure) or not knowing why you're doing this work in the first place (inertia)."
      },
      {
        type: "p",
        value:
          "It is by practicing this skill that some superstar athletes seem to be immune to the emotional and economic pressure of a big game."
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Creating an 'ugly first draft' and sharing it with colleagues when you're feeling stuck."
          },
          {
            type: "li",
            value:
              "Being excited to learn from others and use your skills map and catalog."
          },
          {
            type: "li",
            value:
              "Being quick to point out where your work didn't achieve its expected outcome. Better yet, you share what you've learned."
          },
          {
            type: "li",
            value: "Helping your colleagues increase their own tomo."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c79": {
    content: [
      {
        type: "p",
        value:
          "For any work that you're engaged in, being able to catch your own errors before the customer does builds trust. This skill is about building that trust by pressure testing your work. There are two main types of errors that you want to catch. The first is a 'type 1' error when you make a mistake (e.g., you sent an email to the wrong person). The second is a 'type 2' error when you miss something entirely (e.g., you never sent out an email that you were supposed to)."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Building a process to double check your work."
          },
          {
            type: "li",
            value:
              "Using tactics like independent calculations, sanity checking outputs, and checking formulas for checking quantitative work."
          },
          {
            type: "li",
            value:
              "Using tactics like spell checkers and reading out loud for checking qualitative work."
          },
          {
            type: "li",
            value:
              "Having your peers review your work when the risk levels are high."
          },
          {
            type: "li",
            value:
              "Using peer interviews to ask your colleagues how they would have tackled your work."
          },
          {
            type: "li",
            value: "Including time to double-check your work in your calendar."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c7b": {
    content: [
      {
        type: "p",
        value:
          "Sometimes, you won't be sure if it's safe or appropriate to ask for help. This skill is about proactively seeking help whenever you're uncertain about something or making little progress in your work."
      },
      {
        type: "p",
        value:
          "You can think of your team like a ship at sea. If you accidentally blow a hole in the side of the ship above the waterline, that's no big deal. But if you blow a hole below the waterline, the entire team will sink. Any time you think holes might be appearing below the waterline, you should seek help."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Self-evaluating when you're stuck and merely wasting time."
          },
          {
            type: "li",
            value: "Learning where the waterline is in your role."
          },
          {
            type: "li",
            value:
              "Self-evaluating if you're going to take an action that is below the waterline."
          },
          {
            type: "li",
            value:
              "Identifying the right colleague to reach out to depending on the type of escalation that occurs."
          },
          {
            type: "li",
            value:
              "Being able to reach out to leaders, coaches, or experts for help."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Policies with regards to your work."
          },
          {
            type: "li",
            value: "Escalation processes (if they exist)."
          },
          {
            type: "li",
            value:
              "Personal relationships with experts who can advise you in your work."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c81": {
    content: [
      {
        type: "p",
        value:
          "As a team member, you likely want to share your best ideas, dissenting opinions, questions, and learnings with your colleagues. However, it isn't always easy to share your perspective in a way that helps the team or is 'on point' with the broader discussion. This skill is about learning WHEN and HOW you can share your perspective."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "p",
        value: "Solving for context (the when):"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Assessing whether your comment is relevant to the goal of the conversation. When in doubt, test your understanding of the conversation's goal with your team."
          },
          {
            type: "li",
            value:
              "Considering the altitude of the conversation. Is your team discussing issues at a high level perspective whereas your comments are more detailed oriented?"
          },
          {
            type: "li",
            value:
              "Evaluating if you're offering a new insight or merely restating a perspective already understood by the team."
          },
          {
            type: "li",
            value:
              "Saving comments for later if they are important but irrelevant to the current discussion."
          }
        ]
      },
      {
        type: "p",
        value: "Solving for your tone (the how):"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Explaining your concern (without accusing anyone) when sharing a dissenting opinion."
          },
          {
            type: "li",
            value:
              "Understanding other people's perspectives before stating your own."
          },
          {
            type: "li",
            value:
              "Focusing on the work and the customer rather than targeting your colleagues."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c7f": {
    content: [
      {
        type: "p",
        value: "This skill is about using basic math to improve your work."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Using algebra, fractions, percentages, percentage growth, and interest rates in your work."
          },
          {
            type: "li",
            value: "Using math to optimally spend your budget."
          },
          {
            type: "li",
            value: "Estimating the impact value of your ideas."
          },
          {
            type: "li",
            value: "Using spreadsheet software to facilitate basic math."
          },
          {
            type: "li",
            value:
              "Using math for cash operations (e.g., dividing cash, returning change, calculating tips)."
          }
        ]
      }
    ]
  },
  "5878da5bb6789281bd55e8c5": {
    content: [
      {
        type: "p",
        value:
          "This skill is about validating and scrubbing your data prior to starting any analytics projects. While this might not seem like the most exciting skill to work on, it is incredibly important for ensuring that your work is high quality. Bad data often leads to incorrect insights. And flaws in a data set means you have to start all over again. Achieving competency in this skill will help you avoid these errors."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Reviewing meta data to ensure that each column has been joined correctly."
          },
          {
            type: "li",
            value:
              "Fixing corrupted data (e.g., do zeros represent 'zeros' or 'blanks'?)"
          },
          {
            type: "li",
            value: "Cleaning columns used for grouping and pivoting."
          },
          {
            type: "li",
            value:
              "Properly labeling columns or indices that will be used for future analysis."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e90b": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building financial and valuation models using viable, tested assumptions. Achieving competency in this skill can significantly help your company make better strategic and operational decisions. "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building models that test the impact of initiatives against valuation, income statement, balance sheet, and key financial ratios (e.g., ROE, ROA, ROIC, IRR, and efficiency ratio)."
          },
          {
            type: "li",
            value:
              "Thinking about how to improve your company's strategy using economic models."
          },
          {
            type: "li",
            value:
              "Creating scenarios that demonstrate the uncertainty of particular inputs."
          }
        ]
      }
    ]
  },
  "5878da5bb6789281bd55e8cf": {
    content: [
      {
        type: "p",
        value:
          "This skill is about generating insights by analyzing, synthesizing, and presenting raw data."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "p",
        value: "Mastering the following steps:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Frame the problem or hypothesis."
          },
          {
            type: "li",
            value: "Join all the data to a single source."
          },
          {
            type: "li",
            value: "Confirm the joining mechanism that you will be using."
          },
          {
            type: "li",
            value: "Pivot the data, if necessary."
          },
          {
            type: "li",
            value: "Create summary data to test your hypothesis."
          },
          {
            type: "li",
            value:
              "Visualize the data (i.e., how do you plan on explaining it to others?)"
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Excel pivot tables, vlookup, hlookup, index, sumif, aploris, and countif functions."
          }
        ]
      }
    ]
  },
  "593dda083200004400d644a9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about exploring various hypotheses and potential relationships using statistics."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Being familiar with statistical tools including correlations, factoring, and clustering."
          },
          {
            type: "li",
            value:
              "Knowing how to quickly identify relationships and create new dimensions for summaries."
          },
          {
            type: "li",
            value:
              "Using a clustering or factoring analysis to identify further insights."
          }
        ]
      }
    ]
  },
  "593de1fe3200001800d644ad": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building models and advanced calculators that facilitate decision making."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Designing models with separate input, calculation, and output sections/sheets."
          },
          {
            type: "li",
            value: "Ensuring that all inputs and outputs are user friendly."
          },
          {
            type: "li",
            value: "Building a process for error checking into the model."
          },
          {
            type: "li",
            value:
              "Creating scenario based models with sensitivity analyses and operational models with inputs."
          }
        ]
      }
    ]
  },
  "593de1363200001700d644ab": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building prescriptive models using statistical tools."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Being familiar with operational queuing, Monte Carlo simulators, and inventory/news vendor models."
          },
          {
            type: "li",
            value:
              "Designing models with separate input, calculation, and output sections/sheets."
          },
          {
            type: "li",
            value: "Ensuring that all inputs and outputs are user friendly."
          },
          {
            type: "li",
            value: "Building a process for error checking into the model."
          }
        ]
      }
    ]
  },
  "5878da5bb6789281bd55e8d3": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building statistical models that drive decision making."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Creating statistical models, (e.g., regressions, logistic/non-logistic regressions, risk modeling, credit scores, and prioritization models)."
          },
          {
            type: "li",
            value: "Testing inputs to ensure their appropriateness."
          },
          {
            type: "li",
            value:
              "Being familiar with mathematical transformations of inputs. This will ensure properly capturing their relationship to the predicted output."
          },
          {
            type: "li",
            value: "Constructing the model and testing it for validity."
          }
        ]
      }
    ]
  },
  "5878da5bb6789281bd55e8c9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about generating original data and gathering quantitative feedback accordingly."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Using a hypothesis driven approach when answering relevant questions."
          },
          {
            type: "li",
            value: "Understanding the limitations of your data."
          },
          {
            type: "li",
            value: "Indicating any assumptions that were made."
          },
          {
            type: "li",
            value: "Ensuring that your data is used responsibly."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e8db": {
    content: [
      {
        type: "p",
        value:
          "A distinctive frontline colleague is always looking to expand our organization's impact by expanding our client base. This requires building a large, trust-based network of friends."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Understanding the segments that would most be impactful for our organization or who we can best help. "
          },
          {
            type: "li",
            value:
              "Identifying the common places (e.g., websites, conferences) where our target segments seek information."
          },
          {
            type: "li",
            value:
              "Using social media provide useful / entertaining information."
          },
          {
            type: "li",
            value:
              "Being genuine friends with this network (e.g., being your authentic self, being relentlessly helpful)."
          },
          {
            type: "li",
            value:
              "Keeping the network constantly warm by sharing information, asking for help, and being friendly."
          },
          {
            type: "li",
            value: "Prioritizing and focusing time on influencers."
          },
          {
            type: "li",
            value: "Earning trust by keeping commitments."
          },
          {
            type: "li",
            value:
              "Continuing to build these friendships with someone before, during, and after they are a client."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e8dd": {
    content: [
      {
        type: "p",
        value:
          "Being an effective leader of client development implies being methodical about your process (so as to not drop balls), and being a team player (by using the systems and tools of the team). This skill is about personally managing your sales process in the ways agreed upon by your team."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Breaking your personal expectation to a funnel with specific targets for each step (e.g., I want to help 2 new customers per week, which means, I have to make 10 new introductory emails per day)."
          },
          {
            type: "li",
            value:
              "Analyzing and understanding your team's chosen methodology for tracking the sales process (e.g., CRM, Salesforce)."
          },
          {
            type: "li",
            value:
              "Having a systemic approach to capture and remind you of promised follow-ups and keep-in-touch routines."
          },
          {
            type: "li",
            value: "Using CRM software efficiently and habitually."
          },
          {
            type: "li",
            value: "Consistently entering contacts and opportunities."
          },
          {
            type: "li",
            value:
              "Applying the teams' agreed upon nomenclature and organizational systems."
          },
          {
            type: "li",
            value:
              "Tracking correspondence with potential clients to make it easier for colleagues to learn and help."
          }
        ]
      }
    ]
  },
  "5878da5bb6789281bd55e8cd": {
    content: [
      {
        type: "p",
        value:
          "An effective client developer has to help diagnose the client's pain and needs, and prescribe a cure. This requires you to learn how to question, hypothesize, and test solutions."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Researching the client's industry / functional dynamics to form a prediction on their likely biggest issues. Specifically understand how your client's team or organization is expected to create value and growth."
          },
          {
            type: "li",
            value:
              "Asking colleagues and experts to help you refine your prediction."
          },
          {
            type: "li",
            value:
              "Using questions and scenarios to refine your prediction to create a clear, structured, articulation of the client's problem. The clear articulation of your client's problem and constraints is already value creating."
          },
          {
            type: "li",
            value:
              "Teaching our organization's perspectives on this specific problem and  sharing solutions. Solutions may range from those we could help with along with those we might not."
          },
          {
            type: "li",
            value:
              "Sharing additional materials or opportunities to learn more."
          },
          {
            type: "li",
            value: "Setting up a clear follow-up and next steps."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e8f3": {
    content: [
      {
        type: "p",
        value:
          "In order to impact an organization it is important to be able to accurately convey the value proposition of our firm. This implies conveying the theory of impact of our services, teaching the specific tools, and personifying our values."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Teaching exactly how our services create impact and why we are confident. "
          },
          {
            type: "li",
            value:
              "Demonstrating our tools and software with real muscle memory."
          },
          {
            type: "li",
            value:
              "Using stories, 'from-to' articulations, and case examples to showcase our approaches and technologies."
          },
          {
            type: "li",
            value: "Using relaxed, helpful, humor to convey our points of view."
          },
          {
            type: "li",
            value:
              "Using questions to clarify client's questions. Don't just reload and react, but seek to understand."
          },
          {
            type: "li",
            value: "Focusing on client impact first and foremost."
          }
        ]
      }
    ]
  },
  "5878da5db6789281bd55e975": {
    content: [
      {
        type: "p",
        value:
          "This skill is about crafting the terms and arrangements for a client proposal that is a win-win and a fair exchange of value. That includes building relationships, identifying compelling events, and collaborating with internal experts."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Continually learning from your colleagues about your organization's strengths and weaknesses (so you can craft a specific and bold proposal for clients)."
          },
          {
            type: "li",
            value:
              "Continually learning about how competition and alternatives."
          },
          {
            type: "li",
            value:
              "Creating and using clear pricing tools to ensure fairness and consistency within a client organization."
          },
          {
            type: "li",
            value:
              "Creating clear materials to explain the arrangements without confusion."
          },
          {
            type: "li",
            value:
              "Giving the client real options that are compelling, including a simple, fast trial."
          },
          {
            type: "li",
            value: "Forming a hypothesis the prospective client's budget."
          },
          {
            type: "li",
            value: "Developing a win/win fee structure."
          }
        ]
      }
    ]
  },
  "593d80353200001800d6449f": {
    content: [
      {
        type: "p",
        value:
          "It is not uncommon for even the most passionate potential clients to struggle to take action within their organizations.  Complex webs of decision makers, internal politics, unclear budgets, all contribute to slow decision making. This skill is about helping a prospective client to move forward on a potential working partnership of some kind."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Analyzing the organizational dynamics to predict the headwinds that a client decision maker might feel."
          },
          {
            type: "li",
            value:
              "Counseling the client on who to bring on board and how to build internal conviction."
          },
          {
            type: "li",
            value:
              "Building the client's excitement (i.e., their tomo) to stay the course (e.g., introducing them to existing clients, seeing the impact for themselves)."
          },
          {
            type: "li",
            value:
              "Forming a prediction of the risks and fears that would most prevent the client organization from taking action.  Forming a set of mitigants or explanations to reduce the perception of risk."
          },
          {
            type: "li",
            value:
              "Helping the client to form a compelling internal business case for the proposal (e.g., by calculating and presenting the expected impact, sharing impact cases)."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c64": {
    content: [
      {
        type: "p",
        value:
          "Building a community of people who we can help with out mission—prospective customers, partners, and recruits—is an important part of creating impact at the front line. "
      },
      {
        type: "p",
        value:
          "Why?  Our front line colleagues should be ambassadors of our mission. They are the ones that have to help the world understand what we do and why that matters."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Proactively seeking out and meeting prospective customers."
          },
          {
            type: "li",
            value:
              "Proactively seeking out influencers in your community who could help expand our impact."
          },
          {
            type: "li",
            value:
              "Proactively seeking out and meeting potential recruits who would feel play and purpose for our work."
          },
          {
            type: "li",
            value:
              "Practicing this skill as part of your day to day life. Every event is an opportunity to help someone through our work. "
          },
          {
            type: "li",
            value:
              "Practicing this strategically, for example by maintaining a list of people who you want to get to know."
          },
          {
            type: "li",
            value:
              "With all of these colleagues,  teaching them about the problem our Firm is solving on their behalf."
          }
        ]
      },
      {
        type: "p",
        value:
          "At the point of fluency you are just becoming 'the mayor' of our area of expertise within your community."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "The mission of the firm and how that relates to our customers' problems."
          },
          {
            type: "li",
            value: "The key influencers in your community."
          },
          {
            type: "li",
            value: "Any CRM technology used by our firm."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c66": {
    content: [
      {
        type: "p",
        value:
          "In many cases, a prospective customer won't engage with you in a  conversation about their true needs if they don't trust you as an advisor. For prospective customers, who don't yet know what we offer, you should first develop a professional, trust-based advisory relationship."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "As your begin forming relationships, you are not transactional. Your goal is merely to get to know the customer as friends, and understand their context."
          },
          {
            type: "li",
            value:
              "When the opportunity presents itself to be helpful (on anything), you find away to help, even if independent of our own offerings. Always be helping. This is where we show that we all live in service of others."
          },
          {
            type: "li",
            value:
              "Even though these are pre-customers, you still showcase professionalism so that if they were to become customers, they view you as someone who can help."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c68": {
    content: [
      {
        type: "p",
        value:
          "At the initial moments when a potential customer is actually looking for help from our firm, the first step is to genuinely and deeply understand their problem and needs (not jump straight to selling a product or service). "
      },
      {
        type: "p",
        value:
          "Why? Because impacting our customers positively matters more than just closing a sale. So we have to make sure we understand their needs, so we can ultimately (no this skill) solve those needs."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Approaching a customer and breaking the ice."
          },
          {
            type: "li",
            value:
              "Mastering the art of asking questions to identify needs without being annoying."
          },
          {
            type: "li",
            value: "Mastering the art of 'guess' and 'check'."
          },
          {
            type: "li",
            value:
              "Going deep in the questioning to get beyond the 'symptom' or the 'stated need' to the deeper, underlying problem."
          },
          {
            type: "li",
            value:
              "Maintaining an approachable, friendly, reassuring body language."
          },
          {
            type: "li",
            value: "Sharing your own stories about similar problems and needs."
          },
          {
            type: "li",
            value:
              "Giving the customer confidence that you will help with those needs regardless of whether or not they use our product."
          },
          {
            type: "li",
            value:
              "Using educated guesses to predict the customer's need and test that prediction (i.e., 'guess and check'). At this point you are showing you can predict the customer's need with only a few inputs."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Best practice talking points to open a dialog with customers"
          },
          {
            type: "li",
            value:
              "Best-practice talking points used to diagnose customer needs"
          },
          {
            type: "li",
            value: "The primary customer personas (i.e., customer segments)"
          },
          {
            type: "li",
            value:
              "All our product / service features and the benefits they create for each customer persona"
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c6a": {
    content: [
      {
        type: "p",
        value:
          "Once you understand the customer's need, it is time to solve that need. This means trying to first figure out which (if any) of our products and services could best help the customer."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Actively using all our products where applicable, or you have at least tried / witnessed our products and services. It is really hard to understand a product without trying it yourself."
          },
          {
            type: "li",
            value: "Summarizing (out loud) the customers' needs."
          },
          {
            type: "li",
            value:
              "Asking yourself, which of our products and services (if any) will meet those needs, and try to find the simplest path to help the customer."
          },
          {
            type: "li",
            value:
              "If none of our products and services meet our customers' needs, thinking through non-competitive offerings that could help."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "The primary customer personas (i.e., customer segments)"
          },
          {
            type: "li",
            value:
              "All our product / service features and the benefits they create for each customer persona"
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c6c": {
    content: [
      {
        type: "p",
        value:
          "Once you have figured out which of our products and services best meet customers needs, you will have to teach the customer understand what you know to be true."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Using the best talking points we have about each product and feature."
          },
          {
            type: "li",
            value:
              "Teaching to customers by explaining our services in a way that relates to customers."
          },
          {
            type: "li",
            value: "Not using high pressure tactics."
          },
          {
            type: "li",
            value: "Never distorting the truth."
          },
          {
            type: "li",
            value: "Educating by demonstrating the product or service."
          },
          {
            type: "li",
            value:
              "Showing passion for the product because you also use it (where applicable)."
          },
          {
            type: "li",
            value:
              "Articulating the differences between our product and those of relevant competitors."
          },
          {
            type: "li",
            value:
              "Understanding when to use the tactics above and when not to."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Our best-practice talking points."
          },
          {
            type: "li",
            value: "What all our features and services actually do."
          },
          {
            type: "li",
            value: "Who our customers will view as competitors or alternatives."
          },
          {
            type: "li",
            value:
              "How our features and services compare to those of competitors that our customers' are most likely evaluating."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c6e": {
    content: [
      {
        type: "p",
        value:
          "This skill is about knowing how to learn, apply, and test complex math as part of your work."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Learning complex mathematical processes, in which numbers have to be used from unstructured sources."
          },
          {
            type: "li",
            value:
              "Committing to memory the guidelines needed for mathematical work steps."
          },
          {
            type: "li",
            value:
              "Using spreadsheets and other calculators for problems like testing credit worthiness or accounting for a small business."
          },
          {
            type: "li",
            value:
              "Sanity checking math in your mind, by checking if outputs are in the range you expect."
          },
          {
            type: "li",
            value:
              "Creating models that can process long sets of numerical data with subtotals, totals, and category totals (e.g., using formulas like SUMIF)"
          },
          {
            type: "li",
            value:
              "Using spreadsheet software (e.g., Excel) with rapid proficiency. That means being faster in excel than you would have been with a calculator."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "The guidelines and policies used in your mathematical process."
          },
          {
            type: "li",
            value: "The software needed to facilitate your calculations."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c70": {
    content: [
      {
        type: "p",
        value:
          "For an organization to deliver the highest possible impact to customers, its processes and policies will need to be continuously improved. The skill is about quickly and accurately learning any new high-risk process, policy, or best practice."
      },
      {
        type: "p",
        value:
          "Master artists go through a similar process. First, they learn how a technique was used by earlier masters. Then, they discover how to use that information to develop their own practice."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Learning enterprise processes. This includes being able to pick up a new, detailed, enterprise process within a month of practicing it. That means learning the policies/guardrails for that process, along with the technologies needed to implement it."
          },
          {
            type: "li",
            value:
              "Committing most of the common policies and processes to memory (or creating simple job aids and cheat sheets to help you). When starting a role, it's up to you to study any relevant policies, processes, or best practices."
          },
          {
            type: "li",
            value: "Committing any technological process elements to memory."
          },
          {
            type: "li",
            value:
              "Learning when and how to adapt from enterprise processes. In addition, you learn the underlying intent of that process in order to seek alternatives, escalate, or seek help from your peers when a customer's context is different from what you are accustomed to."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "The common processes and policies in all transactions of your work."
          },
          {
            type: "li",
            value:
              "How to use each of the technologies associated with your processes."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c72": {
    content: [
      {
        type: "p",
        value:
          "Occasionally, the front-line will need to customize or create informational materials for their customers. However doing so well requires creating materials that are consisted with the values and brand standards of the organization."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Creating clear and compelling sales and service materials"
          },
          {
            type: "li",
            value: "Writing grammatically correct, and succinct copy"
          },
          {
            type: "li",
            value:
              "Using the branding of the organization (e.g., color, images) to ensure consistency across all our colleagues"
          }
        ]
      },
      {
        type: "h4",
        value: " Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Brand standards"
          },
          {
            type: "li",
            value: "Basic writing conventions"
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c74": {
    content: [
      {
        type: "p",
        value:
          "In order to help someone, especially a customer, who is having difficulty, it is important to start by having empathy. Empathy means, 'the ability to understand and share the feelings of another.'"
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "When a customer shares their problem, you ask questions, if necessary to make sure you fully understand the problem."
          },
          {
            type: "li",
            value:
              "Simultaneously, you ask yourself, how do you think this customer is feeling? How sure are you about this? How would you feel if you were in this situation."
          },
          {
            type: "li",
            value:
              "You playback for the customer your understanding of the problem to make sure you heard correctly."
          },
          {
            type: "li",
            value:
              "You modulate your approach based on your understanding how the customer is feeling. For example, if the customer is feeling anxiety, you share something like, 'Don't worry, because I've got your back.'  If the customer is feeling angry, you share something like, 'I can only imagine how you must be feeling. I'll do whatever I possibly can to help you.'  If the customer is feeling playful, you play along (but always professionally)."
          },
          {
            type: "li",
            value:
              "You absolutely do not apply any biases (e.g., age, gender, race) as you seek to understand the customer. The customer is never to blame."
          }
        ]
      },
      {
        type: "p",
        value:
          "Fluency in this skill requires knowledge of the common issues and cases that a customer may be feeling to help you quickly diagnose their core issue."
      }
    ]
  },
  "5b9adc76063092ce46b00c76": {
    content: [
      {
        type: "p",
        value:
          "This skill is about executing a complex procedure, process, checklist, or any other system of tactical performance with a high degree of accuracy. That means being able to identify where a process may not have an expected outcome and adapting accordingly. It also means being able to use tools and systems designed to maintain tactical performance with accuracy."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Executing operational processes with infrequent errors or delays (e.g., invoicing, expense management, tax, and accounting)."
          },
          {
            type: "li",
            value:
              "Identifying weak points in the process and pressure testing accordingly."
          },
          {
            type: "li",
            value: "Working more frequently with external providers."
          },
          {
            type: "li",
            value:
              "Managing meeting logistics for third parties ranging from 1:1 to complex large-scale presentations."
          },
          {
            type: "li",
            value:
              "Coordinating calendars, physical space, budget constraints, and any necessary documents prior to meetings."
          },
          {
            type: "li",
            value:
              "Partnering with external vendors and internal partners (e.g., food delivery, technology set up)."
          },
          {
            type: "li",
            value:
              "Helping your clients plan the scope of an event, including its time, location, and cost."
          },
          {
            type: "li",
            value:
              "Inspecting venues to ensure that they meet your client's requirements."
          },
          {
            type: "li",
            value:
              "Monitoring event activities to ensure that your client and event attendees are satisfied."
          },
          {
            type: "li",
            value: "Using vendor product demonstration meetings productively."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c78": {
    content: [
      {
        type: "p",
        value:
          "On occasion, a customer cannot accurately describe the root issues of their problems. They describe symptoms which could be as broad as, 'it doesn't work.' It is at this point where you will need to troubleshoot to find the root causes of a customer's problem."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Treating each customer problem, especially the thorny problems, as interesting mysteries that should be unraveled."
          },
          {
            type: "li",
            value:
              "Identifying the customers' level of expertise up front. Are they power-users of your services or novices?  If they are power-users, your style of working with them should a peer-like partnership. If they are novices, you'll need to be a patient teacher."
          },
          {
            type: "li",
            value:
              "Depending on the customer's style, you take the customer through a series of questions or mini-experiments to help diagnose exactly what is blocking them."
          },
          {
            type: "li",
            value:
              "When in doubt, you pull in colleagues to help you or you use the knowledgebase of the organization to find answers."
          },
          {
            type: "li",
            value:
              "You always maintain a brief summary of the customer's problem to (1) validate with the customer, and (2) quickly bring colleagues up to speed."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "The issues that represent 80% of customer problems."
          },
          {
            type: "li",
            value: "Policies associated with common customer issues."
          },
          {
            type: "li",
            value: "Any technology used to capture troubleshooting knowledge."
          },
          {
            type: "li",
            value: "The escalation paths of the organization."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c7a": {
    content: [
      {
        type: "p",
        value:
          "Teams often share common workspaces, tools, and materials. If these environments lack a systemic approach to organization, it can become very difficult for anyone on the team to have impact. This skill is about becoming a better user of space in ANY environment, not just the one you're in now."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Knowing the organizational conventions of any common space you work in (e.g., where tools and work items can be found and where they should be returned)."
          },
          {
            type: "li",
            value: "Living by the norms of common work spaces."
          },
          {
            type: "li",
            value:
              "Following what's needed to keep common tools and equipment well maintained."
          },
          {
            type: "li",
            value: "Fixing any problem that you encounter."
          },
          {
            type: "li",
            value: "Being a custodian of your raw materials."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c7c": {
    content: [
      {
        type: "p",
        value:
          "Once you understand the root cause of a customer's problem, you will have to resolve the problem, both technically and emotionally. Resolving a customer's problem requires you to fully shepherd the customer to a resolution that makes them feel emotionally satisfied."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Treating each customer interaction as an experiment on how to make another person happy (even in difficult situations)"
          },
          {
            type: "li",
            value:
              "Viewing yourself as the customer's advocate through this process"
          },
          {
            type: "li",
            value:
              "Continuously asking yourself what the emotional state of the customer is at each moment of the conversation."
          },
          {
            type: "li",
            value:
              "Completing all the technical and process steps needed to actually give the customer resolution to their problem."
          },
          {
            type: "li",
            value:
              "Finding ways to keep ownership of the customer until the issue has been fully resolved."
          },
          {
            type: "li",
            value:
              "Keeping the customer informed about where you are in the process with useful, confidence building information."
          },
          {
            type: "li",
            value:
              "Accurately documenting any learnings for future similar customer issues."
          },
          {
            type: "li",
            value:
              "Tactfully and genuinely apologizing for issues that we created."
          },
          {
            type: "li",
            value:
              "Sharing with customers what you've learned from this interaction and passing it along to colleagues who would benefit from this insight."
          },
          {
            type: "li",
            value:
              "When our policy or approach does not make sense given the current customer's context, escalating or pulling in help to find a better answer."
          },
          {
            type: "li",
            value:
              " If you are left unable to fully satisfy the customer's problem, let them know and let them know that you will bring this up with your colleagues with the hope of fixing this for future customers. For example, 'I'm really sorry Mr. Nelson. Our policy doesn't allow me to accept this return from you because it is over 4 months old. I will reach out to my colleagues who are in charge of this policy to see if in the future we can change it for situations like yours.'"
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "The policies and procedures of customer support."
          },
          {
            type: "li",
            value: "Organizational teams who may own certain policies."
          },
          {
            type: "li",
            value: "The processes used to resolve 80% of customer issues."
          },
          {
            type: "li",
            value: "Best practice talking points for common issues."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c7e": {
    content: [
      {
        type: "p",
        value:
          "The highest form of helping a customer, is to teach them self-sufficiency—teaching them to fish rather than giving them a fish. When the situation calls for it, teach your customers to help themselves."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Asking customers tactically and without any pushiness if they want to learn how to avoid similar problems in the future."
          },
          {
            type: "li",
            value:
              "Explaining why they should learn self-sufficiency e.g., 'Learning how to do this takes about 5 minutes, but will save you about 5 minutes every month forever.'"
          },
          {
            type: "li",
            value:
              "Giving the customers options on how they can learn this where and when they want to."
          },
          {
            type: "li",
            value: "Teaching the customer patiently."
          },
          {
            type: "li",
            value: "Making learning fun for the customer."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "All our common customer interactions and the classic pitfalls."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cc1": {
    content: [
      {
        type: "p",
        value:
          "When presenting at a higher-stakes professional situation like a meeting with critical external stakeholders, a new set of skills can help you mitigate risk and have a positive impact. This skill is about being impactful in those types of situations."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Designing board-level decks that can be used as read-ahead. At the board level, these decks can easily become public record. Therefore, it's important to validate the tone of each sentence and the quality of each data point."
          },
          {
            type: "li",
            value:
              "Building effective executive summaries that distill a complex board-level issue to a one-pager with the appropriate structure (e.g., 'situation-complication-resolution', 'birth-death-rebirth')."
          },
          {
            type: "li",
            value:
              "Tightly managing a conversation's flow. During a board-level meeting, there is a real possibility of a detour or 'curve ball.' Some of those detours are worth taking about at the moment. Most, however, should be explored later in the meeting or saved entirely for a different date."
          },
          {
            type: "li",
            value:
              "Creating clarity on the ask of each meeting participant before and after the meeting."
          },
          {
            type: "li",
            value:
              "Balancing tactical and adaptive performance messages, (e.g., when in doubt, half the message is about the impact already created and half is about the opportunity still remaining)."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cbf": {
    content: [
      {
        type: "p",
        value:
          "Occasionally, you may have the opportunity to present to a large audience (100+ people). Doing so in a way that is memorable and impactful is itself a skill.  Communicating on stage is about stage presence and the creation of stage-friendly materials."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Using a whole stage dynamically."
          },
          {
            type: "li",
            value: "Maintaining eye contact with the audience."
          },
          {
            type: "li",
            value: "Using open body language and gestures."
          },
          {
            type: "li",
            value: "Projecting your voice."
          },
          {
            type: "li",
            value: "Using materials/visuals designed for a large audience."
          },
          {
            type: "li",
            value:
              "Designing materials that are complementary to your spoken word."
          },
          {
            type: "li",
            value:
              "Designing materials that are created for visual presentation (rather than as an accompaniment to a written piece)."
          },
          {
            type: "li",
            value: "Following the organization's standards of professionalism."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "The rhythm and flow of storytelling."
          },
          {
            type: "li",
            value:
              "The fundamentals of the organization's unique outlook or value proposition."
          },
          {
            type: "li",
            value: "Brand standards."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cbd": {
    content: [
      {
        type: "p",
        value:
          "The more adaptive an organization is, the more adept every colleague will need to become at documenting learnings and sharing perspectives. This skill focuses on the efficient communication of information through professional long-form writing."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Writing memos, posters, briefs, and articles that are customer appropriate."
          },
          {
            type: "li",
            value:
              "Applying the same conceptual structure as described in the skill _Communicating with Structure_ for longer form writing."
          },
          {
            type: "li",
            value:
              "Taking structure to the next level in longer-form writing by providing clear summaries, subpoints (i.e., 'clunks'), and conclusion."
          },
          {
            type: "li",
            value: "Writing in logically grouped paragraphs."
          },
          {
            type: "li",
            value: "Following brand standards."
          },
          {
            type: "li",
            value: "Writing long-form as it represents the entire organization."
          },
          {
            type: "li",
            value:
              "Writing long-form to a high professional standard, (i.e., one which your customers would be impressed by). "
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Good grammar, sentence structure, pacing and narrative clarity. "
          },
          {
            type: "li",
            value: "Brand standards."
          },
          {
            type: "li",
            value:
              "The core beliefs, mission, and theory of impact of the organization."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cbb": {
    content: [
      {
        type: "p",
        value:
          "Often visuals (like slides or charts) are the most effective way to share information with large groups of people. This skill is about using visuals when necessary to convey structured, quantitative information. One of the major barriers to great team performance is being able to communicate complex thoughts. Often, the rate of change in an organization is driven by the communication skills of that organization. Therefore, a well-designed visual can be the key to effectively sharing information. "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Making visuals (e.g., PowerPoint slides) to display accurate data."
          },
          {
            type: "li",
            value:
              "Using standard chart types (bar, column, flag, waterfall, histogram, line, scatter) and understanding how each displays information."
          },
          {
            type: "li",
            value: "Applying your organization's brand conventions."
          },
          {
            type: "li",
            value:
              "Designing the visual so that the insight (i.e., the 'so what') can be easily understood."
          },
          {
            type: "li",
            value:
              "Having a logical information hierarchy (e.g., main point is most obvious, key information is next, second-order points are third, footnotes are fourth, etc.)."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "The software the organization uses for its visuals."
          },
          {
            type: "li",
            value: "Organizational norms around visual design."
          },
          {
            type: "li",
            value: "Brand standards."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00c94": {
    content: [
      {
        type: "p",
        value:
          "This skill is about using observational and conversational techniques to improve your company's ability to make predictions. That means using ethnographic research to articulate the impact you hope to create and a causal explanation of how your work creates that very impact."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Articulating your best theory of impact."
          },
          {
            type: "li",
            value: "Explaining the hypothesis based on your theory."
          },
          {
            type: "li",
            value:
              "Understanding which segments are strategically critical (e.g., research about decision makers, early adopters, or influencers may drive greater impact than research about a random population)."
          },
          {
            type: "li",
            value: "Considering which data you're going to need."
          },
          {
            type: "li",
            value:
              "Researching if there a natural experiment that has already been conducted."
          },
          {
            type: "li",
            value:
              "Knowing the experimental plan (i.e., what data do you need and how will you gather it?)."
          },
          {
            type: "li",
            value: "Identifying how you will test for causality."
          },
          {
            type: "li",
            value:
              "Optimizing ROI (e.g., cost of the experiment versus value of the knowledge)."
          }
        ]
      }
    ]
  },
  "593db36e3200001300d644a5": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating an experiment that will help your team identify where and why it is having the impact that it is."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Knowing the hypothesis you're testing."
          },
          {
            type: "li",
            value:
              "Identifying what data you will need to test that hypothesis."
          },
          {
            type: "li",
            value:
              "Researching if there is a natural experiment that has already been conducted."
          },
          {
            type: "li",
            value:
              "Knowing the experimental plan (i.e., what data do you need and how will you gather it?)."
          },
          {
            type: "li",
            value: "Identifying how you will test for causality."
          },
          {
            type: "li",
            value:
              "Optimizing ROI (e.g., cost of the experiment versus value of the knowledge)."
          },
          {
            type: "li",
            value:
              "Knowing which other insight gathering strategies have a higher ROI."
          }
        ]
      }
    ]
  },
  "5878da5bb6789281bd55e8cb": {
    content: [
      {
        type: "p",
        value:
          "Surveys can be useful when testing a hypothesis around human sentiment or decision making. This skill is about creating an impactful survey for your company."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Articulating the problem statement or hypotheses you plan to test."
          },
          {
            type: "li",
            value:
              "Determining which outputs you want to create (i.e., including the visuals themselves and the analytics you'd expect)."
          },
          {
            type: "li",
            value:
              "Knowing what information you need to produce the ideal outcome."
          },
          {
            type: "li",
            value:
              "Deciding the target population, question types (likert, rank, yes/no), and order of questions for your survey. "
          },
          {
            type: "li",
            value: "Sharing your above decisions with experts on the topic."
          },
          {
            type: "li",
            value:
              "Coding and testing the survey. (Make sure to test the output to ensure it can be easily analyzed)."
          },
          {
            type: "li",
            value: "Executing and monitoring the survey."
          },
          {
            type: "li",
            value:
              "Reviewing the results to check for validity before conducting any additional analyses."
          }
        ]
      }
    ]
  },
  "59aef2e0300000200016e9c9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about designing user interfaces that are aesthetically pleasing."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Immersing yourself in the aesthetic choices of  each customer segment that your product targets."
          },
          {
            type: "li",
            value:
              "Identifying how aesthetics for a product may differ based on strategy or brand positioning."
          },
          {
            type: "li",
            value:
              "Applying standards of color theory, legibility, design grids, patterns, typography, and whitespace to product aesthetics."
          },
          {
            type: "li",
            value:
              "Maintaining design boards of different styles. This will facilitate choosing a design language for a given customer segment."
          }
        ]
      },
      {
        type: "h4",
        value: "Signs of someone still learning this skill might include:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Being able to design the customer segment that they represent, but not for other segments."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Photoshop, Illustrator, and other design software."
          }
        ]
      }
    ]
  },
  "59aef0ed300000160016e9c1": {
    content: [
      {
        type: "p",
        value:
          "This skill is about designing user interfaces through an engineering lens (i.e., considering the necessary time to build, debug, reuse, and maintain code). "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Understanding how long it takes to implement and fully debug a design. "
          },
          {
            type: "li",
            value:
              "Forming your own hypothesis on how long build-time would be."
          },
          {
            type: "li",
            value:
              "Minimizing the edge cases in design that end up consuming significant engineering resources."
          },
          {
            type: "li",
            value:
              "Designing to maximize off-the-shelf and reusable components."
          },
          {
            type: "li",
            value:
              "Working with engineers to further simplify designs and accelerate development."
          }
        ]
      }
    ]
  },
  "59aef182300000130016e9c5": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating a design language and guide that maximize usability and engineering productivity for any large application or software platform."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Gathering design usability patterns from other applications."
          },
          {
            type: "li",
            value:
              "Understanding the usability patterns that your target customer segment is most familiar with."
          },
          {
            type: "li",
            value:
              "Creating style guides and UX components that can be consistently used throughout the platform or any other large application. "
          },
          {
            type: "li",
            value:
              "Designing tools that engineers can use to build user interfaces with a common set of design components."
          },
          {
            type: "li",
            value:
              "Creating style sheets that are easily extended, maintained, or used as themes."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "CSS"
          },
          {
            type: "li",
            value: "HTML"
          },
          {
            type: "li",
            value: "Simple JavaScript"
          }
        ]
      }
    ]
  },
  "59aef38c3000003d0016e9cb": {
    content: [
      {
        type: "p",
        value:
          "This skill is about designing user experiences that optimize for user psychology. That means using psychological frameworks to solve for behavioral change, habit forming, and goal completion within an application."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "p",
        value: "Applying:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Tomo theory (total motivation) to build play and purpose into an application (e.g., gamifying data)."
          },
          {
            type: "li",
            value: "Habit forming theory."
          },
          {
            type: "li",
            value: "Choice architecture."
          },
          {
            type: "li",
            value: "Social psychology."
          },
          {
            type: "li",
            value: "The psychology of influence"
          }
        ]
      }
    ]
  },
  "59aef2c03000003e0016e9c7": {
    content: [
      {
        type: "p",
        value:
          "Today, web applications can be viewed on screens ranging from a mobile phone to a 4K widescreen TV. This skill is about designing applications that are responsive to screen time."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Designing a set of components that facilitate responsiveness."
          },
          {
            type: "li",
            value:
              "Creating components that are suited for different modalities (touch screens, mouse, keyboard)."
          },
          {
            type: "li",
            value:
              "Building flows that can easily work at different screen sizes."
          },
          {
            type: "li",
            value:
              "Applying standards of readability regardless of screen size (e.g., comfortable line lengths, text sizes)."
          },
          {
            type: "li",
            value:
              "Staying up-to-date on common responsive UI frameworks like Angular Material, Bootstrap, Skeleton, and Foundation. That means understanding the pros and cons of each and when to apply them."
          },
          {
            type: "li",
            value: "Writing efficient media queries when necessary."
          },
          {
            type: "li",
            value:
              "Running a design process where you test on multiple screen sizes and modalities simultaneously."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00ca5": {
    content: [
      {
        type: "p",
        value:
          "This skill is about conducting analyses and present insights about how complex shared costs allocate to revenues."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Conducting analyses to determine how complex shared costs allocate across businesses or products (e.g., activity based costing)."
          },
          {
            type: "li",
            value:
              "Identifying the strategic implications of using cost drivers (e.g., FTE, revenue, assets) and making recommendations accordingly. "
          },
          {
            type: "li",
            value:
              "Highlighting the difference between core and non-core expenses, along with fixed, quasi-variable and variable expenses to assist in strategic decisions."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00ca7": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating budgets that will keep your business safe, meet the requirements of capital providers, and allow colleagues to have greater freedom in their decision making."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Creating budgets for whole businesses."
          },
          {
            type: "li",
            value:
              "Maintaining a process for tracking expenses against the budget."
          },
          {
            type: "li",
            value: "Providing ongoing budget clarity to all stakeholders."
          },
          {
            type: "li",
            value: "Making quick adjustments with new information."
          },
          {
            type: "li",
            value:
              "Avoiding “use-it-or-lose-it” behavior by constantly checking the value of the open budget."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00ca9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating a process for hedging financial risks."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Identifying any potential drivers of external financial risk (e.g., forex exposure, commodity prices)."
          },
          {
            type: "li",
            value: "Creating strategies for hedging those risks."
          },
          {
            type: "li",
            value:
              "Developing NPV positive hedging strategies by modeling the unhedged risk and hedge cost."
          },
          {
            type: "li",
            value:
              "Executing hedges (often through a broker or other third party)."
          },
          {
            type: "li",
            value: "Developing a process for managing hedges."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cab": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building external financial reports (e.g., income statements, statements of cash flows, balance sheets). That includes gathering necessary inputs from financial systems / GL, along with making adjustments to follow GAAP and other pertinent laws and regulations. "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building audible, public financial statements that follow all relevant accounting rules."
          },
          {
            type: "li",
            value: "Creating addendums to reconcile any potential issues."
          },
          {
            type: "li",
            value:
              "Ensuring an unimpeachable level of quality so that external confidence remains high."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cad": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building valuation models of companies using a variety of techniques, including discounted cashflow models, comps analysis, sum-of-parts comparable analysis, and valuation ratios. "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building valuation calculations using the common techniques mentioned above."
          },
          {
            type: "li",
            value:
              "Justifying decisions based on key valuation parameters (e.g., discount rate, terminal growth rate, choice of comps). "
          },
          {
            type: "li",
            value:
              "Adjusting financial statements to produce comparable inputs or accurate cash flows."
          },
          {
            type: "li",
            value:
              "Creating models that can be used for complex decision making (e.g., merger and acquisition decisions)."
          },
          {
            type: "li",
            value:
              "Building scenarios to better understand the most sensitive and risky inputs of the model."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00caf": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building, maintaining, and optimizing processes to manage all costs and revenues in your company. "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Creating financial processes and routines that provide economic clarity."
          },
          {
            type: "li",
            value:
              "Sharing insights and reports to facilitate financial decision making."
          },
          {
            type: "li",
            value: "Automating financial reporting."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cb1": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building processes that manage the cash flow cycle (e.g., accounts receivable, accounts payable), minimize cash leakage, ensure timely receivables and payables, optimize taxes, and reduce payment costs."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Improving payment mechanisms to reduce cost and risk."
          },
          {
            type: "li",
            value:
              "Creating a process for receivables to eliminate revenue leakage."
          },
          {
            type: "li",
            value: "Developing receivables strategies in order to save time."
          },
          {
            type: "li",
            value: "Leading collections processes."
          },
          {
            type: "li",
            value:
              "Treating partners with respect and courtesy by consistently paying on time."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cb3": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building processes that manage the cash flow cycle (e.g., accounts receivable, accounts payable), minimize cash leakage, ensure timely receivables and payables, optimize taxes, and reduce payment costs."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Improving payment mechanisms to reduce cost and risk."
          },
          {
            type: "li",
            value:
              "Creating a process for receivables to eliminate revenue leakage."
          },
          {
            type: "li",
            value: "Developing receivables strategies in order to save time."
          },
          {
            type: "li",
            value: "Leading collections processes."
          },
          {
            type: "li",
            value:
              "Treating partners with respect and courtesy by consistently paying on time."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cb5": {
    content: [
      {
        type: "p",
        value: "This skill is about helping clients manage international laws."
      },
      {
        type: "h4",
        value: "This includes knowing:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Canada's retail entity compliance."
          },
          {
            type: "li",
            value: "India's compliance on trading and contracting."
          },
          {
            type: "li",
            value: "Japan's compliance on consulting and software services."
          },
          {
            type: "li",
            value: "Mexico's wholesale market participation."
          }
        ]
      },
      {
        type: "p",
        value:
          "This skill can range from low to high-tomo depending on the individual case. However, it's easy to slip into low-tomo when compliance with state rules is viewed as a 'necessary evil' rather than an important part of market participation. Low tomo in this case would look like associates trying to just get the filing done, avoid penalties, and do the least amount possible to stay compliant. As a result of this, colleagues will take less ownership, solve fewer problems, stop innovating, and ultimately, start gaming the system. When done well, however, this skill can help protect the company while simultaneously providing clients with clear expectations and exceptional service."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Being familiar with international laws and how to connect to those entities."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cb7": {
    content: [
      {
        type: "p",
        value:
          "This skill is about helping clients manage compliance with regional and federal laws."
      },
      {
        type: "p",
        value: "Client interactions and contract review include:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Preparing client applications to ISOs, REGGI, and FERC Market Based Rate Authority, among others."
          },
          {
            type: "li",
            value:
              "Reviewing compliance with all ISO and FERC regulations and filings."
          },
          {
            type: "li",
            value: "Helping clients with disputes with ISO/Federal Agencies."
          }
        ]
      },
      {
        type: "p",
        value:
          "This skill can range from low to high-tomo depending on the individual case. However, it's easy to slip into low-tomo when compliance with state rules is viewed as a 'necessary evil' rather than an important part of market participation. Low tomo in this case would look like associates trying to just get the filing done, avoid penalties, and do the least amount of work possible to stay compliant. As a result of this, colleagues will take less ownership, solve fewer problems, stop innovating, and ultimately, start gaming the system."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Knowing your state's renewable portfolio obligation standards."
          },
          {
            type: "li",
            value:
              "Being familiar with the application processes and idiosyncrasies associated with filing rules (i.e. actual versus published timelines)."
          },
          {
            type: "li",
            value:
              "Identifying which questions to ask clients about their application and compliance filings."
          },
          {
            type: "li",
            value:
              "Building a professional relationship with Federal and Regional staff to ensure that processes run efficiently."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cb9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about helping clients manage compliance with state laws."
      },
      {
        type: "h4",
        value: "Client Interactions and Rule Review include:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Preparing client applications to State Regulatory Authorities."
          },
          {
            type: "li",
            value:
              "Reviewing the state's compliance filings such as the Renewable Portfolio Standards Applications."
          },
          {
            type: "li",
            value: "Reviewing marketing claims and compliance with state rules."
          },
          {
            type: "li",
            value: "Helping clients with disputes with State agencies."
          }
        ]
      },
      {
        type: "p",
        value:
          "This skill can range from low to high-tomo depending on each individual case. However, it's easy to slip into low-tomo when compliance with state rules is viewed as a 'necessary evil' rather than an important part of market participation. Low tomo in this case would look like associates trying to just get the filing done, avoid penalties, and do the least amount of work possible to stay compliant. As a result of this, colleagues will take less ownership, solve fewer problems, stop innovating, and ultimately, start gaming the system."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Knowing your state's renewable portfolio obligation standards."
          },
          {
            type: "li",
            value:
              "Understanding the application processes and filing rules (i.e. actual versus published timelines)."
          },
          {
            type: "li",
            value:
              "Identifying which questions to ask clients about their application and compliance filings."
          },
          {
            type: "li",
            value:
              "Having a professional relationship with state contacts to ensure processes run efficiently."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cbb": {
    content: [
      {
        type: "p",
        value:
          "This skill is about having a system in place to receive internal advice and feedback."
      },
      {
        type: "h4",
        value: "In House Legal Advisory Skills include:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Advising your company about how to resolve internal and external disputes."
          },
          {
            type: "li",
            value:
              "Representing your company in third party actions such as a client bankruptcy."
          },
          {
            type: "li",
            value: "Advising your company about its risk management practices."
          },
          {
            type: "li",
            value: "Using external counsel when you need additional support."
          }
        ]
      },
      {
        type: "p",
        value:
          "In most organizations, in-house legal advisory can range from low to medium tomo depending on the situation. At low-tomo, colleagues think they should say what someone wants to hear, fear failure, and passively hope that things won't become an issue in the future. As a result of these feelings, colleagues take less ownership, solve fewer problems, stop innovating, and ultimately, start gaming the system (e.g., setting up policies that seem effective but aren't really when put into practice)."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Knowing the main idea of most laws."
          },
          {
            type: "li",
            value: "Working with external counsel."
          },
          {
            type: "li",
            value: "Being able to communicate effectively."
          },
          {
            type: "li",
            value:
              "Using your company's mission and culture to know which paths would be best for the company."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cbd": {
    content: [
      {
        type: "p",
        value:
          "This skill is about ensuring that your HR practices reflect your organization's values while simultaneously conform to state and federal regulations."
      },
      {
        type: "h4",
        value: "HR Legal Skills include:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Advising your company on the compliance of HR laws across multiple regions."
          },
          {
            type: "li",
            value: "Updating your associate handbook and training materials."
          },
          {
            type: "li",
            value: "Training your teams and the company on critical issues."
          },
          {
            type: "li",
            value:
              "Supporting hiring and termination procedures, along with diversity and harassment training."
          }
        ]
      },
      {
        type: "p",
        value:
          "Human Resource Legal Support can typically be low-tomo. Colleagues feel pressured about what they can and cannot do. They worry about lawsuits. Often, they start to think that problems will go away if they just ignore them. As a result, colleagues take less ownership, solve fewer problems, stop innovating, and ultimately start gaming the system (e.g., looking to obey the leader but not the spirit of the policies)."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Knowing which laws apply to your state."
          },
          {
            type: "li",
            value:
              "Seeking outside help to review the Associate Handbook, offer letters, and other HR related documents."
          },
          {
            type: "li",
            value: "Clearly articulating your intent in writing."
          },
          {
            type: "li",
            value:
              "Helping operational associates deal with issues such as medical and payroll onboarding."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cbf": {
    content: [
      {
        type: "p",
        value:
          "This skill is about using contract review to make your organization resilient over the long term. Contract review can help your team execute faster on smaller task orders than your competitors, making it possible to serve start ups and earlier stage clients. "
      },
      {
        type: "h4",
        value: "Contract Review includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Clearly expressing the expectations of both parties."
          },
          {
            type: "li",
            value:
              "Highlighting key provisions that you find acceptable. (This will guide business leaders who are involved in the negotiation)."
          },
          {
            type: "li",
            value:
              "Helping business leaders with complex issues (e.g., ownership of IP and non-compete provisions)."
          },
          {
            type: "li",
            value: "Finding solutions that would best benefit your clients."
          }
        ]
      },
      {
        type: "p",
        value:
          "Contract Review can typically be low tome. Colleagues feel pressured to get things done and win the contract negotiation. They might even feel like nothing they would do will make a difference. As a result, colleagues take less ownership, solve fewer problems, stop innovating, and ultimately, start gaming the system (e.g., do I really need to get legal to review this anyway)."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Knowing the laws that apply to each type of contract that you enter, along with the sticking points/deal breakers."
          },
          {
            type: "li",
            value:
              "Identifying potential concerns and adding clarity accordingly."
          },
          {
            type: "li",
            value: "Clearly articulating your intent in writing."
          },
          {
            type: "li",
            value:
              "Effectively negotiating and understanding the consequences of different trade-offs."
          },
          {
            type: "li",
            value:
              "Protecting your company against exposure while simultaneously laying a path forward in negotiation."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cd5": {
    content: [
      {
        type: "p",
        value:
          "This skill is about identifying opportunities for new policies. Policy design includes researching best practices, piloting possible solutions, collecting feedback after implementation, and iterating. New policies and changes to policies are well communicated and easily discoverable to a user looking for information."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Crafting policies that guide the user to the desired behavior and incorporate a level of flexibility, if applicable."
          },
          {
            type: "li",
            value:
              "Monitoring compliance with the policy and updating it as needed."
          },
          {
            type: "li",
            value:
              "Creating policies that are high-tomo and enhance adaptive performance."
          },
          {
            type: "li",
            value: "Clearly communicating new policy changes."
          },
          {
            type: "li",
            value:
              "Knowing the purpose of the policy and designing it accordingly."
          },
          {
            type: "li",
            value: "Asking experts for guidance."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cd7": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building processes used by colleagues to manage internal resources, budgets, expenses, payables, receivables, travel, etc."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building efficient processes that make use of technology without creating painful bureaucracies."
          },
          {
            type: "li",
            value: "Explaining the why behind internal processes."
          },
          {
            type: "li",
            value: "Creating space to be adaptive."
          },
          {
            type: "li",
            value:
              "Maintaining processes and technologies so that they are efficient and effective."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cd9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about using internal systems for administrative tasks. That means managing all relevant administrative processes efficiently, including travel planning, phones, onboarding/offboarding, and contractor processing."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Providing telephone and equipment lists to new and existing associates."
          },
          {
            type: "li",
            value: "Distributing updated company guidelines to associates."
          },
          {
            type: "li",
            value:
              "Coordinating with department leads to ensure smooth onboarding/offboarding transitions."
          },
          {
            type: "li",
            value:
              "Developing associate resources that adhere to company guidelines."
          },
          {
            type: "li",
            value:
              "Managing all relevant administrative processes efficiently (e.g., travel planning, phones, onboarding/offboarding, and contractor processing)."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cdb": {
    content: [
      {
        type: "p",
        value:
          "This skill is about managing your leader's calendar to ensure that they achieve their strategic goals."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Communicating with your leader to point out conflicts and offer solutions on the calendar."
          },
          {
            type: "li",
            value:
              "Taking into account the team's travel schedules, priorities, existing conflicts, business events, and regular communication routines/preferences in requests."
          },
          {
            type: "li",
            value:
              "Proficient use of Microsoft Office Suite (Word, Excel, Outlook, PowerPoint) to coordinate calendars, travel, contacts, conference bridges, appointments, and luncheons/events."
          },
          {
            type: "li",
            value:
              "Talking with multiple leaders to determine which times would be best to hold team meetings."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cdd": {
    content: [
      {
        type: "p",
        value:
          "This skill is about planning complex, large scale events (i.e., with more than 50 participants or a budget over $20K), taking into consideration the goals of the organizers, budget, and audience. "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Planning quarterly and annual meetings (e.g., travel, hotel accommodations, and venue selection, and coordination."
          },
          {
            type: "li",
            value:
              "Planning company wide social events (e.g., holiday parties and celebrations)."
          },
          {
            type: "li",
            value: "Staying within your budget."
          },
          {
            type: "li",
            value:
              "Taking into consideration attendees' dietary needs, calendar constraints, and logistics."
          },
          {
            type: "li",
            value: "Predicting reasonable travel time frames."
          },
          {
            type: "li",
            value:
              "Ensuring that all content and materials are ready before the event."
          },
          {
            type: "li",
            value: "Communicating lead times on materials."
          },
          {
            type: "li",
            value:
              "Delegating as necessary, along with ensuring that participants are aware of their respective roles."
          },
          {
            type: "li",
            value:
              "Creating a thoughtful agenda that allows for unpredicted overflow."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c97": {
    content: [
      {
        type: "p",
        value:
          "This skill is about building personal credibility (i.e., ethos) with colleagues, clients, customers, and other stakeholders."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Paying attention in all meetings and showing respect for your colleagues and customers. This means not multi-tasking or checking your phone during meetings."
          },
          {
            type: "li",
            value: "Dressing appropriately for the occasion."
          },
          {
            type: "li",
            value: "Maintaining a straight posture."
          },
          {
            type: "li",
            value: "Using non-offensive language and cursing."
          },
          {
            type: "li",
            value: "Avoiding low-tomo tactics like threats or inciting fear."
          },
          {
            type: "li",
            value: "Being precise in your descriptions."
          },
          {
            type: "li",
            value: "Not exaggerating or lying."
          },
          {
            type: "li",
            value: "Being on time and turning off any ringers or notifications."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c99": {
    content: [
      {
        type: "p",
        value:
          "The core of ethos is the simultaneous expression of confidence and humility. Expressing confidence and humility in your interactions is an important part of building your credibility and raising the tomo of those around you."
      },
      {
        type: "p",
        value:
          "Confidence is the belief that you can solve for VUCA even if you don't currently know the situation. When you experience a problem, you know that you'll somehow find a way to get through it. The opposite of confidence is becoming panicked when faced with ambiguity."
      },
      {
        type: "p",
        value:
          "Humility is the belief that you can learn from other people. It means believing that everyone has something to teach you. The opposite of humility is signaling to those around you that you have nothing to learn from them."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "p",
        value: "Confidence:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Abandoning your agenda when the conversation is off-script but heading down a path of higher impact. "
          },
          {
            type: "li",
            value:
              "Bringing the discussion back to your agenda when it is heading down a path of lower impact."
          },
          {
            type: "li",
            value:
              "Honestly articulating where you have confidence and where you don't, along with what you can do to close the gap."
          },
          {
            type: "li",
            value:
              "Taking a leadership role in the meeting (i.e., sign-posting and synthesizing in order to move the conversation forward)."
          },
          {
            type: "li",
            value:
              "Showing excitement about the challenge before you when faced with VUCA."
          }
        ]
      },
      {
        type: "p",
        value: "Humility:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Actively listening to others because you believe they have something to teach you. You can demonstrate that you're actively listening by playing back what you've heard in your own language with new examples."
          },
          {
            type: "li",
            value:
              "Conveying playfulness and attentiveness in your facial expression and body language when you're learning from others."
          },
          {
            type: "li",
            value:
              "Demonstrating that you're listening to a colleague's ideas/ concerns and are passionate about their success."
          },
          {
            type: "li",
            value:
              "Articulating what you have learned and where you want to learn."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c91": {
    content: [
      {
        type: "p",
        value:
          "Sometimes, you will want to build consensus to ensure that all colleagues support the change that you're leading. Consensus does not mean 'unanimity.' Instead, it means consent from all stakeholders. A person can disagree and still consent. Seeking unanimity when it doesn't make sense could lead to groupthink or illogical answers. Consensus, on the other hand, uses a sequence of agreements starting with the simplest foundational principles."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Gathering all the stakeholders whose consent is required for an initiative to succeed."
          },
          {
            type: "li",
            value:
              "Publicly confirming agreement with the simplest or least controversial perspectives before moving on to the more controversial ones."
          },
          {
            type: "li",
            value:
              "Understanding the underlying assumptions that could prevent an agreement from taking place."
          },
          {
            type: "li",
            value:
              "Facilitating conversations about the risks presented by those assumptions and if there is any way they can be simply tested ."
          },
          {
            type: "li",
            value:
              "Asking if dissenters are willing to consent. Or, if all else fails, seeing if this issue should be escalated."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c9b": {
    content: [
      {
        type: "p",
        value:
          "Presenting a logical argument (in which a conclusion builds upon irrefutable components) is a powerful influencing tool."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Understanding the types of reasoning and knowing when you're using each (e.g., inductive, deductive, abductive)."
          },
          {
            type: "li",
            value:
              "When possible, striving to make a deductive argument so that the conclusion is inevitable based on its foundational principles."
          },
          {
            type: "li",
            value:
              "Constructing your materials to explain the logic and evidence that proves each principle, while disproving each counter-principle."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c93": {
    content: [
      {
        type: "p",
        value:
          "The simplest form of pathos to master is using stories and allegories to teach and persuade others."
      },
      {
        type: "p",
        value:
          "A story usually has some basic elements like a protagonist, struggle, and resolution. When using storytelling as a mode of persuasion, it's best to tell a narrative arch with enough details to make your audience feel as if they are part of the story. What's challenging about this skill is being able to recall a story that would be immediately relevant to the situation."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Maintaining a library of stories that grows as you continue to learn new stories."
          },
          {
            type: "li",
            value: "Constantly testing new stories with friendly audiences."
          },
          {
            type: "li",
            value:
              "Telling a complete narrative arc and building drama as you go along."
          },
          {
            type: "li",
            value:
              "Not revealing the conclusion or punchline of a story until the drama reaches its peak."
          },
          {
            type: "li",
            value:
              "Creating a mental image of the scenes rather than just providing a summary of what happens."
          },
          {
            type: "li",
            value: "Expressing joy as you tell the story."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2c95": {
    content: [
      {
        type: "p",
        value:
          "Advanced pathos moves beyond storytelling to master tone and cadence. This skill is about getting closer to spoken word poetry."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Using elements of rhetoric where appropriate. You use anaphora. You use epistrophe. You use asyndeton. If that's not enough, try tricolon or polysyndeton or anadiplosis. Yes, those are all real things. Five of those six elements were used in this very bullet."
          },
          {
            type: "li",
            value: "Speak-ing in rhy-thm."
          },
          {
            type: "li",
            value:
              "Modulating and using pace to create 'texture' – like moments of excitement or catharsis."
          },
          {
            type: "li",
            value:
              "Modulating dynamics TO CREATE HIGH ENERGY MOMENTS, when you pull your audience in."
          },
          {
            type: "li",
            value: "Using dramatic  ...  pauses."
          },
          {
            type: "li",
            value:
              "Can you use hypophora and rhetorical questions to persuade? I bet you can."
          },
          {
            type: "li",
            value:
              "It's like using a hammer to pound nails (i.e., you use analogies to teach)."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Elements of rhetoric that are frequently used by practitioners of this skill."
          }
        ]
      }
    ]
  },
  "5878da5bb6789281bd55e8c1": {
    content: [
      {
        type: "p",
        value:
          "This skill is about conducting research to start a complex problem solving process, not about depth in market research (ie: deep customer suveys, analytical approachs, and focus groups). It speaks to initial research required to create an adaptive problem-solving process."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Having a good understanding of the solution space (e.g., by conducting a google search, expert interview, or reading a relevant book/research paper)."
          },
          {
            type: "li",
            value: "Relying on multiple sources."
          },
          {
            type: "li",
            value: "Seeking alternative points of view."
          },
          {
            type: "li",
            value: "Sharing your synthesized research findings with colleagues."
          }
        ]
      }
    ]
  },
  "5936de32330000160059fe9a": {
    content: [
      {
        type: "p",
        value:
          "This skill is about asking your colleagues for help in solving a problem. That means learning how to effectively frame a problem."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Framing the problem statement (i.e., what are you trying to solve for?)."
          },
          {
            type: "li",
            value:
              "Identifying the constraints and insights that you already know."
          },
          {
            type: "li",
            value:
              "Creating a MECE framework for the solution space that you're exploring. (Often, this frame uses binary trees or 2X2 matrices)."
          }
        ]
      }
    ]
  },
  "5878da5db6789281bd55e94d": {
    content: [
      {
        type: "p",
        value:
          "This skill is about using straw models in order to rapidly capture feedback and insights."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Articulating the problem and your constraints."
          },
          {
            type: "li",
            value:
              "Describing multiple straw models that represent potential answers."
          },
          {
            type: "li",
            value:
              "Gathering insights from experts, colleagues, and clients to refine your answers accordingly."
          },
          {
            type: "li",
            value:
              "Crafting a sequence of interactions that will enable you to continuously refine the straw model."
          },
          {
            type: "li",
            value:
              "Making a decision once the straw model is stable. (This is a form of inductive problem solving and therefore may not lead to collectively exhaustive or root cause solutions)."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e8e9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about using a hypothesis-based approach to collaboratively solve high stake problems."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Articulating the problem and your constraints."
          },
          {
            type: "li",
            value:
              "Working with experts, colleagues, and clients to refine the problem."
          },
          {
            type: "li",
            value:
              "Building hypothesis trees to frame the sub problems that need to be resolved."
          },
          {
            type: "li",
            value:
              "Iterating with stakeholders throughout the process (e.g., narrowing the hypotheses until a decision can be made)."
          }
        ]
      }
    ]
  },
  "5878da5bb6789281bd55e8c3": {
    content: [
      {
        type: "p",
        value:
          "This skill is about problem solving using a deductive, theory based approach."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Conducting research to identify preexisting theories."
          },
          {
            type: "li",
            value:
              "Formulating root-cause theories that start with basic premises and explanations of causality."
          },
          {
            type: "li",
            value:
              "Running natural or controlled experiments to test your theory."
          },
          {
            type: "li",
            value:
              "Refining your theory to drive even deeper predictive power. A fully formed theory should be as close to deductive reasoning as possible (i.e., the conclusion of the theory is logically derived from its premises)."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e8e7": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating consensus among people with dissenting opinions. It's worth remembering here that consensus is not a vote of unanimity. Instead, it is about acquiring agreement from all who are impacted by the decision. This skill is part of the adaptive problem solving process, which includes:  conducting research to solve problems, framing problems, solving problems, and building consensus among people with strong, differing opinions."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Identifying whose consent you will need to achieve the highest tomo impact."
          },
          {
            type: "li",
            value:
              "Understanding the potential gaps to consensus (e.g., different contexts, skills, and motives)."
          },
          {
            type: "li",
            value:
              "Building a sequence of conversations that help resolve gaps to consensus."
          },
          {
            type: "li",
            value:
              "Holding a final 'stack hands' conversation to ensure consensus is complete and transparent."
          },
          {
            type: "li",
            value:
              "Documenting consensus in writing and sharing this with all participants."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00ceb": {
    content: [
      {
        type: "p",
        value:
          "Tactical performance, (i.e., creating 'convergence' in an organization by learning from past experiences), often requires work to be codified into step-by-step processes. This skill is about designing those processes."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Designing processes at the average level of complexity for your organization."
          },
          {
            type: "li",
            value: "Designing processes for multiple people."
          },
          {
            type: "li",
            value:
              "Applying the lean tool kit to ensure your inventory is kept to a minimum."
          },
          {
            type: "li",
            value:
              "Applying the six-sigma tool kit at the level of blackbelt to ensure that unwanted variation is kept at a minimum. (If the process prevents experimentation, it will be stagnant and suboptimal)."
          },
          {
            type: "li",
            value: "Building measurement systems directly into the process."
          },
          {
            type: "li",
            value:
              "Creating systems that allow participants of the process to continuously improve it (e.g., using guardrails instead of absolutes)."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Software used in your organization."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00ced": {
    content: [
      {
        type: "p",
        value:
          "Some organizational processes can be optimized through advanced mathematical and statistical approaches. This skill is about applying complex mathematical modeling to do exactly that"
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building models (e.g., the news vendor or statistical queuing) and monte carlo simulators to optimize processes."
          },
          {
            type: "li",
            value:
              "Collecting and analyzing data used when optimizing processes."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e8ff": {
    content: [
      {
        type: "p",
        value:
          "A product vision is the north star for a product realization team. When built well, the vision becomes a source of tomo (i.e., play and purpose) for engineers, designers, executives, and customers. The goal of the product vision is to create a compelling rationale for why a product should be built and why your colleagues should invest their time into this product."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Clarifying who your customers are and if necessary, creating customer personas."
          },
          {
            type: "li",
            value:
              "Building a theory of impact for your customer. The theory of impact answers the question, 'how and why will this product create impact for a customer?'"
          },
          {
            type: "li",
            value:
              "Developing specific theories of impact for different types of customers, e.g., decision makers, users, administrators."
          },
          {
            type: "li",
            value:
              "Explaining why we believe these theories of impact might be true. A product vision should include some evidence and rationale."
          },
          {
            type: "li",
            value:
              "Explaining why we believe that the product is economically viable. You can evaluate this question with competitors, or with a no-tech test."
          },
          {
            type: "li",
            value: "Creating materials used to teach colleagues this vision."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e909": {
    content: [
      {
        type: "p",
        value:
          "An early step in the product realization process is the conversion of a product vision to a set of components that need to be built. Often this is called a product architecture or blueprint, because it is akin to an architect creating the blueprints or scale models of a building."
      },
      {
        type: "p",
        value:
          "The purpose of a product manager (versus an engineer) clarifying the architecture is not to get the technical details right. Instead, the purpose is to create an independent point of view on a few questions"
      },
      {
        type: "ol",
        value: [
          {
            type: "li",
            value:
              "Which parts of our application should be built as a platform component (e.g., the fastest most efficient approach is to build a generic, repeatable-use component)?"
          },
          {
            type: "li",
            value:
              "How can we minimize what the user has to learn across a large platform (e.g., where possible, the application as a whole are internally consistent)?"
          },
          {
            type: "li",
            value:
              "Where should we take on technical debt versus where should we not (for example, because we would have to re-build the component almost immediately)?"
          },
          {
            type: "li",
            value: "Why are we confident this vision is economically viable?"
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Integrating: Laying out which of your product's components should be broad and multi-purpose (e.g., platform elements, sources of synergy and reuse), and which components should be single-use.  By solving for integrative thought first, you are reducing errors from Conway's law (i.e., product design is heavily influenced by organizational structure), and from coordination neglect (i.e., people's bias to divide and conquer prematurely, neglecting any risks or possible upsides to integrative thought)"
          },
          {
            type: "li",
            value:
              "Disintegrating: Laying out the sub-components of your product in a way that is minimizes overlap and allows your team to divide-and-conquer."
          },
          {
            type: "li",
            value:
              "Creating easily digested materials that ensure that the whole team understands this architecture."
          },
          {
            type: "li",
            value:
              "Developing an iterative roadmap that shows the strategic priority of different components. The roadmap should consider which components produce the highest impact for the least effort."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e901": {
    content: [
      {
        type: "p",
        value:
          "As a product manager, to balance tactical performance (achieving targets) and adaptive performance (pivoting and learning quickly), you will want to build and maintain a transparent, collaborative product development process."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building a process that includes all of the steps from identifying the problem to be solved (i.e., the challenge), sifting through ideas, planning MVPs / experiments, iteratively building, and learning from (i.e., reflecting on) outcomes."
          },
          {
            type: "li",
            value: "Building a process that the whole team engages in."
          },
          {
            type: "li",
            value:
              "Building consensus steps at the critical phases of the process. Using process rigor to ensure consensus and thus eliminate even the possibility of blame."
          },
          {
            type: "li",
            value:
              "Building process steps to create the space for teams to communicate effectively with each other."
          },
          {
            type: "li",
            value:
              "Building a way for the whole team to see the process transparently (e.g., experiment board)."
          },
          {
            type: "li",
            value:
              "Building into the process clear milestones and goals so critical dates are well known to others."
          },
          {
            type: "li",
            value:
              "Ensuring each experiment has a clear timebox to ensure high ROI and to meet team commitments."
          },
          {
            type: "li",
            value:
              "Maintaining the use of the process even when the team is busy or facing urgent situations."
          },
          {
            type: "li",
            value:
              "Teaching all colleagues (i.e., engineers, designers) how to use the process."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e903": {
    content: [
      {
        type: "p",
        value:
          "A well honed, high-tomo product development process should not need a product manager to make 'requirements.' However, on occasion, a user's problem is unusual or sophisticated enough to need some kind of deeper explanation to engage a whole product realization team. The key to this skill is to provide clarity on the recommended solution without providing too much clarity to overly constrain engineers."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "For each component, answering five questions:",
            children: [
              {
                type: "ol",
                value: [
                  {
                    type: "li",
                    value: "Who is the customer?"
                  },
                  {
                    type: "li",
                    value: "What problem are we solving for this customer?"
                  },
                  {
                    type: "li",
                    value: "When do we need to launch?"
                  },
                  {
                    type: "li",
                    value: "What constraints do we have?"
                  },
                  {
                    type: "li",
                    value:
                      "What is our best current idea for how we solve this problem within these constraints, and why?"
                  }
                ]
              }
            ]
          },
          {
            type: "li",
            value:
              "Ensuring that all the stakeholders needed to launch this component are in consensus on these answers."
          },
          {
            type: "li",
            value:
              "Creating visuals, mockups, flowcharts, or Excel models if necessary to explain these answers."
          },
          {
            type: "li",
            value:
              "Creating clarity on the quality / risk levels of the component."
          },
          {
            type: "li",
            value:
              "Using the process to distill the component to its simplest, minimal viable product."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Depending on the type of product, understanding how the product is built at a high enough level."
          }
        ]
      }
    ]
  },
  "5878da5cb6789281bd55e907": {
    content: [
      {
        type: "p",
        value:
          "This skill is about partnering with the engineering and manufacturing teams in your organization in the building of a product. This partnership will encourage you to incorporate both tactical (i.e., the broader team solving for the strategy), and adaptive performance (i.e., allowing the broader team to improve or pivot away from the strategy). "
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Learning the user's and buyer's context (e.g., interviewing the frontline, owning customer facing experiments, and building a customer panel)."
          },
          {
            type: "li",
            value:
              "Using each team meeting (e.g., weekly performance cycles) to share new context and learnings."
          },
          {
            type: "li",
            value: "Understanding the drivers of technical complexity."
          },
          {
            type: "li",
            value:
              "Accurately predicting the time it will take to engineer a solution."
          },
          {
            type: "li",
            value:
              "Teaching engineers the 'why' when you have a firm grasp on the path forward."
          },
          {
            type: "li",
            value:
              "Coaching engineers to co-create a common solution when you don't have a firm grasp on the path forward."
          },
          {
            type: "li",
            value: "Being open to learning from the engineers."
          },
          {
            type: "li",
            value: "Celebrating the engineering team's successes and impact."
          },
          {
            type: "li",
            value:
              "Routinely checking in on the engineering team (especially when components are more complex)."
          },
          {
            type: "li",
            value: "Reviewing early prototypes."
          },
          {
            type: "li",
            value:
              "Providing fast and honest feedback, along with any course corrections. "
          },
          {
            type: "li",
            value:
              "Being easily accessible and approachable by engineers. (Engineers will likely want to pull you in for high-tomo reasons)."
          },
          {
            type: "li",
            value:
              "Asking engineers to play-back complex communications in order to avoid miscommunication."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cf6": {
    content: [
      {
        type: "p",
        value:
          "Most complex enterprise web applications have data storage needs. This skill is about setting up and administering database servers."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Evaluating the needs of a product to determine the optimal data storage strategies, weighing the costs, uptime requirements, risks, developer skills, and other considerations."
          },
          {
            type: "li",
            value:
              "Optimizing data storage performance (e.g., with indices, views, stored procedures)."
          },
          {
            type: "li",
            value:
              "Developing and implementing an uptime strategy (e.g., failovers, backups, RAID storage)."
          },
          {
            type: "li",
            value: "Administering and optimizing database queries."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cf8": {
    content: [
      {
        type: "p",
        value:
          "In order to keep an organization productive and impactful, its tools need to be ready for use with minimal downtime.  This skill is about working with colleagues to ensure their technology remains running in healthy condition."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Diagnosing and fixing system and network issues."
          },
          {
            type: "li",
            value: "Diagnosing and fixing issues with user devices."
          },
          {
            type: "li",
            value: "Managing devices and device policy."
          }
        ]
      }
    ]
  },
  "59aeef483000007c0b16e9af": {
    content: [
      {
        type: "p",
        value:
          "Modern web-based software applications require the designing and engineering of distributed systems. This skill is about applying the evolving range of infrastructure technologies to the needs of a complex web-application."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Keeping abreast of the various technologies used for cloud based platforms and their relative strengths and deficits. "
          },
          {
            type: "li",
            value:
              "Developing a perspective on the product's requirements for performance and scale."
          },
          {
            type: "li",
            value:
              "Developing options for infrastructure given a product's requirements and budget."
          },
          {
            type: "li",
            value: "Implementing a chosen strategy."
          }
        ]
      }
    ]
  },
  "59aeef77300000130016e9b1": {
    content: [
      {
        type: "p",
        value:
          "An enterprise delivering application services will require people who support those services. These services must each be deployed, configured, monitored and maintained. This can be done on server hardware directly or more commonly on cloud services."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Provisioning the computing resources necessary to meet the organizations needs."
          },
          {
            type: "li",
            value:
              "Operations of the machines or virtual machines that host the services."
          },
          {
            type: "li",
            value:
              "Management of each service required (e.g. web servers, application servers, email services, search engines, etc.)."
          },
          {
            type: "li",
            value:
              "Establishing monitoring and maintenance routines to ensure the availability of the services."
          },
          {
            type: "li",
            value: "Implementing and managing virtualization."
          }
        ]
      }
    ]
  },
  "59aeeeca3000003e0016e9ab": {
    content: [
      {
        type: "p",
        value:
          "Managing information security requires addressing the security risk to information wherever it resides and as it is transferred. This skill is about identifying and addressing the risks to data in each part of the system and of each communication channel between those parts."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Assessing and prioritizing risks and threats of complex network infrastructure."
          },
          {
            type: "li",
            value: "Setting security policies and practices."
          },
          {
            type: "li",
            value:
              "Developing a prioritized strategy considering costs and magnitude of risk."
          },
          {
            type: "li",
            value:
              "Selecting and implementing tools and strategies for securing data at rest. This includes use of encryption, access control, and data tracking technology."
          },
          {
            type: "li",
            value:
              "Selecting and implementing tools and strategies for securing data in transit."
          },
          {
            type: "li",
            value:
              "Selecting and implementing firewalls, anti-phishing technologies, and other tools designed to protect from common attack vectors."
          }
        ]
      }
    ]
  },
  "59aeecdf300000200016e99b": {
    content: [
      {
        type: "p",
        value:
          "Some applications require user interfaces that make it simple for non-technical users to get value from our technology. This skill is about building front-end user interfaces for web applications."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building user interfaces (e.g., in HTML, CSS) for web with flexibility for different hardware and software requirements as needed."
          },
          {
            type: "li",
            value:
              "Keeping abreast of the various front-end frameworks and using that knowledge to make strategic decisions on which framework to use for the product's requirements."
          },
          {
            type: "li",
            value:
              "Researching similar software being used by the target user to identify and apply common user interface patterns."
          },
          {
            type: "li",
            value:
              "Building responsive user interfaces that work from wide-screen televisions to mobile phone screens."
          },
          {
            type: "li",
            value:
              "Researching and using existing components, especially when building a minimum viable product."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00cfe": {
    content: [
      {
        type: "p",
        value:
          "In most applications, some form of data security is critical to get right. This skill is about engineering security into complex web applications."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Understanding the security of each communication channel between the different parts of the system."
          },
          {
            type: "li",
            value:
              "Configuring encryption among components and between components and external services. "
          },
          {
            type: "li",
            value: "Implementing high quality encryption in the software."
          },
          {
            type: "li",
            value:
              "Ensuring that user data is appropriately protected, especially confidential information and password."
          }
        ]
      }
    ]
  },
  "59aeedd0300000140016e9a5": {
    content: [
      {
        type: "p",
        value:
          "At the basic most level, engineers will frequently need to take algorithms designed in flow charts and convert them into usable code. This skill is about the basic translation of algorithm requirements into working procedures."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Using technical tools (e.g., a programming language) to create repeatable models and procedures that accomplish tasks."
          },
          {
            type: "li",
            value:
              "Implementing appropriate data structures that best represent the business domain model and allow for storing and managing data in an efficient way."
          },
          {
            type: "li",
            value:
              "Translating algorithms defined as a flow chart or in pseudo code into executable programs."
          }
        ]
      }
    ]
  },
  "59aeec9d300000160016e997": {
    content: [
      {
        type: "p",
        value:
          "For any complex application that needs to be maintained over time, it is critical for software engineers to build modular, independently testable components. This skill is about routinely applying black box and stub-out techniques to structuring your software."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "For the front-end, building browser elements independently that can be tested individually."
          },
          {
            type: "li",
            value:
              "For the back-end, breaking down functionality into discrete components in order to separate concerns into discrete chunks). "
          },
          {
            type: "li",
            value:
              "Writing tests, such as unit tests, to enable refactoring of a system (i.e., seamless replacement of old components with new)."
          }
        ]
      }
    ]
  },
  "59aeec1d300000140016e993": {
    content: [
      {
        type: "p",
        value:
          "Solve a narrowly defined problem -or often subproblem- with software. Apply software engineering fundamentals in algorithms, data structures and code design. Implement solution with appropriate level of complexity given its requirements"
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Design and implementation is elegant and understandable"
          },
          {
            type: "li",
            value: "Solution meets the requirements given and implied"
          },
          {
            type: "li",
            value:
              "Follows best practices, especially with regards to modularity, testability and maintainability "
          },
          {
            type: "li",
            value:
              "Effective application of computer science basics, including but not limited to:",
            children: [
              {
                type: "ol",
                value: [
                  {
                    type: "li",
                    value: "Algorithms"
                  },
                  {
                    type: "li",
                    value:
                      "Data structures (e.g., arrays, linked lists, stacks, queues, trees, hashes, sets)"
                  },
                  {
                    type: "li",
                    value: "Concurrency"
                  },
                  {
                    type: "li",
                    value: "Resource utilization"
                  },
                  {
                    type: "li",
                    value: "Transactions"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "59aeec44300000200016e995": {
    content: [
      {
        type: "p",
        value:
          "Contribute to the engineering of complex software applications with many sub-systems, sub-teams, and codebases."
      },
      {
        type: "h4",
        value: "Design code that deals with complexity arising from:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "A large number of people or sub-teams."
          },
          {
            type: "li",
            value: "A large codebase or multiple codebases."
          },
          {
            type: "li",
            value:
              "A solution built of multiple sub-systems particularly those that are distributed."
          },
          {
            type: "li",
            value:
              "Relying on multiple external services such as data stores or processing engines."
          },
          {
            type: "li",
            value:
              "Complex data processing involving multiple sources and varying formats."
          },
          {
            type: "li",
            value: "Strict requirements for availability or performance."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "The ability to divide and conquer along all aspects of the problem and solution."
          },
          {
            type: "li",
            value:
              "A broad range of knowledge on current tools and techniques to apply."
          },
          {
            type: "li",
            value:
              "Designing a system is understandable and adheres to principles of availability, extensibility, and scalability."
          },
          {
            type: "li",
            value:
              "Producing a system where the team can contribute without fear of design conflict."
          },
          {
            type: "li",
            value:
              "Each aspect of the solution is distinct; it may be addressed and refactored independently."
          },
          {
            type: "li",
            value: "Progress may be made by subsystems independent of others."
          }
        ]
      }
    ]
  },
  "59aeecb63000007c0b16e999": {
    content: [
      {
        type: "p",
        value:
          "Software engineers will frequently have to manage, optimize, and debug large, complex codebases. This skill is about analyzing, internalizing, debugging and troubleshooting complex production systems."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Reviewing product documentation or exploring a large codebase to understand a systems' architecture."
          },
          {
            type: "li",
            value:
              "Ascertaining the design patterns used by analyzing a codebase."
          },
          {
            type: "li",
            value:
              "Developing an understanding of how to contribute to the code."
          },
          {
            type: "li",
            value: "Identifying refactoring opportunities."
          },
          {
            type: "li",
            value:
              "Debugging run-time errors and identifying root cause (e.g., through a systemic process of experimentation)."
          },
          {
            type: "li",
            value:
              "Making use of unit tests and API tests to verify functionality."
          },
          {
            type: "li",
            value:
              "Creating straw model plans to rectify errors or defects that take into account the environment, nature, and risk of the errors/defects and the stage of code delivery."
          },
          {
            type: "li",
            value:
              "Consulting with teammates both proactively and reactively to implement fixes."
          }
        ]
      }
    ]
  },
  "59aeedb3300000200016e9a3": {
    content: [
      {
        type: "p",
        value:
          "Dynamic or scripted programming offers a number of advantages in speed to develop and disadvantages in debugging and maintaining complex software. However, engineers may need to use these paradigms when building complex systems."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Addressing the needs of type correctness without the direct support of a compiler (e.g., improved documentation, variable naming)."
          },
          {
            type: "li",
            value:
              "Understanding when the advantages of using interpreted code outweigh the disadvantages."
          },
          {
            type: "li",
            value: "Building tests and error checking into the code."
          }
        ]
      }
    ]
  },
  "59aeed3d3000003e0016e99f": {
    content: [
      {
        type: "p",
        value:
          "This skill is about designing and building software using a functional programming paradigm."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Organizing the components of the system primarily as immutable types and functions that operate on them."
          },
          {
            type: "li",
            value:
              "Implementing using a language that supports functions as first class entities and immutable data types."
          },
          {
            type: "li",
            value:
              "Understanding the situations where a functional paradigm is most advantageous."
          }
        ]
      }
    ]
  },
  "59aeed72300000140016e9a1": {
    content: [
      {
        type: "p",
        value:
          "This skill is about designing and building software using an imperative programming paradigm."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Structuring the types, procedures and functions into well organized sets that can be managed as discrete modules."
          },
          {
            type: "li",
            value:
              "Implementing using a language that supports the imperative style."
          }
        ]
      }
    ]
  },
  "59aeed1a300000150016e99d": {
    content: [
      {
        type: "p",
        value:
          "Object Oriented Design is a paradigm that facilitates solving real world problems in software by modeling them closely. The engineer creates a representation of the problem in software and is able to address it directly in the code in language that resembles natural language.  This approach has found many benefits and yielded considerable success over the years"
      },
      {
        type: "p",
        value:
          "There are a number of programming languages that support Object Oriented Design. Some are purer than others but that is only important to academics. Languages such as C++, Java, C#, Python, Ruby can be described as primarily object oriented. Some other languages have some support for object oriented design (e.g., JavaScript) or support more than one paradigm (e.g., Scala)"
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Able to code in the language most utilized by the organization."
          },
          {
            type: "li",
            value:
              "Decomposing the components of the system into objects and their classes."
          },
          {
            type: "li",
            value:
              "Identifying the inheritance and containment relationships between components."
          },
          {
            type: "li",
            value:
              "Implementation using an object oriented programming language."
          },
          {
            type: "li",
            value:
              "Applying best practices (e.g. SOLID principles) and design patterns."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00d09": {
    content: [
      {
        type: "p",
        value:
          "Automating API tests. When the API tests are automated using a high level language, they can be run the same way the automated regression tests are run."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "The ability to automate the API tests using a high level language, not a tool such as postman."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00d0b": {
    content: [
      {
        type: "p",
        value:
          "Automating the tests reduces the amount of testing time. For example: Designing and executing Unit Tests, Delivery support and management, Designing and executing Load Tests, Designing and executing Regression Tests, Designing and executing Acceptance Tests, Designing and executing QA Tests, Designing and executing Production Smoke Tests, Designing and executing Integration Tests, Designing and executing System Tests"
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Build a testing framework"
          },
          {
            type: "li",
            value: "Automate the tests without help"
          },
          {
            type: "li",
            value:
              "Ability to decide which tests are labor cost efficient to automate."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00d0d": {
    content: [
      {
        type: "p",
        value:
          "Test planning defines how a software solution is verified.  Execution of the test plans provides the organization with verification that the system quality is satisfactory and identifies defects.  There are a range of test activities that are performed throughout the product development lifecycle.  The precise test activities are specific to an organization but follow a common pattern of defining test plans, test cases and managing their execution."
      },
      {
        type: "h4",
        value: "Test Planning is used for:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Acceptance of new software features."
          },
          {
            type: "li",
            value:
              "Regression tests that confirm the system continues to work as expected."
          },
          {
            type: "li",
            value:
              "Smoke testing to validate basic functionality during the development cycle."
          },
          {
            type: "li",
            value:
              "Verifying that performance targets or other system requirements are met."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Writing comprehensive test plans."
          },
          {
            type: "li",
            value: "Organizing test plans for specific goals."
          },
          {
            type: "li",
            value:
              "Writing and maintaining test cases that verify the system using the style and scope of testing necessary"
          },
          {
            type: "li",
            value:
              "Testing the system as a whole as well as each major sub-component.  Understand the compentization of the system and their individual verification is an advanced aspect of this skill."
          },
          {
            type: "li",
            value:
              "The ability to determine who and what to ask when a spec or requirement is unclear."
          }
        ]
      }
    ]
  },
  "5b9adc76063092ce46b00d0f": {
    content: [
      {
        type: "p",
        value:
          "Test that a feature adheres to its acceptance criteria. Perform the first level analysis of where a defect is occurring, for example: testing object oriented programming, testing data transformation programming,  executing white or black-box style tests, etc."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Understanding the user enough to create a complete set of tests."
          },
          {
            type: "li",
            value:
              "Articulating the reason any bugs are either must be fixed or can wait."
          },
          {
            type: "li",
            value: "Maintaining a structured and useful list of active bugs."
          },
          {
            type: "li",
            value:
              "Scrutinizing the user interface to find details that are incorrect."
          },
          {
            type: "li",
            value: "Cataloging and using APIs to test APIs directly."
          },
          {
            type: "li",
            value: "Finding the most critical edge cases."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cb1": {
    content: [
      {
        type: "p",
        value:
          "This skill is about using customer analysis to gain a deeper understanding of your customers' problems and how you can develop solutions for them. (This skill is useful for attacking a market or creating a new line product)."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Identifying my customers’ core problem, either through anthropological or experimental research."
          },
          {
            type: "li",
            value: "Seeing the emotional side of my customer’s problem."
          },
          {
            type: "li",
            value:
              "Understanding my customer’s problem at a level deeper than my competitors have."
          },
          {
            type: "li",
            value:
              "Building a solution at a price appropriate for the customer, while also providing the returns my organization requires."
          },
          {
            type: "li",
            value: "Designing a strategy that the customer would pay for."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2caf": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating financial analysis methods and models that optimize performance strategies."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building models to test financial assumptions (e.g., margins, asset turns, leverage)."
          },
          {
            type: "li",
            value:
              "Creating discounted cash flow calculations to estimate strategic options."
          },
          {
            type: "li",
            value: "Estimating investment requirements."
          },
          {
            type: "li",
            value:
              "Creating scenarios to estimate the economic impact of downside risks."
          },
          {
            type: "li",
            value:
              "Building in likely ranges for each assumption and justifying them with research (i.e., historical or first principles problem solving)."
          }
        ]
      },
      {
        type: "h4",
        value: "Fluency in this skill typically requires knowledge of:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Financial modeling in Microsoft Excel or a similar platform."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cb5": {
    content: [
      {
        type: "p",
        value:
          "Sometimes strategies rely not only on technical feasibility but also on the evolution of technology. This skill is about skate to where the puck WILL BE rather than where it currently is (i.e., applying a future view of technology when building strategies)."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Monitoring the technology space to learn which technologies might shape the work that you do."
          },
          {
            type: "li",
            value:
              "Building a forecast for the evolution and changing costs of these technologies."
          },
          {
            type: "li",
            value:
              "Thinking about how these technologies can more effectively solve your customer’s core problem."
          },
          {
            type: "li",
            value:
              "Using facts to hypothesize the likely pace of adoption, if these technologies are indeed customer-facing."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cb3": {
    content: [
      {
        type: "p",
        value:
          "This skill is about reconfiguring your operating model to help your organization succeed. That includes understanding the current and future state capabilities of the organization."
      },
      {
        type: "h4",
        value:
          "An organization’s operating system includes the following components:"
      },
      {
        type: "ol",
        value: [
          {
            type: "li",
            value: "Identity"
          },
          {
            type: "li",
            value: "Organizational structure"
          },
          {
            type: "li",
            value: "Talent system"
          },
          {
            type: "li",
            value: "Governance system"
          },
          {
            type: "li",
            value: "Planning system"
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cb9": {
    content: [
      {
        type: "p",
        value:
          "This skill is about creating impact by becoming a multi-business organization."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value:
              "Building a case for why a multi-business organization would create value by articulating a TOI focused on revenue growth."
          },
          {
            type: "li",
            value:
              "Having a process to scan which companies can be brought into the portfolio."
          },
          {
            type: "li",
            value:
              "Calculating revenue synergies with benchmark fact bases (e.g., segment-level cross-scale rates)."
          },
          {
            type: "li",
            value:
              "Calculating realistic cost synergies (in most large acquisitions, expected cost synergies don’t last)."
          },
          {
            type: "li",
            value:
              "Describing the risks (e.g., talent) that would need to be addressed."
          },
          {
            type: "li",
            value: "Conducting detailed due-diligence on possible targets."
          }
        ]
      }
    ]
  },
  "5b08476eb59100ac961e2cb7": {
    content: [
      {
        type: "p",
        value:
          "For any business, it is unwise to have only one strategy. By the time you’re forced to come up with a new strategy, it would have already been too late. This skill, therefore, is about creating a portfolio of strategies."
      },
      {
        type: "h4",
        value: "Fluency in this skill typically includes:"
      },
      {
        type: "ul",
        value: [
          {
            type: "li",
            value: "Building a strategic portfolio."
          },
          {
            type: "li",
            value:
              "Balancing this portfolio with a variety of strategies that range from the near to long term and have low to high levels of risk."
          },
          {
            type: "li",
            value:
              "Using your operating model to ensure that the organization spends an appropriate amount of time against the portfolio."
          },
          {
            type: "li",
            value: "Developing long-term strategies every month."
          }
        ]
      }
    ]
  }
};

export default skillDetailsData;
