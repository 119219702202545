import React from "react";
import ReactDOM from "react-dom";

import { OverviewProvider } from "./contexts/Overview";
import { DetailsProvider } from "./contexts/Details";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./index.scss";

import Overview from "./screens/Overview";
import SkillGroupSummary from "./screens/SkillGroupSummary";

import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root");

ReactDOM.render(
  <OverviewProvider>
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
      <Switch>
        <Route exact path="/" component={Overview} />
        <DetailsProvider>
          <Route exact path="/group" component={SkillGroupSummary} />
        </DetailsProvider>
      </Switch>
    </BrowserRouter>
  </OverviewProvider>,

  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
