import React from "react";

const StarburstButton = () => {
  return (
    <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle strokeOpacity=".5" stroke="#FFF" cx="16" cy="16" r="16" />
        <path
          d="M18.294 9.14a7.224 7.224 0 0 0-2.546-.367l-.163-4.766a12.002 12.002 0 0 1 4.22.61l-1.511 4.522zm-5.272.267l-1.966-4.344a11.912 11.912 0 0 1 4.14-1.036l.316 4.758a7.161 7.161 0 0 0-2.49.622zm7.705 1.12a7.195 7.195 0 0 0-2.211-1.31l1.659-4.47c1.346.499 2.58 1.231 3.67 2.174l-3.118 3.606zm-9.986.509L7.275 7.762a11.965 11.965 0 0 1 3.429-2.532l2.107 4.277a7.233 7.233 0 0 0-2.07 1.529zm11.714 1.7a7.236 7.236 0 0 0-1.552-2.052l3.234-3.504a11.988 11.988 0 0 1 2.572 3.4l-4.254 2.156zm-13.208.67l-4.451-1.71A11.944 11.944 0 0 1 7.01 8.048l3.572 3.16a7.197 7.197 0 0 0-1.336 2.197zm13.962 2.023a7.178 7.178 0 0 0-.651-2.483l4.32-2.016a11.9 11.9 0 0 1 1.085 4.129l-4.754.37zm-19.206.848a11.959 11.959 0 0 1 .66-4.214l4.504 1.564a7.198 7.198 0 0 0-.396 2.542l-4.768.108zm23.24 3.922l-4.466-1.668a7.211 7.211 0 0 0 .447-2.868l4.763-.216a11.95 11.95 0 0 1-.743 4.753zm-22.236.619a11.947 11.947 0 0 1-.99-4.153l4.763-.261c.047.867.247 1.708.593 2.498l-4.366 1.916zm19.795 3.338l-3.497-3.241a7.228 7.228 0 0 0 1.386-2.166l4.41 1.815a11.964 11.964 0 0 1-2.3 3.592zm-17.14.473a11.965 11.965 0 0 1-2.493-3.458l4.302-2.056c.373.78.88 1.482 1.504 2.086l-3.313 3.428zm13.383 2.262l-2.006-4.326a7.228 7.228 0 0 0 2.103-1.48l3.39 3.352a11.982 11.982 0 0 1-3.487 2.454zm-9.48.262a11.937 11.937 0 0 1-3.618-2.257l3.2-3.534a7.22 7.22 0 0 0 2.182 1.36l-1.763 4.431zm4.962.835l-.206-4.763a7.19 7.19 0 0 0 2.504-.566l1.865 4.389a11.895 11.895 0 0 1-4.163.94zm-.524.012H16c-1.395 0-2.764-.238-4.067-.708l1.617-4.486a7.2 7.2 0 0 0 2.45.426h.086l.054 4.767-.136.001z"
          fill="#FFF"
          opacity=".546"
        />
      </g>
    </svg>
  );
};

export default StarburstButton;
