import React from "react";

import SkillRow from "./SkillRow";

import styles from "./index.module.scss";

import { SingleSkillConfigType } from "../../../../helpers/skillGroupConfigGenerators";

type PropsType = {
  skills: Array<SingleSkillConfigType>;
  skillGroupId: string;
};

const SkillList = ({ skills, skillGroupId }: PropsType) => {
  const [hoveredSkillId, setHoveredSkillId] = React.useState<
    string | undefined
  >(undefined);

  return (
    <ul className={styles.List}>
      {skills.map((skill: SingleSkillConfigType) => {
        return (
          <SkillRow
            skill={skill}
            hoveredSkillId={hoveredSkillId}
            setHoveredSkillId={setHoveredSkillId}
            skillGroupId={skillGroupId}
            key={skill.id}
          />
        );
      })}
    </ul>
  );
};

export default SkillList;
