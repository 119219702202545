import React, { ReactNode } from "react";

import { DetailsContext } from "./DetailsConsumer";
import { AnimationTransitionStateType } from "../../types";

const DetailsProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = React.useState();
  const [clickedSkillId, setClickedSkillId] = React.useState<
    string | undefined
  >(undefined);
  const [animateSlide, setAnimateSlide] = React.useState(false);
  const [detailsAnimationState, setDetailsAnimationState] = React.useState<
    AnimationTransitionStateType
  >(undefined);

  const providerState = {
    detailsAnimationState,
    animateSlide,
    setAnimateSlide,
    config,
    setDetailsAnimationState,
    setConfig,
    clickedSkillId,
    setClickedSkillId
  };

  return (
    <DetailsContext.Provider value={providerState}>
      {children}
    </DetailsContext.Provider>
  );
};

export default DetailsProvider;
