import React from "react";
import cx from "classnames";

import styles from "./Honeycomb.module.scss";

type PropsType = {
  className: string;
  sunburstHeight: number;
};

const Honeycomb = ({ className, sunburstHeight }: PropsType) => {
  return (
    <svg
      className={cx(styles.Honeycomb, className)}
      xmlns="http://www.w3.org/2000/svg"
      width={`${sunburstHeight / 1.5}`}
      height={`${sunburstHeight / 1.5}`}
    >
      <defs>
        <pattern
          id="honeycomb"
          width="50"
          height="50"
          data-name="honeycomb"
          patternUnits="userSpaceOnUse"
        >
          <path fill="none" d="M0 0h50v50H0z" />
          <path d="M164.06 5.85H85.94L46.88 75l39.06 69.15h78.12L203.12 75 164.06 5.85z" />
          <path d="M114.06 5.85H35.94L-3.12 75l39.06 69.15h78.12L153.12 75 114.06 5.85z" />
          <path d="M64.06 5.85h-78.12L-53.12 75l39.06 69.15h78.12L103.12 75 64.06 5.85zM25 5.85v138.3" />
          <path d="M14.06 5.85h-78.12L-103.12 75l39.06 69.15h78.12L53.12 75 14.06 5.85z" />
          <path d="M-35.94 5.85h-78.12L-153.12 75l39.06 69.15h78.12L3.12 75-35.94 5.85zM164.06-44.15H85.94L46.88 25l39.06 69.15h78.12L203.12 25l-39.06-69.15zM46.88 25h156.24" />
          <path d="M114.06-44.15H35.94L-3.12 25l39.06 69.15h78.12L153.12 25l-39.06-69.15zM-3.12 25h156.24" />
          <path d="M64.06-44.15h-78.12L-53.12 25l39.06 69.15h78.12L103.12 25 64.06-44.15zM25-44.15v138.3M-53.12 25h156.24" />
          <path d="M14.06-44.15h-78.12L-103.12 25l39.06 69.15h78.12L53.12 25 14.06-44.15zM-103.12 25H53.12" />
          <path d="M-35.94-44.15h-78.12L-153.12 25l39.06 69.15h78.12L3.12 25l-39.06-69.15zM-153.12 25H3.12M164.06-94.15H85.94L46.88-25l39.06 69.15h78.12L203.12-25l-39.06-69.15z" />
          <path d="M114.06-94.15H35.94L-3.12-25l39.06 69.15h78.12L153.12-25l-39.06-69.15z" />
          <path d="M64.06-94.15h-78.12L-53.12-25l39.06 69.15h78.12L103.12-25 64.06-94.15zM25-94.15v138.3" />
          <path d="M14.06-94.15h-78.12L-103.12-25l39.06 69.15h78.12L53.12-25 14.06-94.15z" />
          <path d="M-35.94-94.15h-78.12L-153.12-25l39.06 69.15h78.12L3.12-25l-39.06-69.15z" />
        </pattern>
      </defs>
      <circle fill="url(#honeycomb)" cx="50%" cy="50%" r="50%" />
    </svg>
  );
};

export default Honeycomb;
