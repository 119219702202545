import React from "react";
import cx from "classnames";

import { OverviewConsumer } from "../../../../../contexts/Overview";

import DimensionIcon from "../../../../../components/DimensionIcon";

import { SingleSkillConfigType } from "../../../../../helpers/skillGroupConfigGenerators";
import {
  getHeadHandHeartColor,
  exponentialTechHex
} from "../../../../../helpers/visualizationStyles";

import { DetailsConsumer } from "../../../../../contexts/Details";

import styles from "./index.module.scss";

type PropsType = {
  skill: SingleSkillConfigType;
  hoveredSkillId: string | undefined;
  setHoveredSkillId: (arg0: string | undefined) => void;
  skillGroupId: string;
};

const SkillRow = ({
  hoveredSkillId,
  skill,
  setHoveredSkillId,
  skillGroupId
}: PropsType) => {
  const {
    setAnimateSlide,
    clickedSkillId,
    setClickedSkillId
  } = React.useContext(DetailsConsumer);

  const { setHoveredNode, skillSetType } = React.useContext(OverviewConsumer);

  const timeoutRef = React.useRef<number>();

  const setSkillActiveHandler = (active: boolean) => {
    if (active) {
      setHoveredSkillId(skill.id);
      setHoveredNode({
        id: skill.id,
        color:
          skillSetType === "leap"
            ? getHeadHandHeartColor(skill.domain)
            : exponentialTechHex,
        isSkillBucket: false,
        skillGroupId: skillGroupId,
        nodeTitle: skill.name,
        groupTitle: ""
      });
    } else if (!clickedSkillId) {
      setHoveredSkillId(undefined);
      setHoveredNode(undefined);
    }
  };

  return (
    <li
      className={styles.Wrapper}
      key={skill.id}
      onMouseEnter={() => {
        setSkillActiveHandler(true);
      }}
      onMouseLeave={() => {
        setSkillActiveHandler(false);
      }}
      onClick={() => {
        if (skillSetType === "leap") {
          if (clickedSkillId && clickedSkillId === skill.id) {
            setClickedSkillId(undefined);
            clearTimeout(timeoutRef.current);
          } else {
            setAnimateSlide(true);
            timeoutRef.current = window.setTimeout(
              () => setClickedSkillId(skill.id),
              300
            );
          }
        }
      }}
    >
      <div className={styles.Content}>
        <button
          className={cx(styles.Skill, {
            [styles.SkillDim]:
              (hoveredSkillId && hoveredSkillId !== skill.id) ||
              (clickedSkillId && clickedSkillId !== clickedSkillId)
          })}
        >
          <div className={styles.DetailsWrapper} id={skill.id}>
            <DimensionIcon dimension={skill.domain} />
            <span className={styles.Title}>{skill.name}</span>
          </div>
          <svg
            className={styles.FluencyBlock}
            style={
              hoveredSkillId && hoveredSkillId !== skill.id
                ? skill.dimStyle
                : skill.style
            }
          />
        </button>
      </div>
    </li>
  );
};

export default SkillRow;
