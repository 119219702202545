import React from "react";

import Select from "../../Toggles/Select";
import RadioGroup from "../../Toggles/RadioGroup";

import { OverviewConsumer } from "../../../contexts/Overview";

import styles from "./index.module.scss";
import { FluencyToggleType, DepartmentType } from "../../../types";

const FiltersPanel = () => {
  const { department, fluency, setDepartment, setFluency } = React.useContext(
    OverviewConsumer
  );

  return (
    <div className={styles.Wrapper}>
      <h3 className={styles.Legend}>Filters</h3>
      <RadioGroup<FluencyToggleType>
        label="Skill Fluency"
        name="fluencyLevel"
        options={[
          { value: "all", label: "All" },
          { value: "fluent", label: "Fluent" },
          { value: "learning", label: "Learning" },
          { value: "goal", label: "Goal" }
        ]}
        onChange={setFluency}
        radioValue={fluency}
      />
      <Select<DepartmentType>
        label="Domain Team"
        setValue={setDepartment}
        options={[
          { value: "allCompany", label: "LEAP Team Total" },
          { value: "engineering", label: "Data & Engineering" },
          { value: "product", label: "Product" },
          { value: "strategy", label: "Strategy" },
          { value: "business", label: "Business Development" },
          { value: "operations", label: "Operations" }
        ]}
        inputValue={department}
      />
    </div>
  );
};

export default FiltersPanel;
