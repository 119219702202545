import {
  getIndividualSkillWeight,
  getSkillGroupWeight,
  getSkillGroupAverageWeight,
  getDepartmentFluencyWeight
} from "./weightCalculations";

import {
  SkillStatsType,
  SkillGroupDataType,
  DepartmentType,
  FluencyToggleType,
  SkillDataType
} from "../types";

import {
  skillGroupHoverHex,
  getHeadHandHeartColor,
  getHeadHandHeartFluencyStyle,
  getOpacity,
  exponentialTechHex,
  getExponentialTechStyle
} from "./visualizationStyles";

/*
 * Factory function to create a Sunburst chart config. Takes a skill group weight
 * calculator function and an individual skill weight calculator function
 */
function configFactory(
  groupWeightCalculator: (skillGroup: SkillGroupDataType) => number,
  skillWeightCalculator: (skill: SkillStatsType) => number,
  skillData: SkillDataType
) {
  const skillGroupConfig = skillData.skillGroups.map(skillGroup => {
    //@ts-ignore
    let skillGroupWeight = groupWeightCalculator(skillGroup);

    const groupHoverHex = skillGroupHoverHex;

    return {
      title: skillGroup.groupName,
      id: skillGroup.groupId,
      size: 1,
      weight: skillGroupWeight,
      color:
        skillData.skillSetType === "leap" ? groupHoverHex : exponentialTechHex,
      style: {
        fill: groupHoverHex,
        fillOpacity: getOpacity(skillGroupWeight)
      },
      hoverColor: groupHoverHex,
      children: skillGroup.skills.map(skill => {
        const skillWeight = skillWeightCalculator(skill);

        const skillFluencyStyle =
          skillData.skillSetType === "leap"
            ? getHeadHandHeartFluencyStyle(skillWeight, skill.domain)
            : getExponentialTechStyle(skillWeight);

        return {
          domain: skill.domain,
          title: skill.skillName,
          id: skill.skillId,
          color:
            skillData.skillSetType === "leap"
              ? getHeadHandHeartColor(skill.domain)
              : exponentialTechHex,
          style: skillFluencyStyle,
          weight: skillWeight,

          hoverColor:
            skillData.skillSetType === "leap"
              ? getHeadHandHeartColor(skill.domain)
              : exponentialTechHex,
          size: 1
        };
      })
    };
  });

  return {
    title: "total",
    color: "#ffffff",
    size: 0,
    children: [{ title: "skills", children: skillGroupConfig }]
  };
}

export function generateDepartmentOverview(
  department: DepartmentType,
  skillData: SkillDataType
) {
  // this groupWeightCalculator function is necessary as it is passed into a
  // factory function that requires a specific structure'
  function groupWeightCalculator(skillGroup: SkillGroupDataType) {
    return getSkillGroupWeight(
      getSkillGroupAverageWeight(
        skillGroup.skills,
        skillGroup.skills.length,
        department,
        skillData.employeeCounts[department]
      )
    );
  }

  // this skillWeightCalculator function is necessary as it is passed into a
  // factory function that requires a specific structure'
  function skillWeightCalculator(skill: SkillStatsType) {
    return getIndividualSkillWeight(
      skill[department].learning,
      skill[department].fluent,
      skillData.employeeCounts[department]
    );
  }

  return configFactory(groupWeightCalculator, skillWeightCalculator, skillData);
}

// Generates a config using the department section of data. Can be called
// with "fluent", "learning"
export function generateDepartmentProgressView(
  fluencyLevel: FluencyToggleType,
  department: DepartmentType,
  skillData: SkillDataType
) {
  function groupWeightCalculator(skillGroup: SkillGroupDataType) {
    const foundSkill = skillGroup.skills.find((skill: SkillStatsType) => {
      if (fluencyLevel === "fluent" || fluencyLevel === "learning") {
        if (skill[department][fluencyLevel] > 0) {
          return true;
        }
      } else if (fluencyLevel === "goal" && department === "allCompany") {
        if (skill["allCompany"]["goal"] > 0) {
          return true;
        }
      }
      return false;
    });

    return foundSkill ? 3 : 1;
  }

  // this skillWeightCalculator function is necessary as it is passed into a
  // factory function that requires a specific structure'
  function skillWeightCalculator(skill: SkillStatsType) {
    return getDepartmentFluencyWeight(fluencyLevel, department, skill);
  }

  return configFactory(groupWeightCalculator, skillWeightCalculator, skillData);
}
