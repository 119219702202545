import { SkillStatsType, DepartmentType, FluencyToggleType } from "../types";

function getSkillWeight(
  fluent: number,
  fluencyMultiplier: number,
  learning: number,
  learningMultiplier: number
): number {
  return fluent * fluencyMultiplier + learning * learningMultiplier;
}

export function getIndividualSkillWeight(
  learning: number,
  fluent: number,
  totalEmployees: number
): number {
  const targetMultiplier = totalEmployees * 3;
  const learningMultiplier = 1;
  const fluencyMultiplier = 3;

  const currentFluency = getSkillWeight(
    fluent,
    fluencyMultiplier,
    learning,
    learningMultiplier
  );

  const weightPercentage = (currentFluency / targetMultiplier) * 100;

  if (weightPercentage > 50) {
    return 3;
  } else if (weightPercentage > 0) {
    return 2;
  } else {
    return 1;
  }
}

export function getSkillGroupWeight(averageSkillWeight: number) {
  if (averageSkillWeight <= 1) {
    return 1;
  } else if (averageSkillWeight > 1 && averageSkillWeight <= 2) {
    return 2;
  } else {
    return 3;
  }
}

export function getSkillGroupAverageWeight(
  skills: SkillStatsType[],
  numSkills: number,
  department: DepartmentType,
  employeeCount: number
) {
  let skillWeightTotal = 0;
  skills.forEach(skill => {
    skillWeightTotal += getIndividualSkillWeight(
      skill[department].learning,
      skill[department].fluent,
      employeeCount
    );
  });

  return skillWeightTotal / numSkills;
}

export function getDepartmentFluencyWeight(
  fluencyLevel: FluencyToggleType,
  department: DepartmentType,
  skill: SkillStatsType
) {
  if (fluencyLevel === "fluent" || fluencyLevel === "learning") {
    if (skill[department][fluencyLevel] > 0) {
      return 3;
    }
  } else if (fluencyLevel === "goal" && department === "allCompany") {
    if (skill["allCompany"]["goal"] > 0) {
      return 3;
    }
  }
  return 1;
}
