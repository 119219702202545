import React from "react";
import {
  AnimationTransitionStateType,
  DepartmentType,
  ActiveSkillGroupDataType,
  FluencyToggleType,
  HoveredNodeType,
  SkillDataType
} from "../../types";

type OverviewContextType = {
  skillSetType: "leap" | "exponential";
  leapVersion: number;
  exponentialVersion: number;
  activeSkillGroup?: ActiveSkillGroupDataType;
  overviewAnimationState: AnimationTransitionStateType;
  department: DepartmentType;
  fluency: FluencyToggleType;
  hoveredNode?: HoveredNodeType;
  skillData: SkillDataType | undefined;
  leapVersions: number;
  exponentialVersions: number;
  setLeapVersion: (arg0: number) => void;
  setExponentialVersion: (arg0: number) => void;
  setSkillSetType: (arg0: "leap" | "exponential") => void;
  setActiveSkillGroup: (arg0?: ActiveSkillGroupDataType) => void;
  setOverviewAnimationState: (arg0: AnimationTransitionStateType) => void;
  setDepartment: (arg0: DepartmentType) => void;
  setFluency: (arg0: FluencyToggleType) => void;
  setHoveredNode: (arg0: HoveredNodeType | undefined) => void;
  setSkillData: (arg0: SkillDataType) => void;
  setLeapVersions: (arg0: number) => void;
  setExponentialVersions: (arg0: number) => void;
};

export const OverviewContext = React.createContext<OverviewContextType>({
  skillSetType: "leap",
  leapVersion: 1,
  exponentialVersion: 1,
  activeSkillGroup: undefined,
  overviewAnimationState: undefined,
  department: "allCompany",
  fluency: "all",
  hoveredNode: undefined,
  skillData: undefined,
  leapVersions: 1,
  exponentialVersions: 1,
  setLeapVersion: () => {},
  setExponentialVersion: () => {},
  setSkillSetType: () => {},
  setActiveSkillGroup: () => {},
  setOverviewAnimationState: () => {},
  setDepartment: () => {},
  setFluency: () => {},
  setHoveredNode: () => {},
  setSkillData: () => {},
  setLeapVersions: () => {},
  setExponentialVersions: () => {}
});

export default OverviewContext;
