import React from "react";
import cx from "classnames";

import FiltersPanel from "./FiltersPanel";
import LegendPanel from "./LegendPanel";
import SkillTypePicker from "../SkillTypePicker";
import { OverviewConsumer } from "../../contexts/Overview";

import styles from "./index.module.scss";

type PropsType = {
  className?: string;
};

const Filters = ({ className }: PropsType) => {
  const { overviewAnimationState } = React.useContext(OverviewConsumer);

  return (
    <div className={cx(styles.Wrapper, className)}>
      {overviewAnimationState === "in" && <SkillTypePicker />}
      <div className={styles.Filters}>
        <FiltersPanel />
        <LegendPanel />
      </div>
    </div>
  );
};

export default Filters;
