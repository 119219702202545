import React from "react";

const IconUnchecked = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="7"
      cy="7"
      r="7"
      transform="translate(1 1)"
      stroke="#FFF"
      fill="none"
      fillRule="evenodd"
      strokeOpacity=".7"
    />
  </svg>
);

export default IconUnchecked;
