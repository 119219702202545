import React from "react";
import { Sunburst } from "react-vis";
import { Redirect } from "react-router-dom";
import cx from "classnames";

import styles from "./index.module.scss";

import Brain from "./Brain";
import Honeycomb from "./Honeycomb";
import SkillScoreCard from "./SkillScoreCard";

import { OverviewConsumer } from "../../../contexts/Overview";

import {
  generateDepartmentProgressView,
  generateDepartmentOverview
} from "../../../helpers/overviewConfigGenerators";

import {
  getNeutralStyle,
  getOpacity,
  skillGroupHoverHex
} from "../../../helpers/visualizationStyles";

import { ConfigType } from "../../../types";

type WrapperSizeType = { width: number; height: number };

type PropsType = {
  wrapperSize?: WrapperSizeType;
};

const SunburstWithBrain = ({ wrapperSize }: PropsType) => {
  const {
    overviewAnimationState,
    department,
    fluency,
    setActiveSkillGroup,
    setOverviewAnimationState,
    setHoveredNode,
    hoveredNode,
    skillData,
    skillSetType
  } = React.useContext(OverviewConsumer);

  if (!skillData) {
    return null;
  }

  // Generate main config
  const viewConfig = React.useMemo(() => {
    if (hoveredNode) {
      const config = generateDepartmentOverview(department, skillData);

      return applyHoverStyles(hoveredNode.skillGroupId, config);
    } else {
      if (fluency && fluency !== "all" && department) {
        return generateDepartmentProgressView(fluency, department, skillData);
      }

      if (fluency === "goal") {
        return generateDepartmentProgressView(fluency, "allCompany", skillData);
      }

      return generateDepartmentOverview(department, skillData);
    }
  }, [department, fluency, skillData, skillSetType, hoveredNode]);

  const [redirectToSkillGroup, setRedirectToSkillGroup] = React.useState(false);
  const [freezeHover, setFreezeHover] = React.useState(true);

  const hoverTimeoutRef = React.useRef<number>();
  const clickTimeoutRef = React.useRef<number>();
  const loadTimeoutRef = React.useRef<number>();

  // Prevent hovered skill group panel from showing
  // until the starburst has loaded
  React.useEffect(() => {
    loadTimeoutRef.current = window.setTimeout(
      () => setFreezeHover(false),
      1500
    );
  });

  function applyHoverStyles(hoveredGroupId: string, viewConfig: ConfigType) {
    const clonedData: ConfigType = JSON.parse(JSON.stringify(viewConfig));

    clonedData.children[0].children.forEach(node => {
      if (node.id === hoveredGroupId) {
        node.style = {
          fill:
            skillSetType === "exponential"
              ? skillGroupHoverHex
              : node.hoverColor,
          fillOpacity: getOpacity(node.weight)
        };

        node.children.forEach(subSkill => {
          subSkill.style = {
            fill: subSkill.hoverColor,
            fillOpacity: getOpacity(subSkill.weight)
          };
        });
      } else {
        node.style = getNeutralStyle();

        node.children.forEach(subSkill => {
          subSkill.style = getNeutralStyle();
        });
      }
    });

    return clonedData;
  }

  if (!wrapperSize) return null;

  if (redirectToSkillGroup) {
    return <Redirect push to="/group" />;
  }

  function calculateSunburstHeight(wrapperSize: WrapperSizeType) {
    let sunburstDimension = 0;

    sunburstDimension = wrapperSize.height - 200;

    if (wrapperSize.width < 1400) {
      sunburstDimension = wrapperSize.width - 700;
    }

    return sunburstDimension;
  }

  return (
    <div
      className={cx(styles.Wrapper, {
        [styles.WrapperAnimateOut]: overviewAnimationState === "out",
        [styles.WrapperAnimateIn]: overviewAnimationState === "in"
      })}
    >
      <Sunburst
        className={cx(styles.Sunburst, {
          [styles.SunburstAnimateIn]: overviewAnimationState === "in"
        })}
        style={{ strokeWidth: "2px" }}
        hideRootNode
        data={viewConfig}
        height={calculateSunburstHeight(wrapperSize)}
        width={calculateSunburstHeight(wrapperSize)}
        stroke="#3D244F"
        colorType="literal"
        onValueMouseOver={node => {
          if (!freezeHover) {
            clearTimeout(hoverTimeoutRef.current);
            setActiveSkillGroup({
              skillGroup: node.children ? node : node.parent,
              currentElement: node
            });

            setHoveredNode({
              id: node.id,
              color: node.hoverColor,
              isSkillBucket: !!node.children,
              nodeTitle: node.title,
              skillGroupId: node.children ? node.id : node.parent.data.id,
              groupTitle:
                node.parent.data.title === "skills"
                  ? "Capability"
                  : node.parent.data.title
            });
          }
        }}
        onValueMouseOut={() => {
          if (!freezeHover) {
            setActiveSkillGroup(undefined);
            hoverTimeoutRef.current = window.setTimeout(
              () => setHoveredNode(undefined),
              200
            );
          }
        }}
        onValueClick={node => {
          setFreezeHover(true);
          setHoveredNode(undefined);
          if (node) {
            clickTimeoutRef.current = window.setTimeout(
              () =>
                setActiveSkillGroup({
                  skillGroup: node.children ? node : node.parent.data,
                  currentElement: node
                }),
              3000
            );
            clickTimeoutRef.current = window.setTimeout(() => {
              setRedirectToSkillGroup(true);
            }, 500);
            setOverviewAnimationState("out");
          }
        }}
      />
      <div className={styles.HoverWrapper}>
        <span className={styles.GroupName}>
          {hoveredNode && hoveredNode.groupTitle}
        </span>
        <h2 className={styles.HoveredNodeTitle}>
          {hoveredNode && hoveredNode.nodeTitle}
        </h2>
        {hoveredNode && (
          <SkillScoreCard
            hidden={hoveredNode.isSkillBucket}
            department={department}
            skillId={hoveredNode.id}
          />
        )}
      </div>
      <Brain
        className={cx(styles.Brain, {
          [styles.BrainAnimateIn]: overviewAnimationState === "in"
        })}
        sunburstHeight={calculateSunburstHeight(wrapperSize)}
      />
      <Honeycomb
        className={cx(styles.Honeycomb, {
          [styles.HoneycombAnimateIn]: overviewAnimationState === "in"
        })}
        sunburstHeight={calculateSunburstHeight(wrapperSize)}
      />
    </div>
  );
};

export default SunburstWithBrain;
