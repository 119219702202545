import React from "react";

import IconChecked from "./IconChecked";
import IconUnchecked from "./IconUnchecked";
import Text from "../../Text";
import { SmallSerifHeading } from "../../Headings";

import styles from "./index.module.scss";

export type RadioGroupValueType = { value: string; name: string };

type PropsType<T = string> = {
  label?: string;
  name: string;
  options: { value: T; label: string }[];
  radioValue: T;
  onChange: (val: T) => void;
};

const RadioGroup = <T, _ = undefined>({
  options,
  name,
  radioValue,
  onChange,
  label
}: PropsType<T>) => {
  return (
    <div>
      <SmallSerifHeading className={styles.Label}>{label}</SmallSerifHeading>
      <div role="radiogroup" className={styles.RadioWrapper}>
        {options.map(option => (
          <label className={styles.Radio} key={option.value.toString()}>
            {radioValue === option.value ? <IconChecked /> : <IconUnchecked />}
            <input
              className={styles.RadioInput}
              name={name}
              type="radio"
              value={option.value.toString()}
              aria-checked={radioValue === option.value}
              checked={radioValue === option.value}
              onChange={(e: React.ChangeEvent<any>) => onChange(e.target.value)}
            />
            <Text className={styles.RadioLabel}>{option.label}</Text>
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
