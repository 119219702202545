import React from "react";
import cx from "classnames";

import Breadcrumbs from "../../components/Breadcrumbs";
import SunburstWithBrain from "./SunburstWithBrain";
import Layout from "../../components/Layout";
import Filters from "../../components/Filters";
import DataUpdatedCard from "../../components/DataUpdatedCard";
import ActivatedScorecard from "./ActivatedScorecard";

import { OverviewConsumer } from "../../contexts/Overview";

import styles from "./index.module.scss";

const Overview = () => {
  const timeoutRef = React.useRef<number>();
  const {
    overviewAnimationState,
    setOverviewAnimationState
  } = React.useContext(OverviewConsumer);

  const [clientDimensions, setClientDimensions] = React.useState<{
    width: number;
    height: number;
  }>();

  function updateDimensionValues() {
    setClientDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  React.useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      setOverviewAnimationState("in");
    }, 300);
  }, []);

  React.useEffect(() => {
    updateDimensionValues();

    window.addEventListener("resize", () => {
      updateDimensionValues();
    });

    return function cleanup() {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    <Layout>
      <Breadcrumbs />
      <main className={styles.Overview}>
        <div
          className={cx(styles.SidebarLeft, {
            [styles.SidebarLeftAnimateOut]: overviewAnimationState === "out",
            [styles.SidebarLeftAnimateIn]: overviewAnimationState === "in"
          })}
        >
          <Filters className={styles.FiltersPanel} />
        </div>
        <div
          className={cx(styles.Main, {
            [styles.MainAnimateIn]: overviewAnimationState === "in"
          })}
        >
          <SunburstWithBrain wrapperSize={clientDimensions} />
        </div>
        <div
          className={cx(styles.SidebarRight, {
            [styles.SidebarRightAnimateOut]: overviewAnimationState === "out",
            [styles.SidebarRightAnimateIn]: overviewAnimationState === "in"
          })}
        >
          <DataUpdatedCard />
          <ActivatedScorecard />
        </div>
      </main>
    </Layout>
  );
};

export default Overview;
