import React, { ReactNode } from "react";

import styles from "./index.module.scss";

type PropsType = {
  children: ReactNode;
};

const Layout = ({ children }: PropsType) => (
  <div className={styles.Wrapper}>
    <div className={styles.Content}>{children}</div>
  </div>
);

export default Layout;
