import React from "react";

type PropsType = {
  className?: string;
};

const HandIcon = ({ className = "" }: PropsType) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
  >
    <path fill="#46BFBE" fillRule="evenodd" d="M0 13h14L7 1z" />
  </svg>
);

export default HandIcon;
