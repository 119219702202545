import React from "react";
import cx from "classnames";
import { Redirect } from "react-router-dom";

import { DetailsConsumer } from "../../contexts/Details";

import { OverviewConsumer } from "../../contexts/Overview";

import Chevron from "./Chevron";
import StarburstButton from "./StarburstButton";

import { getSkillName } from "../../helpers/skillDataFetchers";

import styles from "./index.module.scss";

const Breadcrumbs = () => {
  const {
    activeSkillGroup,
    setHoveredNode,
    setOverviewAnimationState,
    setActiveSkillGroup,
    skillData
  } = React.useContext(OverviewConsumer);

  const {
    clickedSkillId,
    setDetailsAnimationState,
    setClickedSkillId,
    config,
    setConfig
  } = React.useContext(DetailsConsumer);

  const [redirectToOverview, setRedirectToOverview] = React.useState(false);

  const timeoutRef = React.useRef<number>();

  if (redirectToOverview || !skillData) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.Wrapper}>
      <button
        onClick={() => {
          if (activeSkillGroup) {
            setOverviewAnimationState(undefined);
            setDetailsAnimationState("out");
            timeoutRef.current = window.setTimeout(() => {
              setRedirectToOverview(true);
              setActiveSkillGroup(undefined);
              setHoveredNode(undefined);
              setClickedSkillId(undefined);
              setConfig(undefined);
            }, 300);
          }
        }}
        className={cx(styles.HomeLink, styles.Button)}
      >
        <span className={styles.StarburstButton}>
          <StarburstButton />
        </span>
        <h1 className={styles.Heading}>Skill NeuroNet</h1>
      </button>
      {config ? (
        <div className={styles.GroupLink}>
          <Chevron />
          <span
            role="link"
            aria-current={config && !clickedSkillId ? true : undefined}
            onClick={() => setClickedSkillId(undefined)}
            className={cx(styles.SkillGroupName, styles.Button, {
              [styles.Active]: config && !clickedSkillId
            })}
          >
            {config.skillGroupName}
          </span>
        </div>
      ) : null}
      {clickedSkillId ? (
        <div className={styles.SkillLink}>
          <Chevron />
          <span
            role="link"
            aria-current={clickedSkillId ? true : undefined}
            className={cx(styles.SkillGroupName, {
              [styles.Active]: clickedSkillId
            })}
          >
            {getSkillName(clickedSkillId, skillData)}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Breadcrumbs;
