import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

import DataPointCard from "../../../../components/DataPointCard";
import { OverviewConsumer } from "../../../../contexts/Overview";

import { getFluencyCount } from "../../../../helpers/skillDataFetchers";

import { DepartmentType } from "../../../../types";

type PropsType = {
  department: DepartmentType;
  skillId?: string;
  hidden: boolean;
};

const SkillScoreCard = ({ department, skillId, hidden }: PropsType) => {
  const { skillData } = React.useContext(OverviewConsumer);
  if (!skillData) {
    return null;
  }

  let goalCount: number = 0;
  let fluentCount: number = 0;
  let learningCount: number = 0;

  if (skillId) {
    goalCount =
      department === "allCompany"
        ? getFluencyCount(skillId, department, "goal", skillData)
        : undefined;
    fluentCount = getFluencyCount(skillId, department, "fluent", skillData);
    learningCount = getFluencyCount(skillId, department, "learning", skillData);
  }

  return (
    <div className={cx(styles.Wrapper, { [styles.Hidden]: hidden })}>
      <DataPointCard small noBorder heading="fluent" data={fluentCount} />
      <DataPointCard small noBorder heading="learning" data={learningCount} />
      {goalCount !== undefined ? (
        <DataPointCard small noBorder heading="goal" data={goalCount} />
      ) : null}
    </div>
  );
};

export default SkillScoreCard;
