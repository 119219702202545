import React from "react";
import cx from "classnames";

import Brain from "./Brain";
import Breadcrumbs from "../../components/Breadcrumbs";
import Filters from "../../components/Filters";
import Layout from "../../components/Layout";
import SkillGroupDetails from "./SkillGroupDetails";
import DataUpdatedCard from "../../components/DataUpdatedCard";

import { DetailsConsumer } from "../../contexts/Details";

import styles from "./index.module.scss";

const SkillGroupSummary = () => {
  const { detailsAnimationState, setDetailsAnimationState } = React.useContext(
    DetailsConsumer
  );

  React.useEffect(() => {
    setDetailsAnimationState("in");
  }, []);

  return (
    <Layout>
      <Breadcrumbs />
      <div className={styles.SkillGroupSummary}>
        <div className={styles.SidebarLeft} />
        <main
          className={cx(styles.Wrapper, {
            [styles.WrapperAnimateOut]: detailsAnimationState === "out",
            [styles.WrapperAnimateIn]: detailsAnimationState === "in"
          })}
        >
          <SkillGroupDetails />
        </main>
        <div
          className={cx(styles.SidebarRight, {
            [styles.SidebarRightAnimateOut]: detailsAnimationState === "out",
            [styles.SidebarRightAnimateIn]: detailsAnimationState === "in"
          })}
        >
          <DataUpdatedCard />
          <Filters />
        </div>
      </div>
      <Brain
        className={cx(styles.Brain, {
          [styles.BrainAnimateIn]: detailsAnimationState === "in"
        })}
      />
    </Layout>
  );
};

export default SkillGroupSummary;
