import React, { useEffect, useRef } from "react";
import cx from "classnames";
import Vivus from "vivus";

import styles from "./Brain.module.scss";
import { OverviewConsumer } from "../../../contexts/Overview";

type PropsType = {
  className?: string;
  sunburstHeight: number;
};

const Brain = ({ className, sunburstHeight }: PropsType) => {
  const { hoveredNode } = React.useContext(OverviewConsumer);

  const BrainGlowColor = {
    backgroundColor: hoveredNode && hoveredNode.color
  };

  const BrainRightRef = useRef<SVGSVGElement>(null);
  const BrainLeftRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (BrainLeftRef && BrainLeftRef.current) {
      BrainLeftRef.current.style.opacity = "1";
    }
    try {
      new Vivus(
        "brain-left",
        {
          duration: 60,
          animTimingFunction: Vivus.EASE,
          start: "inViewport"
        },
        function() {
          if (BrainRightRef && BrainRightRef.current) {
            BrainRightRef.current.style.opacity = "1";
          }
          try {
            new Vivus(
              "brain-right",
              {
                duration: 40,
                animTimingFunction: Vivus.EASE_IN,
                start: "autostart"
              },
              function() {
                // callback for brain-right
              }
            );
          } catch (e) {
            console.error(e);
          }
        }
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div
      className={cx(className, styles.Brain, {
        [styles.BrainHover]: hoveredNode && hoveredNode.color
      })}
    >
      <div className={styles.BrainSVGWrapper}>
        <svg
          className={styles.BrainLeft}
          ref={BrainLeftRef}
          id="brain-left"
          data-name="brain-left"
          viewBox="0 0 210.49 513.4"
          height={`${sunburstHeight / 2}`}
        >
          <path d="M199.51 15.89l6 333 4 138-17 18-24 8-43-9-86-61-19-32v-23l-10-41-10-41 8-54-4-33 5-23 14-40 16-50 18-27 23-34 25-22 48-16 18-6 23 6 5 9z" />
          <path
            d="M388.81 403.14l-5.76-319.55-4.77-8.65c-.12 0-22.14-5.68-22.25-5.66S338.7 75 338.6 75c-.25 0-46.21 15.34-46.44 15.42S268 111.53 267.89 111.6s-22.26 32.57-22.43 32.69-17.39 25.9-17.51 26-15.59 48-15.76 48.19-13.58 38.43-13.7 38.62-4.88 22.1-4.94 22.2 3.65 31.72 3.57 31.87-7.87 51.88-8 52.08 9.28 39.36 9.14 39.51 8.82 37.53 8.67 37.65l.42 1.8-.25 22.11 17.68 30.81 81.91 58.09"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.77 391.81l-5.51-306.1-4.53-8.29c-.24 0-21.28-5.37-21.51-5.34s-16.67 5.49-16.85 5.52c-.52.1-44.43 14.69-44.89 14.85s-23.24 20.13-23.48 20.27-21.53 31.14-21.86 31.38-16.79 24.82-17 25-15.19 46-15.52 46.4-13.15 36.85-13.39 37.23c-.12.19-4.76 21.2-4.88 21.41s3.3 30.42 3.14 30.73-7.74 49.75-8 50.17 8.56 37.72 8.28 38 8.1 35.94 7.81 36.18l.37 1.72-.5 21.22 16.37 29.63L303.5 537"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.74 380.48l-5.27-292.65-4.29-7.94c-.36 0-20.43-5-20.77-5s-16 5.23-16.27 5.28c-.78.14-42.65 14-43.33 14.27-.48.16-22.36 19.2-22.8 19.41s-20.79 29.71-21.3 30.07-16.18 23.72-16.54 24.08c-.54.5-14.78 43.93-15.28 44.59-.37.48-12.73 35.28-13.08 35.85-.18.29-4.65 20.3-4.83 20.61s3 29.14 2.72 29.6c-.35.69-7.62 47.63-8.08 48.25s7.84 36.08 7.43 36.51 7.37 34.34 6.93 34.7l.33 1.65-.75 20.33 15.06 28.43 73.71 52.29"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.71 369.16L380.68 90l-4.05-7.58c-.48 0-19.57-4.75-20-4.68s-15.33 5-15.69 5c-1 .19-40.86 13.39-41.78 13.69-.63.22-21.48 18.27-22.07 18.55s-20.09 28.28-20.75 28.76c-.51.36-15.58 22.62-16.06 23.07-.72.67-14.37 41.92-15 42.8-.49.63-12.3 33.7-12.77 34.46-.25.38-4.53 19.4-4.77 19.82s2.6 27.85 2.29 28.46c-.47.92-7.49 45.51-8.1 46.33-.47.64 7.11 34.44 6.56 35s6.65 32.74 6.06 33.22l.29 1.58-1 19.44 13.75 27.24 69.62 49.38"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.69 357.83L379.9 92.07l-3.82-7.23c-.6.07-18.71-4.43-19.28-4.34s-14.66 4.71-15.12 4.8c-1.29.24-39.07 12.73-40.22 13.11-.79.27-20.6 17.34-21.34 17.69-.91.44-19.31 26.86-20.15 27.46-.64.45-15 21.53-15.57 22.09-.91.83-14 39.89-14.81 41-.61.79-11.87 32.13-12.46 33.08-.3.48-4.41 18.5-4.71 19-.4.68 2.25 26.55 1.86 27.32-.59 1.15-7.36 43.39-8.13 44.42-.58.8 6.4 32.79 5.71 33.51s5.93 31.15 5.19 31.75l.25 1.5-1.3 18.58 12.44 26.05L294 488.34"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.66 346.5l-4.54-252.31-3.58-6.87c-.73.08-17.86-4.12-18.54-4s-14 4.46-14.54 4.57c-1.56.28-37.3 12.07-38.67 12.53-1 .32-19.72 16.4-20.6 16.83-1.1.52-18.58 25.43-19.58 26.14-.77.55-14.37 20.44-15.1 21.11-1.08 1-13.55 37.87-14.56 39.2-.73.94-11.45 30.55-12.16 31.69-.36.57-4.29 17.6-4.65 18.23-.48.83 1.91 25.27 1.43 26.19-.7 1.38-7.23 41.26-8.15 42.5-.7 1 5.67 31.16 4.85 32s5.2 29.55 4.32 30.27l.21 1.43-1.51 17.67 11.12 24.86 61.43 43.57"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M382.64 335.17l-4.3-238.86L375 89.8c-.85.09-17-3.81-17.8-3.69s-13.33 4.2-14 4.32c-1.81.34-35.5 11.43-37.1 12-1.11.37-18.85 15.47-19.88 16-1.28.62-17.83 24-19 24.84-.89.64-13.76 19.35-14.61 20.13-1.26 1.17-13.14 35.85-14.32 37.39-.85 1.11-11 29-11.85 30.31-.42.67-4.18 16.7-4.6 17.44-.55 1 1.56 24 1 25-.82 1.61-7.11 39.14-8.17 40.59-.83 1.11 4.94 29.51 4 30.52s4.47 27.95 3.44 28.79l.17 1.36-1.76 16.77 9.81 23.68 57.33 40.66"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M381.63 323.84l-4.07-225.41-3.1-6.16c-1 .11-16.14-3.49-17-3.36s-12.66 4-13.4 4.09c-2.07.38-33.72 10.77-35.54 11.38-1.28.43-18 14.54-19.15 15.1-1.46.7-17.1 22.57-18.44 23.53-1 .73-13.15 18.25-14.12 19.15-1.44 1.33-12.73 33.82-14.09 35.59-1 1.26-10.59 27.4-11.54 28.92-.48.76-4.06 15.8-4.54 16.64-.63 1.1 1.22 22.7.58 23.92-.94 1.84-7 37-8.2 38.67-.94 1.28 4.23 27.87 3.13 29s3.76 26.35 2.58 27.32l.13 1.28-2 15.89 8.5 22.48 53.24 37.77"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M380.61 312.52l-3.82-212-2.86-5.8c-1.09.11-15.29-3.18-16.31-3s-12 3.69-12.82 3.84c-2.33.44-31.93 10.12-34 10.81-1.43.48-17.09 13.6-18.41 14.24-1.65.79-16.36 21.14-17.87 22.22-1.14.82-12.52 17.17-13.63 18.17-1.62 1.51-12.32 31.81-13.85 33.79-1.09 1.42-10.17 25.84-11.23 27.54-.55.86-3.94 14.91-4.48 15.85-.71 1.24.86 21.4.15 22.78-1.06 2.07-6.85 34.9-8.22 36.75-1.06 1.44 3.5 26.24 2.27 27.53s3 24.75 1.7 25.84l.09 1.21-2.27 15 7.19 21.29 49.14 34.86"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M379.61 301.19L376 102.67l-2.64-5.45c-1.2.13-14.42-2.86-15.56-2.7-1 .14-11.33 3.44-12.24 3.61-2.59.48-30.15 9.46-32.44 10.23-1.59.53-16.21 12.67-17.67 13.37-1.84.88-15.63 19.72-17.31 20.91-1.27.91-11.94 16.07-13.15 17.19-1.8 1.67-11.92 29.78-13.61 32-1.22 1.58-9.74 24.26-10.93 26.15-.6 1-3.82 14-4.42 15.05-.79 1.38.52 20.12-.27 21.66-1.18 2.29-6.73 32.76-8.25 34.83-1.18 1.59 2.78 24.59 1.41 26s2.31 23.15.84 24.36v1.14l-2.52 14.11 5.88 20.1 45.05 31.95"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M379.59 289.86l-3.34-185.07-2.39-5.09c-1.33.14-13.57-2.55-14.82-2.37-1.06.16-10.66 3.18-11.66 3.37-2.85.52-28.37 8.81-30.88 9.65-1.75.58-15.33 11.73-16.95 12.51-2 1-14.89 18.29-16.73 19.6-1.41 1-11.34 15-12.67 16.21-2 1.84-11.51 27.76-13.37 30.18-1.34 1.74-9.32 22.69-10.62 24.77-.67 1-3.71 13.11-4.37 14.26-.87 1.52.17 18.83-.7 20.52-1.29 2.52-6.6 30.64-8.27 32.91-1.3 1.76 2.06 22.95.55 24.53s1.58 21.56 0 22.89v1.06l-2.8 13.21 4.56 18.91 41 29.05"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M379.57 278.54l-3.1-171.63-2.15-4.74c-1.45.16-12.71-2.23-14.08-2-1.15.17-10 2.92-11.09 3.13-3.1.57-26.58 8.15-29.32 9.07-1.91.64-14.45 10.8-16.21 11.65-2.2 1.05-14.15 16.85-16.17 18.29-1.53 1.09-10.73 13.88-12.18 15.22-2.16 2-11.1 25.74-13.13 28.39-1.46 1.89-8.9 21.11-10.32 23.38-.72 1.15-3.58 12.21-4.3 13.46-1 1.66-.18 17.55-1.13 19.39-1.42 2.75-6.47 28.52-8.3 31-1.41 1.91 1.34 21.31-.31 23s.86 20-.9 21.41v1l-3 12.33 3.25 17.73 36.86 26.14"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M379.54 267.21L376.69 109l-1.92-4.38c-1.56.17-11.85-1.92-13.33-1.71-1.25.19-9.32 2.67-10.51 2.89-3.37.63-24.8 7.5-27.77 8.5-2.07.69-13.57 9.87-15.48 10.79-2.38 1.14-13.41 15.42-15.59 17-1.67 1.18-10.13 12.78-11.7 14.24-2.35 2.17-10.69 23.72-12.9 26.58-1.58 2.06-8.46 19.54-10 22-.79 1.25-3.47 11.31-4.25 12.67-1 1.79-.53 16.26-1.55 18.25-1.54 3-6.35 26.4-8.33 29.08-1.53 2.08.62 19.67-1.16 21.54s.13 18.36-1.78 19.93l-.08.92-3.27 11.44 1.93 16.55 32.77 23.24"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M379.52 255.88l-2.61-144.73-1.68-4c-1.69.18-11-1.61-12.59-1.38-1.34.2-8.66 2.41-9.94 2.65-3.62.67-23 6.85-26.21 7.92-2.22.75-12.69 8.94-14.74 9.92-2.57 1.24-12.68 14-15 15.68-1.79 1.27-9.52 11.69-11.21 13.26-2.53 2.34-10.29 21.69-12.66 24.78-1.7 2.21-8 18-9.7 20.61-.84 1.34-3.35 10.42-4.19 11.88-1.1 1.93-.87 15-2 17.11-1.65 3.21-6.22 24.28-8.35 27.17-1.65 2.23-.1 18-2 20s-.64 16.79-2.64 18.47l-.12.85-3.53 10.55.63 15.35 28.67 20.33"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M379.84 244.56l-2.36-131.28-1.48-3.67c-1.81.19-10.14-1.3-11.85-1-1.44.21-8 2.16-9.36 2.41-3.88.72-21.22 6.19-24.65 7.34-2.39.8-11.81 8-14 9.06-2.75 1.32-11.94 12.58-14.45 14.37-1.92 1.37-8.92 10.6-10.73 12.28-2.7 2.51-9.88 19.67-12.42 23-1.82 2.37-7.61 16.39-9.39 19.22-.9 1.44-3.23 9.52-4.13 11.09-1.19 2.07-1.22 13.68-2.41 16-1.77 3.44-6.09 22.15-8.38 25.25-1.76 2.39-.82 16.38-2.88 18.54s-1.31 15.17-3.51 17l-.17.78-3.77 9.67-.69 14.15 24.57 17.43"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M380.48 233.24l-2.13-117.84-1.21-3.31c-1.93.21-9.28-1-11.1-.72-1.54.23-7.32 1.9-8.79 2.17-4.14.77-19.44 5.54-23.09 6.77-2.55.85-10.94 7.07-13.29 8.19-2.93 1.41-11.2 11.15-13.88 13.06-2.05 1.46-8.31 9.5-10.25 11.3-2.88 2.68-9.46 17.65-12.17 21.18-1.95 2.53-7.19 14.82-9.09 17.84-1 1.53-3.11 8.61-4.07 10.29-1.27 2.2-1.57 12.39-2.84 14.84-1.88 3.67-6 20-8.4 23.33-1.88 2.56-1.55 14.75-3.74 17s-2 13.57-4.39 15.5l-.2.7-4 8.78-2 13 20.48 14.52"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M381.11 221.92l-1.88-104.39-1-3c-2.05.22-8.42-.67-10.36-.38-1.63.23-6.65 1.64-8.2 1.93-4.4.81-17.66 4.88-21.54 6.18-2.71.91-10.06 6.14-12.55 7.34-3.12 1.5-10.47 9.71-13.32 11.75-2.17 1.55-7.71 8.41-9.76 10.31-3.06 2.85-9.06 15.63-11.94 19.38-2.06 2.69-6.76 13.24-8.78 16.46-1 1.63-3 7.71-4 9.49-1.35 2.35-1.92 11.1-3.26 13.71-2 3.9-5.84 17.91-8.43 21.42-2 2.71-2.27 13.1-4.6 15.54s-2.76 12-5.26 14l-.25.63-4.27 7.88L248.4 282l16.38 11.62"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M381.74 210.6l-1.64-90.94-.73-2.6c-2.17.24-7.57-.35-9.62 0-1.73.25-6 1.38-7.63 1.69-4.66.86-15.87 4.23-20 5.61-2.87 1-9.18 5.2-11.82 6.47-3.3 1.59-9.73 8.29-12.75 10.44-2.3 1.64-7.1 7.32-9.28 9.33-3.24 3-8.65 13.61-11.69 17.58-2.19 2.85-6.34 11.67-8.47 15.07-1.09 1.73-2.88 6.82-4 8.7-1.42 2.48-2.27 9.82-3.69 12.58-2.13 4.13-5.71 15.78-8.45 19.5-2.12 2.87-3 11.46-5.46 14.05s-3.49 10.37-6.13 12.54l-.29.56-4.53 7-4.56 10.54 12.28 8.71"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M382.38 199.37L381 121.88l-.5-2.25c-2.29.25-6.71 0-8.87.27-1.83.27-5.32 1.14-7.06 1.46a180.47 180.47 0 0 0-18.42 5c-3 1-8.3 4.27-11.09 5.61-3.49 1.67-9 6.86-12.18 9.13-2.43 1.73-6.5 6.22-8.79 8.35-3.42 3.18-8.24 11.59-11.46 15.78-2.31 3-5.91 10.09-8.16 13.68-1.15 1.83-2.77 5.92-3.9 7.91-1.5 2.62-2.62 8.53-4.12 11.44-2.24 4.36-5.58 13.66-8.48 17.58-2.24 3-3.71 9.83-6.31 12.56s-4.21 8.78-7 11.06l-.33.49-4.79 6.11-5.93 9.4 8.19 5.81"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383 188.2l-1.15-64-.27-1.89c-2.41.26-5.85.27-8.13.61-1.92.28-4.65.87-6.48 1.21a144.17 144.17 0 0 0-16.87 4.45 104.67 104.67 0 0 0-10.35 4.75c-3.67 1.76-8.25 5.43-11.61 7.82a104.7 104.7 0 0 0-8.31 7.37c-3.6 3.35-7.83 9.57-11.22 14-2.43 3.16-5.48 8.52-7.85 12.3-1.21 1.92-2.65 5-3.84 7.11-1.58 2.76-3 7.24-4.55 10.31-2.36 4.58-5.45 11.53-8.5 15.66-2.36 3.19-4.43 8.19-7.17 11.06s-4.94 7.18-7.88 9.59l-.37.41-5 5.22-7.25 8.21 4.1 2.91"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.64 177l-.94-52.13c-39.32 4.28-61.58 16.85-84.37 61.15A99.21 99.21 0 0 1 273 217.48l-14.26 11.69"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.66 177.91l-2.5-38.45c-.18 0-.57-11.37-.74-11.36-26.77 1.7-45.81 9.55-62.37 27.56-7.32 8-15.34 19.47-22.44 32.68a114.25 114.25 0 0 1-19.37 26.14c-2 2-4.36 4.47-6.54 6.26l-14.16 12.42"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.67 178.77l-4.27-36.67c-.36 0-.92-10.83-1.27-10.81-26.54 1.64-45.8 9.36-62.36 27.19a179.52 179.52 0 0 0-22.88 32.14 131.61 131.61 0 0 1-20 26.78 92.13 92.13 0 0 1-6.52 6.59l-14.07 13.16"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.69 179.64l-6.05-34.9c-.54 0-1.27-10.29-1.8-10.25-26.31 1.58-45.8 9.16-62.36 26.82a195.54 195.54 0 0 0-23.31 31.58 152.38 152.38 0 0 1-20.66 27.43c-1.73 1.84-4.52 5.32-6.48 6.93l-14 13.89"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.7 180.5l-7.82-33.12c-.72-.06-1.62-9.74-2.33-9.7-26.08 1.52-45.79 9-62.36 26.46a212.76 212.76 0 0 0-23.74 31 177.78 177.78 0 0 1-21.31 28.06c-1.62 1.76-4.59 5.75-6.44 7.27l-13.87 14.63"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.72 181.37L374.11 150c-.89-.07-1.95-9.2-2.85-9.15-25.85 1.46-45.78 8.79-62.35 26.1-6 6.26-17.85 20.29-24.18 30.48a210 210 0 0 1-22 28.7c-1.5 1.67-4.67 6.18-6.41 7.61l-13.72 15.38"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.74 182.23l-11.39-29.57c-1.07-.09-2.3-8.66-3.38-8.6-25.62 1.4-45.77 8.59-62.35 25.74-5.64 5.84-18.54 20.46-24.61 29.92a251.67 251.67 0 0 1-22.61 29.35c-1.39 1.58-4.74 6.6-6.37 7.94l-13.67 16.11"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.75 183.1l-13.16-27.8c-1.25-.1-2.65-8.11-3.9-8.05-25.4 1.34-45.77 8.4-62.36 25.38-5.29 5.42-19.26 20.61-25 29.37A305.4 305.4 0 0 1 256 232c-1.28 1.49-4.81 7-6.34 8.27l-13.56 16.85"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.77 184l-14.94-26c-1.43-.11-3-7.57-4.43-7.49-25.17 1.27-45.76 8.2-62.35 25-4.95 5-20 20.75-25.49 28.82-7.16 10.53-15.6 20.78-23.9 30.62-1.18 1.4-4.88 7.46-6.3 8.62l-13.47 17.53"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.78 184.83l-16.71-24.25c-1.61-.13-3.35-7-5-6.94-24.94 1.21-45.74 8-62.35 24.65-4.59 4.59-20.77 20.88-25.92 28.26-7.46 10.7-16.14 21.11-24.55 31.27-1.07 1.3-5 7.88-6.26 9l-13.37 18.32"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.8 185.69l-18.49-22.47c-1.79-.14-3.7-6.47-5.49-6.39-24.71 1.16-45.73 7.8-62.35 24.28-4.22 4.2-21.55 21-26.35 27.72-7.77 10.87-16.68 21.43-25.2 31.91-1 1.2-5 8.31-6.23 9.29l-13.27 19"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.81 186.56l-20.26-20.7c-2-.16-4.05-5.93-6-5.84-24.48 1.1-45.71 7.61-62.34 23.92-3.87 3.79-22.36 21.12-26.79 27.16-8.08 11-17.22 21.75-25.85 32.56-.87 1.09-5.1 8.73-6.19 9.62l-13.17 19.79"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.83 187.42l-22-18.92c-2.15-.17-4.4-5.38-6.54-5.29-24.26 1-45.7 7.41-62.35 23.56-3.5 3.39-23.18 21.21-27.22 26.61-8.4 11.22-17.76 22.07-26.5 33.19-.78 1-5.17 9.16-6.15 10L220 277.06"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.85 188.29L360 171.14c-2.33-.18-4.75-4.83-7.07-4.73-24 1-45.68 7.19-62.35 23.19-3.12 3-24.91 20.72-27.65 26.06-10.59 9.67-18.29 22.38-27.15 33.83-.68.89-5.25 9.59-6.12 10.3l-13 21.26"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.86 189.15l-25.59-15.37c-2.51-.2-5.1-4.28-7.6-4.18-23.81.91-45.66 7-62.34 22.83-2.75 2.61-25.69 20.84-28.09 25.5-11 9.7-18.83 22.7-27.8 34.48-.59.78-5.32 10-6.08 10.64l-12.88 22"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.88 190l-27.37-13.6c-2.69-.21-5.45-3.73-8.13-3.63-23.58.85-45.64 6.79-62.34 22.47-2.37 2.22-26.47 21-28.52 25-11.48 9.71-19.36 23-28.45 35.12-.5.67-5.39 10.44-6 11L210.25 289"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.89 190.88l-29.14-11.82c-2.87-.23-5.8-3.17-8.66-3.08-23.35.79-45.62 6.58-62.34 22.11-2 1.84-27.24 21.06-29 24.4-11.91 9.72-19.88 23.32-29.09 35.75-.41.57-5.46 10.87-6 11.32L207 293"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.91 191.75L353 181.7c-3.05-.24-6.15-2.62-9.18-2.53-23.14.74-45.6 6.37-62.34 21.75-1.6 1.46-28 21.18-29.4 23.84-12.35 9.75-20.41 23.63-29.74 36.4-.33.45-5.54 11.29-6 11.65L203.78 297"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.92 192.61l-32.69-8.27c-3.23-.25-6.5-2.07-9.71-2-22.91.67-45.57 6.16-62.34 21.38-1.18 1.12-28.8 21.28-29.83 23.28-12.79 9.76-20.94 23.94-30.39 37-.24.34-5.61 11.72-5.94 12l-12.48 25"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M383.94 193.48L349.47 187c-3.41-.27-6.85-1.51-10.24-1.42-22.68.61-45.54 5.95-62.34 21-.8.73-29.57 21.41-30.26 22.75-13.23 9.77-21.47 24.24-31 37.68-.17.22-5.69 12.14-5.91 12.32L197.31 305"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384 194.35l-36.24-4.73c-3.59-.28-7.19-1-10.77-.87-22.46.55-45.51 5.73-62.33 20.65-.41.36-30.36 21.53-30.7 22.19a97.43 97.43 0 0 0-31.68 38.32c-.1.1-5.77 12.58-5.87 12.67L194.07 309"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384 195.21l-38-3a113.61 113.61 0 0 0-73.63 20l-31.13 21.64a94.62 94.62 0 0 0-32.33 39l-18 40.14"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.08 201.6L370 200.3c-.37-.14-23.52-2-23.9-2.05-26.12-2.6-52.75 4.32-73.72 20.06-.49.37-30.54 21.47-30.85 22-13.44 9.35-25.24 24.25-31.94 39.19l-18.15 40"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.2 207.89l-14.1-1.49c-.75-.29-23.14-2.17-23.91-2.24-26.17-2.64-52.84 4.33-73.82 20.13-1 .74-29.94 21.3-30.56 22.43-12.77 8.88-25.18 25.22-31.55 39.41L192 326"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.31 214.19l-14.08-1.69c-1.13-.43-22.76-2.32-23.92-2.44-26.23-2.67-52.92 4.36-73.91 20.22-1.46 1.1-29.35 21.13-30.28 22.82C230 261.51 217 279.29 211 292.73l-18.41 39.75"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.42 220.48l-14.05-1.88c-1.51-.58-22.4-2.48-23.94-2.64-26.28-2.7-53 4.38-74 20.3-1.95 1.48-28.75 21-30 23.22-11.43 7.94-25.08 27.15-30.77 39.85L193.13 339"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.54 226.78l-14-2.08c-1.89-.72-22-2.63-23.95-2.83a104.79 104.79 0 0 0-74.1 20.38C270 244.1 244.3 263 242.74 265.86c-10.76 7.48-25 28.12-30.38 40.07l-18.66 39.49"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.65 233.08l-14-2.29c-2.27-.86-21.64-2.78-24-3a104.57 104.57 0 0 0-74.19 20.46c-2.92 2.23-27.56 20.63-29.43 24-10.08 7-25 29.09-30 40.29l-18.79 39.36"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.76 239.37l-14-2.48c-2.64-1-21.27-2.93-24-3.22a104.36 104.36 0 0 0-74.28 20.55c-3.4 2.6-27 20.46-29.15 24.4-9.41 6.54-24.91 30.06-29.6 40.51l-18.92 39.23"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M384.88 245.67l-14-2.68c-3-1.15-20.89-3.09-24-3.42a104.18 104.18 0 0 0-74.38 20.63c-3.88 3-26.37 20.29-28.86 24.8-8.74 6.07-24.86 31-29.21 40.73l-19.05 39.11"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385 252l-14-2.87c-3.39-1.29-20.51-3.24-24-3.61a104 104 0 0 0-74.47 20.7c-4.36 3.37-25.77 20.13-28.58 25.2-8.07 5.61-24.8 32-28.83 41l-19.17 39"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.1 258.21l-14-3.07c-3.77-1.44-20.14-3.39-24-3.81a103.77 103.77 0 0 0-74.56 20.79c-4.84 3.75-25.18 20-28.3 25.59-7.4 5.14-24.75 33-28.44 41.18l-19.3 38.84"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.22 264.51l-13.94-3.27c-4.15-1.58-19.76-3.53-24-4a103.55 103.55 0 0 0-74.66 20.87c-5.31 4.13-24.58 19.79-28 26-6.72 4.67-24.69 33.93-28 41.4l-19.42 38.71"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.33 270.81l-13.92-3.47c-4.52-1.72-19.38-3.68-24-4.19a103.35 103.35 0 0 0-74.76 21c-5.79 4.51-24 19.62-27.73 26.38-6 4.21-24.64 34.9-27.66 41.62l-19.56 38.53"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.45 277.11l-13.91-3.66c-4.9-1.87-19-3.83-24-4.39a103.12 103.12 0 0 0-74.85 21c-6.27 4.9-23.4 19.45-27.45 26.78-5.38 3.73-24.58 35.86-27.27 41.84l-19.68 38.45"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.56 283.41l-13.88-3.86c-5.29-2-18.64-4-24-4.59a103 103 0 0 0-74.95 21.12c-6.73 5.28-22.79 19.28-27.16 27.17-4.71 3.27-24.53 36.84-26.88 42.06l-19.81 38.33"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.67 289.71l-13.86-4.06c-5.66-2.15-18.26-4.12-24.05-4.78a102.81 102.81 0 0 0-75 21.19c-7.21 5.68-22.2 19.12-26.88 27.58-4 2.8-24.47 37.8-26.49 42.28l-19.94 38.19"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.79 296l-13.85-4.26c-6-2.3-17.89-4.26-24.06-5a102.56 102.56 0 0 0-75.13 21.27c-7.68 6.07-21.61 18.95-26.6 28-3.36 2.34-24.42 38.77-26.1 42.5L200 416.59"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.9 302.31l-13.83-4.45c-6.41-2.45-17.51-4.41-24.07-5.17A102.38 102.38 0 0 0 272.77 314c-8.14 6.46-21 18.78-26.31 28.36-2.69 1.87-24.37 39.74-25.71 42.73l-20.2 37.94"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386 308.61L372.2 304c-6.79-2.59-17.14-4.55-24.08-5.36A102.18 102.18 0 0 0 272.8 320c-8.62 6.85-20.42 18.61-26 28.76-2 1.4-24.31 40.71-25.32 42.95l-20.32 37.8"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.13 314.91l-13.8-4.85a111.15 111.15 0 0 0-24.09-5.56 102.22 102.22 0 0 0-75.41 21.5c-9.09 7.25-19.82 18.44-25.75 29.15-1.35.94-24.26 41.68-24.93 43.17L201.7 436"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.24 321.21l-13.78-5a106 106 0 0 0-24.1-5.75A102.11 102.11 0 0 0 272.85 332c-9.53 7.66-19.22 18.27-25.46 29.55-.68.46-24.2 42.64-24.54 43.39l-20.58 37.54"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.35 327.51l-13.76-5.24a101.45 101.45 0 0 0-124.89 45.67l-44.86 81"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.48 331l-14.57-1.8c-47.18-16.88-98-6.3-121.51 38L207 451.74"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.6 334.79l-15.38 1.64c-47.15-15.77-95.58-14.08-118.12 30.36l-41.92 88"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.73 338.92L370.54 344c-47.13-14.67-93.16-21.87-114.74 22.69l-40.46 91.46"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.86 343.5l-17 8.52c-47.11-13.57-90.73-29.65-111.35 15l-39 94.93"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387 348.5l-17.81 12c-47.09-12.46-88.31-37.44-108 7.38l-37.52 98.4"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.11 353.87l-18.63 15.42c-47.06-11.36-85.88-45.23-104.58-.28l-36 101.87"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.23 359.54l-19.43 18.85c-47.05-10.25-83.46-53-101.2-7.93L232 475.81"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.36 365.43l-20.25 22.29c-47-9.15-81-60.79-97.8-15.59L236.19 481"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.48 371.49l-21.05 25.74c-47-8.05-78.61-68.58-94.42-23.26l-31.65 112.3"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.61 377.68l-21.87 29.18c-47-6.94-76.18-76.36-91-30.91l-30.22 115.77"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.74 384l-22.68 32.6c-47-5.84-73.77-84.15-87.65-38.57l-28.72 119.24"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.86 390.35l-23.49 36.07c-46.93-4.74-71.34-91.93-84.26-46.23l-27.25 122.72"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388 396.8l-24.3 39.51c-46.91-3.64-68.92-99.72-80.88-53.89L257 508.61"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.11 403.3L363 446.25c-46.89-2.53-66.49-107.5-77.49-61.55L261.2 514.37"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.24 409.84l-25.92 46.39C315.45 454.81 298.25 341 288.21 387l-22.84 133.17"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.36 416.42l-26.72 49.83c-46.85-.32-61.65-123.07-70.73-76.86L269.53 526"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.49 423L361 476.31c-46.83.78-59.22-130.86-67.33-84.53l-19.97 140.1"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.61 429.67l-28.34 56.72c-46.81 1.88-56.8-138.64-63.95-92.19l-18.45 143.57"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.74 436.33l-29.16 60.16c-46.78 3-54.37-146.43-60.56-99.85L282 543.69"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.87 443l-30 63.6c-46.74 4.1-51.87-154.2-57.15-107.49l-15.51 150.52"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M389 449.7l-30.78 67.05c-46.74 5.19-49.52-162-53.79-115.16l-14 154"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.45 454l-10.17 21.33c-.26 0-19.9 42-20.28 42-45.58 5.07-49.54-153.76-54-108.51 0 0-.66 6.8-.68 6.84l-4.39 63.15c-.13.31-3 42.74-3.2 43l-3.82 34"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.91 458.35l-9.81 19.75c-.53-.1-19.56 39.9-20.32 39.9-44.41 4.93-49.56-145.53-54.25-101.86 0 .08-.68 6.55-.72 6.63L300.06 483c-.27.63-1.83 40.64-2.31 41.12l-4.36 32"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.37 462.68l-9.46 18.15c-.79-.1-19.2 37.73-20.34 37.86-43.26 4.8-49.58-137.3-54.49-95.21-.08.13-.7 6.3-.75 6.43l-1.11 57.17c-.4.94-.71 38.53-1.43 39.24l-4.89 30"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.82 467l-9.09 16.56c-1.06-.13-18.85 35.61-20.38 35.77-42.09 4.67-49.6-129.06-54.71-88.55-.11.16-.72 6-.8 6.21l.54 54.2c-.54 1.24.42 36.41-.54 37.36l-5.43 28.06"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.28 471.35l-8.74 15c-1.32-.17-18.49 33.47-20.4 33.68-40.93 4.53-49.62-120.83-54.95-81.91-.13.21-.74 5.79-.83 6l2.18 51.21c-.67 1.56 1.55 34.3.34 35.5l-6 26.08"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.74 475.68l-8.38 13.39c-1.58-.21-18.15 31.33-20.44 31.58-39.76 4.41-49.64-112.59-55.17-75.25-.16.25-.76 5.54-.87 5.8l3.81 48.23c-.8 1.87 2.68 32.19 1.24 33.62l-6.5 24.11"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.47 480l-8 11.8c-1.84-.24-17.79 29.21-20.46 29.5-38.6 4.27-49.66-104.37-55.41-68.61-.18.3-.77 5.29-.91 5.6l5.46 45.25c-.93 2.17 3.81 30.07 2.13 31.74l-7 22.14"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M385.93 484.37l-7.68 10.2c-2.1-.27-17.43 27.08-20.49 27.41-37.44 4.15-49.68-96.13-55.64-62-.21.34-.79 5-.94 5.39l7.1 42.27c-1.07 2.48 4.94 28 3 29.87l-7.57 20.16"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.38 488.72l-7.31 8.62c-2.38-.31-17.09 24.94-20.52 25.32-36.28 4-49.71-87.9-55.88-55.3-.24.38-.81 4.79-1 5.18l8.74 39.28c-1.2 2.8 6.07 25.86 3.9 28L306.22 558"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M386.84 493.09l-7 7c-2.64-.34-16.74 22.82-20.55 23.23-35.12 3.88-49.72-79.66-56.11-48.65-.26.42-.83 4.54-1 5L312.59 516c-1.34 3.1 7.19 23.74 4.79 26.12l-8.65 16.21"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.29 497.19l-6.6 5.43c-2.9-.38-16.38 20.69-20.58 21.14-33.95 3.75-44.74-69.84-56.33-42-.21.5-.86 4.28-1.07 4.77l12 33.31c-1.47 3.42 8.32 21.63 5.68 24.25l-9.19 14.24"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M387.75 501.58l-6.25 3.84c-3.16-.41-16 18.56-20.61 19.06-32.79 3.62-45.29-61.71-56.56-35.35-.24.55-.88 4-1.1 4.57l13.66 30.3c-1.61 3.73 9.46 19.52 6.57 22.38l-9.72 12.26"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.2 506l-5.89 2.25c-3.42-.44-15.68 16.43-20.64 17-31.63 3.48-45.84-53.58-56.79-28.7-.26.59-.89 3.79-1.14 4.36l15.31 27.35c-1.75 4 10.58 17.4 7.45 20.5L316.24 559"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M388.66 510.45l-5.54.66c-3.69-.48-15.33 14.29-20.67 14.87-30.46 3.36-46.38-45.46-57-22-.29.63-.91 3.54-1.18 4.15l16.95 24.36c-1.88 4.36 11.71 15.3 8.34 18.64l-10.79 8.3"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M389.11 514.94l-5.18-.93c-3.95-.52-15 12.16-20.69 12.79-29.31 3.2-46.92-37.36-57.24-15.4-.32.68-.93 3.29-1.22 4l18.6 21.38c-2 4.66 12.83 13.18 9.23 16.76l-11.34 6.33"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M389.57 519.5l-4.83-2.5c-4.21-.55-14.62 10-20.72 10.7-28.14 3.09-47.45-29.26-57.49-8.74-.35.71-.95 3-1.25 3.73l20.23 18.4c-2.15 5 14 11.07 10.12 14.89l-11.87 4.35"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M390 524.19l-4.47-4.11c-4.47-.59-14.27 7.9-20.75 8.61-27 3-48-21.19-57.72-2.09-.38.75-1 2.78-1.29 3.52l21.87 15.42c-2.28 5.28 15.1 9 11 13l-12.41 2.38"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M390.48 530.45l-4.11-5.7c-4.75-.62-13.92 5.77-20.79 6.52-25.81 2.83-48.49-13.12-57.95 4.56-.42.79-1 2.53-1.33 3.32l23.52 12.43c-2.42 5.6 16.22 6.85 11.89 11.14l-12.94.41"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M390.93 536.36l-3.75-7.29c-5-.65-13.57 3.64-20.82 4.44-24.65 2.69-49-5.09-58.18 11.21-.46.82-1 2.28-1.37 3.11l25.19 9.45c-2.55 5.91 17.35 4.74 12.79 9.27L331.27 565"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M391.38 542.1l-3.38-8.88c-5.27-.69-13.22 1.51-20.84 2.34-23.5 2.57-49.48 2.92-58.42 17.86a27.65 27.65 0 0 0-1.4 2.91l26.8 6.47c-2.69 6.21 18.48 2.62 13.67 7.39l-14-3.54"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M391.84 548l-3-10.47c-21.95-2.85-72.19 7.15-81 27.47l28.44 3.48c-2.82 6.53 19.61.52 14.56 5.52l-14.56-5.52"
            transform="translate(-182.33 -61.08)"
          />
          <path d="M199.99 15.52l6 333 4 138-17 18-24 8-43-9-86-61-19-32v-23l-10-41-10-41 8-54-4-33 5-23 14-40 16-50 18-27 23-34 25-22 48-16 18-6 23 6 5 9z" />
          <path
            d="M291.36 72.82l-24.12 22.23L245 129l-17.5 27-15.73 49.63L198 245.31l-1 4.5c-.06.24-3.92 18.07-4 18.31 0 .44 3.75 32.3 3.8 32.74L189.44 354l9.65 40.33 9.66 40.35v22.6c0 .38 18.2 31.24 18.28 31.61.23 1.06 82.5 60.17 83 61.14l42.3 11.16L376 555l16.19-17.15"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M294.41 73.05L271.16 95.5l-21.58 33.9-16.89 26.92-15.47 49.25L203.75 245l-1 4.47c-.11.48-3.83 17.67-3.89 18.16-.11.87 3.51 31.6 3.59 32.47l-6.9 52.2 9.3 39.67 9.3 39.69.1 14.93v7.28c.09.76 17.4 30.47 17.57 31.21.45 2.12 79 59.35 80 61.28l41.59 13.32 23.26-4.26 15.38-16.29"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M297.46 73.27L275.09 96l-20.88 33.8-16.34 26.87-15.2 48.89-13.2 39.16-1 4.43c-.17.72-3.75 17.28-3.83 18-.16 1.3 3.26 30.9 3.39 32.21l-6.36 51.29 8.95 39 9 39 .14 14.66c0 .24 0 6.93.08 7.16.13 1.14 16.61 29.7 16.85 30.82.68 3.17 75.5 58.52 77 61.41l40.89 15.48 22.9-2.39L392 540.41"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M300.5 73.49L279 96.39l-20.16 33.81-15.79 26.8-14.93 48.51-12.93 38.88-1 4.39c-.24 1-3.67 16.89-3.78 17.87-.21 1.74 3 30.2 3.18 31.94L207.81 349l8.61 38.36 8.58 38.37.19 14.4c0 .31.06 6.73.1 7 .18 1.52 15.81 28.93 16.13 30.42.91 4.23 72 57.7 74 61.55l40.19 17.64 22.53-.52 13.76-14.57"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M303.55 73l-20.62 23.17-19.45 33.76-15.25 26.78-14.66 48.14-12.66 38.6-1 4.36c-.3 1.2-3.58 16.49-3.73 17.72-.25 2.16 2.77 29.5 3 31.67l-5.27 49.49 8.26 37.69 8.27 37.71.23 14.14c0 .38.08 6.51.13 6.9.22 1.9 15 28.16 15.41 30 1.14 5.28 68.51 56.87 71 61.68l39.49 19.8 22.18 1.39 12.95-13.72"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M306.59 72.32l-19.74 23.36-18.74 33.71-14.69 26.74L239 203.9l-12.39 38.32-1 4.32c-.36 1.45-3.49 16.1-3.67 17.58-.3 2.59 2.53 28.79 2.79 31.4l-4.72 48.58 7.91 37 7.91 37 .28 13.88c.05.46.1 6.31.16 6.77.27 2.28 14.21 27.4 14.7 29.64 1.36 6.33 65 56 68 61.81l38.79 22 21.78 3.22 12.14-12.86"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M309.64 71.6l-18.86 23.59-18 33.67-14.18 26.69L244.47 203l-12.13 38-1 4.28C231 247 228 261 227.77 262.7c-.36 3 2.28 28.1 2.58 31.14l-4.17 47.68 7.56 36.37 7.57 36.39.33 13.61c.05.54.11 6.11.17 6.65.32 2.66 13.43 26.63 14 29.24 1.59 7.39 61.5 55.21 65 62l38.09 24.12 21.41 5.09 11.34-12"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M312.68 70.89l-18 23.82-17.33 33.61L263.78 155l-13.86 47-11.86 37.76-1 4.24c-.48 1.94-3.32 15.32-3.55 17.29-.41 3.46 2 27.39 2.38 30.87l-3.63 46.78 7.22 35.71 7.22 35.72.37 13.35c.06.62.13 5.91.2 6.52.36 3 12.63 25.87 13.27 28.85 1.82 8.44 58 54.39 62 62.09L360 547.46l21 6.95 10.53-11.14"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M315.73 70.18l-17.11 24L282 127.79l-13 26.6-13.6 46.66-11.59 37.48-1 4.21c-.54 2.17-3.23 14.92-3.49 17.13-.46 3.9 1.79 26.69 2.18 30.61l-3.08 45.87 6.86 35 6.87 35.07.43 13.09c.07.69.14 5.7.22 6.39.41 3.42 11.83 25.09 12.56 28.45 2.05 9.5 54.5 53.56 59 62.22l36.68 28.44 20.68 8.83 9.71-10.29"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M318.77 69.46l-16.23 24.27-15.91 33.52-12.48 26.56-13.33 46.29-11.32 37.2-1 4.17c-.59 2.41-3.14 14.53-3.43 17-.51 4.33 1.54 26 2 30.34l-2.53 45 6.52 34.39 6.52 34.41.47 12.82c.08.77.16 5.49.25 6.26.45 3.8 11 24.33 11.84 28.06 2.28 10.56 51 52.74 56 62.36l36 30.6 20.31 10.7 8.9-9.43"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M321.82 68.75l-15.36 24.49-15.2 33.47-11.93 26.52-13.05 45.92-11.07 36.92-1 4.13c-.65 2.66-3 14.14-3.37 16.84-.57 4.76 1.29 25.29 1.77 30.08l-2 44.07 6.17 33.72 6.22 33.75.51 12.56c.09.85.18 5.29.28 6.14.5 4.18 10.24 23.56 11.12 27.66 2.51 11.61 47.51 51.91 53 62.49l35.27 32.77 19.94 12.56 8.1-8.57"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M325 68l-14.48 24.76L296 126.18l-11.38 26.47-12.76 45.55-10.8 36.64-.95 4.1c-.71 2.89-3 13.74-3.31 16.69-.62 5.19 1.05 24.59 1.56 29.81l-1.44 43.16 5.83 33.07 5.83 33.09.56 12.3c.09.92.19 5.08.3 6 .54 4.56 9.44 22.8 10.41 27.27 2.73 12.67 44 51.09 50 62.63l34.57 34.92L384 552.32l7.29-7.72"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M328.23 67.32l-13.6 25-13.78 33.37L290 152.08l-12.5 45.17-10.5 36.36-1 4.06c-.77 3.14-2.87 13.35-3.25 16.54-.67 5.63.8 23.89 1.36 29.55l-.87 42.24 5.47 32.41 5.48 32.43.61 12c.1 1 .21 4.88.33 5.88.59 4.93 8.64 22 9.69 26.87 3 13.73 40.5 50.26 47 62.77l33.87 37.08 19.2 16.3 6.48-6.85"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M331.47 66.6l-12.73 25.18-13.07 33.33-10.28 26.39-12.25 44.8-10.26 36.08-.94 4c-.84 3.38-2.79 12.95-3.2 16.4-.72 6.06.56 23.19 1.16 29.28l-.35 41.36 5.13 31.74 5.13 31.84.66 11.77c.11 1.08.23 4.68.35 5.75.63 5.32 7.85 21.26 9 26.48 3.19 14.78 37 49.43 44 62.9L367 533.1l18.83 18.17 5.67-6"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M334.71 65.89L322.87 91.3l-12.37 33.27-9.72 26.35-12 44.43-10 35.8-.95 4c-.89 3.62-2.7 12.56-3.13 16.25-.77 6.5.31 22.49.95 29l.2 40.45 4.79 31.09 4.78 31.11.7 11.51c.12 1.15.24 4.46.38 5.62.68 5.69 7.05 20.49 8.26 26.08 3.41 15.84 33.5 48.61 41 63l32.47 41.4 18.46 20 4.86-5.15"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M338 65.18l-11 25.63L315.33 124l-9.17 26.3-11.72 44.1-9.72 35.51-.94 4c-.95 3.86-2.61 12.16-3.08 16.1-.82 6.92.06 21.79.75 28.75l.75 39.55 4.43 30.42 4.44 30.46.75 11.24c.12 1.23.26 4.26.4 5.49.73 6.08 6.26 19.73 7.54 25.69 3.65 16.89 30 47.78 38 63.18l31.76 43.56 18.1 21.91 4-4.28"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M341.21 64.46l-10.09 25.86-10.94 33.18-8.62 26.26-11.45 43.69-9.46 35.23-.94 3.92c-1 4.1-2.53 11.77-3 16-.87 7.36-.18 21.09.55 28.49l1.3 38.65 4.08 29.76 4.09 29.79.8 11c.13 1.31.27 4.06.42 5.37.77 6.45 5.46 19 6.83 25.29 3.87 17.95 26.5 47 35 63.31l31.07 45.73 17.72 23.78 3.24-3.43"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M344.48 63.75l-9.22 26.08L325 123l-8 26.18-11.23 43.32-9.19 34.95-.93 3.88a155.4 155.4 0 0 0-3 15.81c-.93 7.79-.43 20.38.35 28.22l1.84 37.74 3.73 29.1 3.74 29.14.85 10.71c.14 1.39.29 3.86.45 5.24.81 6.84 4.66 18.19 6.11 24.9 4.1 19 23 46.13 32 63.45l30.36 47.88 17.35 25.66 2.43-2.58"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M347.75 63l-8.34 26.32-9.53 33.08-7.51 26.17-10.92 43-8.92 34.67-.93 3.84a147.18 147.18 0 0 0-2.91 15.66c-1 8.23-.67 19.69.15 28l2.39 36.84 3.38 28.42 3.4 28.47.89 10.46c.15 1.46.3 3.65.48 5.11.85 7.21 3.86 17.42 5.39 24.5 4.32 20.06 19.51 45.31 29 63.59l29.65 50 17 27.52 1.62-1.71"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M351 62.32l-7.46 26.54-8.82 33-7 26.12-10.58 42.62-8.66 34.4-.93 3.81a136.38 136.38 0 0 0-2.84 15.51 135.57 135.57 0 0 0-.06 27.69l2.93 35.94 3.05 27.77 3 27.82.94 10.19c.15 1.54.32 3.44.5 5 .9 7.6 3.07 16.66 4.67 24.11 4.56 21.12 16 44.48 26 63.72l29 52.21 16.61 29.39.81-.86"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M354.32 61.6l-40.8 165.93a126.81 126.81 0 0 0-3 42.79l9.86 99.23A254.93 254.93 0 0 0 347.82 462l44.5 85.62"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M351.62 62.39l-39.7 164.5c-3.27 13.32-2.91 28.67-3.11 42.38 0 1.51 9.54 97.48 9.89 98.94 1.49 6.34 1.72 12.91 2.89 19.3a271.81 271.81 0 0 0 25.14 72.69L391 545.68"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M349.23 63.18l-38.59 163.07c-3.11 12.65-3 28.95-3.18 42 0 3 9.21 95.72 9.91 98.64 1.52 6.31 1.91 12.83 3.12 19.19 4.36 22.94 14.65 51.57 25.45 72.36l44 85.33"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M346.83 64l-37.47 161.61c-2.95 12-3.06 29.23-3.24 41.58-.07 4.53 8.85 94 9.93 98.34 1.55 6.27 2.09 12.74 3.33 19.07 4.27 21.72 15.55 52.35 25.78 72l43.77 85.18"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M344.44 64.76L308.08 225c-2.79 11.32-3.14 29.51-3.31 41.17-.09 6 8.49 92.22 10 98 1.58 6.25 2.27 12.67 3.54 19 4.16 20.48 16.44 53.11 26.11 71.71l43.53 85"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M342.05 65.55l-35.26 158.78c-2.62 10.66-3.21 29.8-3.37 40.77-.11 7.55 8.11 90.47 10 97.74 1.61 6.21 2.44 12.59 3.76 18.86 4 19.25 17.33 53.88 26.42 71.38L386.88 538"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M339.66 66.34l-34.15 157.35c-2.46 10-3.29 30.08-3.44 40.36-.13 9.07 7.71 88.74 10 97.45 1.64 6.18 2.62 12.5 4 18.74 3.9 18 18.22 54.65 26.75 71.06l43.04 84.7"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M337.27 67.13l-33 155.92c-2.29 9.32-3.37 30.36-3.51 40-.15 10.58 7.29 87 10 97.15 1.67 6.15 2.8 12.42 4.19 18.63 3.74 16.8 19.11 55.42 27.07 70.73l42.8 84.59"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M334.88 67.92L303 222.41c-2.13 8.66-3.44 30.64-3.57 39.56-.18 12.08 6.85 85.27 10.07 96.85 1.7 6.12 3 12.33 4.4 18.52 3.57 15.57 20 56.18 27.4 70.4l42.55 84.44"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M332.49 68.71l-30.83 153.06c-2 8-3.51 30.93-3.63 39.15-.2 13.6 6.4 83.55 10.1 96.55 1.73 6.09 3.16 12.26 4.61 18.42 3.39 14.35 20.9 56.95 27.72 70.07l42.32 84.29"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M330.1 69.5l-29.72 151.63c-1.8 7.33-3.59 31.21-3.7 38.75-.22 15.11 5.93 81.83 10.13 96.25 1.76 6.06 3.34 12.17 4.83 18.3 3.19 13.14 21.79 57.72 28 69.75l42.07 84.14"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M327.71 70.29l-28.61 150.2c-1.64 6.66-3.67 31.49-3.77 38.35-.24 16.61 5.44 80.11 10.16 96 1.79 6 3.52 12.08 5 18.19 3 11.93 22.68 58.48 28.37 69.42l41.83 84"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M325.32 71.08l-27.5 148.77c-1.47 6-3.74 31.77-3.83 37.94-.27 18.13 4.93 78.41 10.18 95.66 1.83 6 3.7 12 5.26 18.08 2.75 10.71 23.57 59.25 28.69 69.1l41.58 83.84"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M322.93 71.86l-26.39 147.35c-1.31 5.33-3.82 32-3.9 37.54-.29 19.64 4.41 76.71 10.21 95.36 1.86 6 3.87 11.9 5.47 18 2.51 9.51 24.47 60 29 68.78l41.34 83.69"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M320.54 72.65l-25.28 145.92c-1.15 4.66-3.9 32.34-4 37.14-.3 21.14 3.87 75 10.24 95 1.89 6 4.05 11.82 5.69 17.86 2.25 8.31 25.35 60.79 29.33 68.45l41.11 83.54"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M318.15 73.44L294 217.93c-1 4-4 32.62-4 36.73-.33 22.66 3.3 73.33 10.26 94.76 1.92 5.93 4.23 11.73 5.9 17.75 2 7.1 26.25 61.55 29.66 68.12l40.86 83.39"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M315.76 74.23L292.7 217.29c-.82 3.33-4.05 32.9-4.1 36.33-.35 24.17 2.73 71.65 10.29 94.46 2 5.9 4.41 11.64 6.12 17.63s27.14 62.33 30 67.8l40.62 83.24"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M313.37 75l-21.95 141.65c-.65 2.66-4.12 33.18-4.16 35.93-.37 25.68 2.13 70 10.32 94.16 2 5.87 4.58 11.55 6.33 17.52 1.38 4.72 28 63.09 30.3 67.47l40.38 83.09"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M311 75.81L290.14 216c-.49 2-4.19 33.46-4.22 35.52-.4 27.19 1.51 68.31 10.34 93.87 2 5.85 4.77 11.45 6.55 17.41 1.05 3.53 28.92 63.86 30.63 67.14l40.13 82.95"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M308.6 76.6l-19.73 138.77c-.33 1.33-4.27 33.75-4.29 35.12-.42 28.7.88 66.65 10.37 93.56 2.05 5.83 4.94 11.37 6.76 17.31.72 2.35 29.82 64.62 31 66.81L372.55 511"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M306.23 77.39L287.6 214.73c-.16.67-4.34 34-4.35 34.71-.44 30.22.23 65 10.39 93.27 2.09 5.81 5.12 11.27 7 17.19.37 1.18 30.71 65.4 31.28 66.49L371.54 509"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M304.59 78.18L282.66 248.4a197 197 0 0 0 17.61 110l71 148.66"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M299 85.39l-19.63 169.26c-4.35 37.33 1.19 75.56 18.27 109.1.36.71 24.9 51.81 25.39 52.44l3.39 6.94c.56.72 28.95 59.47 29.67 60l13.55 27.11"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M293.4 92.6l-17.35 168.29c-3.86 37.05 1.93 74.9 18.91 108.17.72 1.4 24.51 50.64 25.49 51.9l3.42 6.85c1.12 1.44 28.77 57.94 30.2 59.07L368 513.44"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M287.79 99.8l-15.06 167.34c-3.36 36.76 2.69 74.22 19.57 107.22 1.08 2.11 24.11 49.48 25.57 51.36l3.46 6.77c1.68 2.16 28.59 56.4 30.74 58.11l14.23 26"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M282.19 107l-12.76 166.38c-2.87 36.48 3.43 73.56 20.22 106.28 1.45 2.81 23.72 48.32 25.67 50.83l3.49 6.68c2.24 2.88 28.41 54.87 31.27 57.14l14.58 25.47"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M276.61 114.22l-10.46 165.41c-2.38 36.2 4.18 72.88 20.87 105.34 1.8 3.5 23.31 47.15 25.75 50.28l3.53 6.6c2.8 3.6 28.23 53.34 31.8 56.17l14.9 24.92"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M271.05 121.43l-8.18 164.44c-1.87 35.92 4.93 72.22 21.53 104.4 2.17 4.21 22.92 46 25.84 49.75l3.56 6.51c3.37 4.33 28.05 51.81 32.34 55.21l15.26 24.37"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M265.5 128.64l-5.88 163.47c-1.38 35.64 5.68 71.55 22.18 103.46 2.53 4.91 22.51 44.83 25.93 49.22l3.59 6.42c3.93 5.05 27.87 50.27 32.88 54.24l15.6 23.83"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M260 135.85l-3.59 162.51c-.89 35.35 6.43 70.88 22.83 102.52 2.91 5.6 22.12 43.66 26 48.67l3.63 6.34c4.49 5.77 27.69 48.74 33.41 53.28l15.94 23.27"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M254.46 143.05l-1.29 161.55c-.4 35.08 7.17 70.21 23.48 101.58 3.27 6.3 21.72 42.5 26.11 48.14l3.66 6.25c5.05 6.49 27.5 47.21 33.95 52.31l16.28 22.73"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M249.47 150.26l1 160.59c.1 34.79 7.93 69.54 24.14 100.63 3.64 7 21.32 41.34 26.2 47.6l3.69 6.17c5.61 7.21 27.33 45.68 34.48 51.35l16.62 22.17"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M245.14 157.47l3.29 159.62c.6 34.51 8.67 68.87 24.79 99.7 4 7.68 20.92 40.17 26.29 47.06l3.73 6.08c6.17 7.93 27.14 44.14 35 50.38l17 21.63"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M240.8 164.68l5.59 158.66c1.09 34.22 9.42 68.19 25.44 98.75 4.4 8.38 20.53 39 26.38 46.53l3.76 6c6.73 8.64 27 42.6 35.55 49.41l17.31 21.08"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M236.47 171.89l7.88 157.69a233.7 233.7 0 0 0 26.09 97.81c4.77 9.08 20.13 37.84 26.47 46l3.8 5.92c7.29 9.37 26.78 41.07 36.08 48.44l17.65 20.53"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M232.13 179.1l10.18 156.73a239.35 239.35 0 0 0 26.74 96.87c5.16 9.76 19.73 36.67 26.56 45.45l3.83 5.83c7.86 10.09 26.6 39.53 36.62 47.48l18 20"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M227.8 186.3l12.47 155.77A245.54 245.54 0 0 0 267.66 438c5.54 10.46 19.34 35.51 26.65 44.91l3.87 5.75c8.41 10.81 26.41 38 37.15 46.51l18.33 19.44"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M223.46 193.51l14.77 154.81a252.39 252.39 0 0 0 28 95c5.91 11.15 18.93 34.35 26.73 44.38l3.9 5.66c9 11.53 26.24 36.47 37.69 45.54l18.67 18.89"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M219.13 200.72l17.06 153.84a259.75 259.75 0 0 0 28.7 94.05c6.3 11.83 18.54 33.18 26.83 43.84l3.93 5.57c9.53 12.25 26.05 34.94 38.22 44.58l19 18.34"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M214.79 207.93l19.36 152.88a268.15 268.15 0 0 0 29.35 93.1c6.69 12.52 18.14 32 26.92 43.3l4 5.49c10.1 13 25.87 33.4 38.76 43.61l19.35 17.8"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M210.46 215.14l21.65 151.91a277.26 277.26 0 0 0 30 92.16c7.08 13.21 17.74 30.86 27 42.77l4 5.4c10.66 13.69 25.69 31.87 39.29 42.65l19.69 17.24"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M206.12 222.35l23.95 151a288.44 288.44 0 0 0 30.65 91.22 319.36 319.36 0 0 0 27.1 42.22l4 5.32c11.22 14.42 25.51 30.34 39.83 41.68l20 16.7"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M201.79 229.55l26.24 150a298.7 298.7 0 0 0 58.49 132l4.07 5.23A240 240 0 0 0 331 557.46l20.37 16.14"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M201.2 234.14l27 145.27c8 45.82 29.06 91.3 57.63 128l4 5.09a215.63 215.63 0 0 0 42 40.84c.62.1 20.67 15.47 21.32 15.36"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M200.6 238.8l27.75 140.56c7.65 43.53 29.65 89.19 56.77 124.06l3.85 4.94a197.4 197.4 0 0 0 43.73 41c1.24.2 21 14.81 22.25 14.59"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M200 243.43l28.51 135.84c7.21 41.24 30.19 87.07 55.9 120.11l3.74 4.79a183.28 183.28 0 0 0 45.41 41.08c1.87.3 21.27 14.14 23.2 13.81"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M199.42 248.05l29.26 131.13c6.81 38.95 30.75 84.95 55 116.15l3.64 4.65a172 172 0 0 0 47.09 41.2c2.49.4 21.57 13.47 24.14 13"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M198.83 252.67l30 126.42c6.41 36.66 31.32 82.82 54.17 112.19l3.54 4.51c13.59 17.13 28.88 34.48 48.77 41.32 3 1 21.87 12.8 25.08 12.24"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M198.24 257.29L229 379c6 34.36 31.88 80.7 53.3 108.23l3.43 4.37c14 17.53 30.05 34.76 50.46 41.44 3.6 1.18 22.17 12.12 26 11.46"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M197.65 261.91l31.52 117c5.61 32.08 32.45 78.59 52.44 104.28l3.32 4.22c14.32 17.93 31.21 35 52.14 41.57 4.23 1.32 22.47 11.45 27 10.68"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M197.06 266.52l32.27 112.29c5.21 29.78 33 76.46 51.58 100.32l3.21 4.08c14.69 18.33 32.36 35.3 53.83 41.68 4.85 1.45 22.77 10.78 27.9 9.9"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M196.46 271.14l33 107.57c4.81 27.49 33.57 74.34 50.71 96.37l3.1 3.93c15.05 18.73 33.5 35.57 55.51 41.81 5.48 1.55 23.07 10.1 28.85 9.11"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M195.87 275.75l33.79 102.86c4.41 25.2 34.14 72.22 49.85 92.41l3 3.79c15.41 19.13 34.63 35.84 57.2 41.93 6.1 1.64 23.37 9.43 29.79 8.33"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M195.28 280.36l34.54 98.14c4 22.91 34.7 70.1 49 88.46l2.89 3.64c15.77 19.53 35.74 36.1 58.87 42 6.74 1.73 23.67 8.77 30.73 7.55"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M194.69 285L230 378.39c3.6 20.62 35.26 68 48.12 84.5l2.78 3.5c16.13 19.93 36.85 36.37 60.56 42.18 7.37 1.8 24 8.09 31.67 6.76"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M194.1 289.56l36.05 88.72c3.21 18.33 35.83 65.86 47.26 80.54l2.67 3.36c16.5 20.33 37.95 36.62 62.24 42.29 8 1.87 24.27 7.43 32.62 6"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M193.51 294.15l36.8 84c2.81 16 36.4 63.74 46.39 76.59l2.57 3.21c16.86 20.72 39 36.87 63.93 42.41 8.64 1.93 24.56 6.75 33.55 5.21"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M192.92 298.73L230.48 378c2.4 13.74 37 61.62 45.52 72.63l2.46 3.07c17.23 21.12 40.13 37.13 65.61 42.53 9.28 2 24.87 6.08 34.5 4.43"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M192.32 303.3l38.32 74.59c2 11.45 37.52 59.5 44.66 68.67l2.35 2.93c17.59 21.52 41.2 37.38 67.3 42.65 9.91 2 25.16 5.41 35.44 3.65"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M191.73 307.86l39.07 69.87c1.61 9.16 38.09 57.38 43.8 64.72l2.25 2.78c17.95 21.92 42.26 37.63 69 42.78 10.56 2 25.47 4.73 36.38 2.86"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M191.14 312.39L231 377.55c1.2 6.87 38.64 55.26 42.93 60.76L276 441c18.31 22.32 43.33 37.88 70.66 42.9 11.19 2 25.76 4.06 37.32 2.08"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M190.55 316.89l40.58 60.45c.8 4.58 39.21 53.14 42.07 56.81l2 2.49c18.68 22.72 44.38 38.13 72.34 43 11.84 2.07 26.07 3.4 38.27 1.3"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M190 321.36l41.33 55.73c.4 2.29 39.78 51 41.21 52.86l1.92 2.34a122.25 122.25 0 0 0 74 43.14c12.47 2.08 26.36 2.73 39.2.52"
            transform="translate(-182.33 -61.08)"
          />
          <path
            d="M189.37 325.78l84.24 102.12a123.15 123.15 0 0 0 115.86 43"
            transform="translate(-182.33 -61.08)"
          />
        </svg>
        <svg
          className={styles.BrainRight}
          id="brain-right"
          data-name="brain-right"
          ref={BrainRightRef}
          viewBox="0 0 215.93 516.06"
          height={`${sunburstHeight / 2}`}
        >
          <defs />
          <path d="M.57 16.48L.5 272.67l4.81 216.82 11.99 6 15 11.01 15 .01 15.99 9.01 73.01-20.96 20.01-21.99 29.02-24.98 12.02-43.99 15.02-26 .01-14 3.02-44.99.03-49-5.97-49.01-6.99-20-7.98-30.01-6-5-19.96-63.01-32.97-57.02-16.99-15.01L89.58 8.53l-35-8.02-25-.01L.57 16.48z" />
          <path
            d="M211.31 565.14l-11.43-5.91-4.74-209.7c-.07-1.36.26-246.68.82-247.89.08-.15 27.78-15.53 27.87-15.68s23.93-.21 24-.32 33.53 7.36 33.63 7.16 27.84 20.66 28 20.52 16.33 14.11 16.41 14c.23-.26 32.05 54.09 32.21 54.39l20.23 62.21 5.71 4.77"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M215.8 564.14l-10.88-5.83-4.67-202.56c-.14-2.72.46-237.18 1.58-239.6.14-.31 26.55-15.08 26.71-15.38s22.87-.44 23-.67 32.06 6.63 32.27 6.32 26.68 19.31 26.92 19 15.68 13.21 15.84 13c.43-.42 31.13 51.21 31.43 51.82l20.49 61.4 5.42 4.53"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M220.28 563.14L210 557.4 205.36 362c-.22-4.08.65-227.68 2.33-231.3.22-.47 25.32-14.63 25.57-15.08s21.8-.67 22-1 30.59 5.93 30.91 5.47 25.52 18 25.88 17.51c.23-.28 15 12.31 15.26 12 .69-.78 30.21 48.24 30.71 49.15l20.75 60.6 5.14 4.29"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M224.77 562.14L215 556.5l-4.55-188.32c-.28-5.43.85-218.17 3.1-223 .29-.63 24.08-14.18 24.41-14.79.25-.46 20.75-.9 21-1.34.38-.64 29.12 5.23 29.55 4.61s24.37 16.6 24.85 16c.3-.37 14.36 11.41 14.68 11.06.92-1 29.28 45.31 29.95 46.52l21 59.8 4.85 4.05"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M229.25 561.15l-9.19-5.56-4.49-181.18c-.35-6.8 1.05-208.68 3.85-214.71.36-.78 22.85-13.73 23.27-14.49.31-.57 19.68-1.13 20-1.68.48-.8 27.65 4.53 28.19 3.76s23.21 15.24 23.81 14.5c.37-.46 13.71 10.52 14.1 10.08 1.15-1.3 28.36 42.38 29.2 43.9l21.28 59 4.57 3.81"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M233.74 560.17l-8.63-5.47-4.43-174.06c-.42-8.16 1.24-199.17 4.6-206.41.44-.94 21.63-13.29 22.12-14.2.37-.68 18.62-1.35 19-2 .58-1 26.2 3.83 26.84 2.91s22.05 13.88 22.77 13c.45-.55 13.05 9.62 13.53 9.09 1.38-1.56 27.44 39.46 28.45 41.28l21.54 58.19 4.28 3.57"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M238.22 559.19l-8.07-5.38-4.36-166.93c-.49-9.52 1.43-189.67 5.36-198.12.51-1.09 20.39-12.83 21-13.89.43-.8 17.55-1.58 18-2.36.68-1.12 24.73 3.13 25.48 2.06s20.9 12.53 21.74 11.49c.52-.64 12.39 8.73 12.94 8.1 1.61-1.82 26.52 36.53 27.7 38.66l21.8 57.38 4 3.34"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M242.71 558.22l-7.51-5.28-4.3-159.81c-.56-10.88 1.63-180.17 6.11-189.82.58-1.25 19.16-12.38 19.82-13.6.5-.91 16.49-1.81 17-2.7.78-1.27 23.26 2.44 24.12 1.21s19.74 11.18 20.7 10c.6-.74 11.73 7.83 12.37 7.11 1.84-2.07 25.6 33.61 26.94 36l22.07 56.58 3.71 3.09"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M247.19 557.27l-6.95-5.2L236 399.39c-.63-12.23 1.82-170.66 6.86-181.52.66-1.41 17.94-11.94 18.68-13.3.56-1 15.43-2 16-3 .87-1.43 21.79 1.74 22.76.36s18.58 9.82 19.66 8.48c.68-.83 11.08 6.93 11.79 6.13 2.08-2.34 24.68 30.68 26.19 33.41l22.34 55.78 3.42 2.85"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M251.68 556.4l-6.39-5.11-4.17-145.56c-.7-13.58 2-161.16 7.62-173.22.73-1.57 16.7-11.48 17.52-13 .62-1.14 14.37-2.26 15-3.37 1-1.6 20.32 1 21.4-.5s17.42 8.46 18.63 7c.75-.92 10.42 6 11.21 5.14 2.3-2.6 23.76 27.75 25.44 30.79l22.59 55 3.14 2.62"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M256.16 555.85l-5.82-5-4.11-138.45c-.77-14.95 2.21-151.66 8.37-164.93.8-1.72 15.47-11 16.38-12.71.68-1.25 13.3-2.48 14-3.71 1.06-1.75 18.85.34 20-1.34s16.27 7.1 17.59 5.47c.82-1 9.76 5.14 10.63 4.16 2.54-2.86 22.84 24.82 24.69 28.16l22.86 54.17 2.85 2.38"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M260.64 555.31l-5.26-4.93-4-131.31c-.84-16.31 2.4-142.16 9.13-156.63.87-1.88 14.23-10.59 15.23-12.41.74-1.37 12.23-2.72 13-4.05 1.16-1.92 17.39-.36 18.68-2.2s15.11 5.75 16.55 4c.9-1.11 9.11 4.23 10.06 3.16 2.76-3.11 21.92 21.9 23.93 25.54l23.12 53.36 2.57 2.15"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M265.13 554.77l-4.7-4.83-4-124.19c-.91-17.66 2.59-132.65 9.88-148.33.95-2 13-10.14 14.08-12.11.81-1.49 11.17-2.94 12-4.39 1.26-2.08 15.92-1.06 17.32-3.05s14 4.4 15.52 2.47c1-1.2 8.45 3.34 9.47 2.18 3-3.38 21 19 23.18 22.92L381.34 338l2.28 1.91"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M269.61 554.26l-4.14-4.74-3.91-117.06c-1-19 2.79-123.15 10.63-140 1-2.19 11.78-9.68 12.94-11.81.86-1.6 10.1-3.17 11.05-4.73 1.35-2.23 14.45-1.75 16-3.9s12.8 3 14.48 1c1.05-1.29 7.79 2.44 8.9 1.19 3.22-3.64 20.07 16 22.42 20.29l23.65 51.76 2 1.66"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M274.1 553.87l-3.58-4.66-3.85-109.93c-1.05-20.38 3-113.65 11.39-131.74 1.09-2.35 10.54-9.24 11.78-11.52.93-1.71 9.05-3.39 10.06-5.06 1.45-2.4 13-2.45 14.6-4.75s11.64 1.68 13.44-.54c1.13-1.38 7.14 1.54 8.33.21 3.45-3.9 19.15 13.11 21.67 17.67l23.91 50.95 1.71 1.42"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M278.58 554.62l-3-4.57-3.79-102.81c-1.12-21.74 3.17-104.15 12.14-123.45 1.17-2.5 9.32-8.78 10.64-11.21 1-1.83 8-3.63 9.06-5.41 1.54-2.55 11.51-3.15 13.24-5.6s10.48.33 12.41-2c1.2-1.48 6.47.65 7.74-.78 3.68-4.16 18.23 10.18 20.92 15l24.17 50.15 1.43 1.19"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M283.07 555.39l-2.46-4.48-3.72-95.67c-1.19-23.11 3.37-94.65 12.89-115.16 1.24-2.66 8.09-8.33 9.49-10.91 1.06-1.94 6.92-3.86 8.06-5.75 1.65-2.71 10.05-3.84 11.89-6.45s9.33-1 11.37-3.55c1.28-1.56 5.82-.25 7.17-1.76 3.91-4.42 17.31 7.26 20.16 12.42l24.44 49.34 1.14.95"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M287.55 556.22l-1.89-4.39-3.66-88.55c-1.26-24.46 3.56-85.14 13.65-106.86 1.31-2.82 6.85-7.88 8.34-10.62 1.11-2.05 5.85-4.08 7.06-6.08 1.75-2.87 8.59-4.55 10.53-7.3s8.17-2.39 10.34-5.05c1.34-1.66 5.16-1.15 6.58-2.76 4.15-4.67 16.39 4.33 19.41 9.8L382.62 383l.85.71"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M292 557.12l-1.34-4.3-3.59-81.43c-1.33-25.82 3.75-75.64 14.4-98.56 1.39-3 5.62-7.43 7.19-10.32 1.18-2.17 4.79-4.31 6.07-6.42 1.84-3 7.12-5.24 9.17-8.16s7-3.74 9.3-6.55c1.42-1.75 4.5-2.05 6-3.74 4.37-4.94 15.47 1.4 18.66 7.18l25 47.73.57.48"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M296.52 558.14l-.77-4.22-3.53-74.3c-1.4-27.18 3.95-66.13 15.16-90.26 1.45-3.13 4.39-7 6-10 1.24-2.29 3.72-4.54 5.07-6.76 1.94-3.19 5.65-5.94 7.8-9s5.87-5.09 8.27-8.06c1.5-1.84 3.85-2.94 5.43-4.73 4.61-5.19 14.55-1.52 17.91 4.56l25.23 46.93.28.24"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M301 559.33L297.33 488a173.34 173.34 0 0 1 43.41-123.92 10.57 10.57 0 0 1 17.15 1.92l25.49 46.12"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M374.78 399.77l-3.88-7.65c.47-.18-19.16-36.71-18.68-36.78a9.42 9.42 0 0 0-15.65-1.9c-27.07 32.19-40.52 75-37 119.39.06.05.46 6.61.52 6.66l4.85 62.8"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M366.26 387.38l-3.39-7.38c.94-.37-17.2-35.2-16.23-35.34a8.3 8.3 0 0 0-14.15-1.88c-24.17 30.46-35.28 71.69-30.68 114.86.13.1.57 6.38.69 6.48l6.39 61.15"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M357.84 375l-2.84-7.14c1.41-.55-15.24-33.69-13.78-33.91-2.34-5.57-9.21-6.51-12.65-1.85-21.25 28.76-30 68.36-24.32 110.33.19.15.67 6.15.85 6.3l7.9 59.49"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M349.56 362.59l-2.39-6.86c1.87-.74-13.29-32.18-11.35-32.47-1.94-5.3-8.16-6.22-11.14-1.83-18.33 27.06-24.82 65-17.95 105.8.24.19.77 5.92 1 6.12l9.46 57.84"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M341.45 350.2l-1.9-6.6c2.35-.92-11.33-30.68-8.9-31-1.54-5-7.13-5.92-9.64-1.8-15.42 25.37-19.58 61.72-11.59 101.27.31.24.88 5.69 1.18 5.94l11 56.18"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M333.56 337.8l-1.4-6.33c2.81-1.11-9.38-29.17-6.46-29.61-1.15-4.76-6.09-5.63-8.13-1.77-12.52 23.68-14.35 58.4-5.23 96.74.37.29 1 5.46 1.34 5.76l12.53 54.53"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M323.38 325.41l-.91-6.07c3.29-1.29-7.42-27.66-4-28.17-.75-4.49-5.06-5.34-6.63-1.75-9.63 22-9.12 55.07 1.13 92.21.43.34 1.09 5.23 1.51 5.59l14.06 52.86"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M309.9 313l-.41-5.81c3.75-1.48-5.47-26.16-1.57-26.74-.36-4.22-4-5-5.13-1.72-6.74 20.33-3.88 51.75 7.5 87.68.49.38 1.19 5 1.67 5.41l15.59 51.21"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M296.89 300.62l.08-5.54c4.23-1.66-3.5-24.65.88-25.3 0-4-3-4.74-3.63-1.7-3.85 18.68 1.36 48.43 13.87 83.15.55.43 1.3 4.78 1.84 5.23L327.05 406"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M285.32 288.23l.58-5.28c4.69-1.85-1.54-23.15 3.32-23.87.44-3.68-2-4.43-2.12-1.67-1 17.07 6.59 45.1 20.22 78.62.62.48 1.41 4.55 2 5.05L328 389"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M275.71 275.83l1.08-5c5.16-2 .41-21.64 5.76-22.44.83-3.41-.93-4.12-.62-1.64 1.94 15.49 11.82 41.78 26.59 74.09.67.52 1.51 4.31 2.17 4.87l20.19 46.23"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M266.1 263.43l1.57-4.75c5.63-2.22 2.37-20.13 8.21-21 1.22-3.14.11-3.8.88-1.61 4.9 14 17.06 38.45 32.95 69.55.74.58 1.62 4.09 2.34 4.69l21.72 44.58"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M256.49 251l2.07-4.49c6.09-2.4 4.32-18.63 10.65-19.57 1.62-2.87 1.17-3.49 2.38-1.59 7.93 12.4 22.29 35.14 39.32 65 .8.62 1.72 3.86 2.5 4.51l23.26 42.93"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M246.88 238.64l2.56-4.22c6.57-2.59 6.28-17.12 13.09-18.13 2-2.61 2.24-3.19 3.89-1.56 11 10.78 22.86 35.36 45.68 60.49.74.81 1.83 3.63 2.67 4.33l24.79 41.27"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M237.27 226.25l3.06-3.95c7-2.78 8.23-15.62 15.53-16.71 2.42-2.33 3.3-2.88 5.4-1.53 14.09 9.11 28.88 31.66 52 56 .8.85 1.93 3.41 2.83 4.16l26.32 39.61"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M227.66 213.87l3.55-3.7c7.51-3 10.2-14.11 18-15.27 2.81-2.06 4.36-2.59 6.9-1.5 17.16 7.38 34.88 27.91 58.4 51.43.88.88 2 3.17 3 4l27.86 37.95"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M218.05 201.48l4-3.43c8-3.14 12.16-12.6 20.43-13.83 3.21-1.79 5.41-2.31 8.4-1.48 20.19 5.62 40.83 24.12 64.77 46.9 1 .91 2.14 3 3.16 3.8l29.39 36.29"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M208.44 189.08l4.54-3.17c8.44-3.33 14.34-10 22.87-12.4 3.76-1.06 6.46-2 9.9-1.46 23.2 3.87 46.73 20.29 71.13 42.38 1 .94 2.25 2.71 3.33 3.61l30.92 34.64"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M198.83 176.72l5-2.9c8.92-3.51 16.21-8.87 25.32-11 4.08-.94 7.51-1.75 11.4-1.43 26.18 2.12 52.6 16.41 77.5 37.84 1.12 1 2.35 2.49 3.49 3.44l32.46 33"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M189.22 164.39l5.53-2.64c9.38-3.7 18.08-7.73 27.75-9.53a65 65 0 0 1 12.91-1.4c29.15.37 58.41 12.5 83.86 33.31 1.21 1 2.46 2.26 3.66 3.26l34 31.33"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 152.15l6-2.38a137.56 137.56 0 0 1 138.66 22.4l35.52 29.66"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 156.76l6.66-2.34a139.26 139.26 0 0 1 140.39 25.7l34.8 30"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 161.4l7.3-2.31a141 141 0 0 1 142.12 29l34.08 30.25"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 166.07l7.94-2.28a142.89 142.89 0 0 1 143.85 32.33l33.36 30.55"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 170.76l8.57-2.24a144.78 144.78 0 0 1 145.59 35.63L366.41 235"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 175.47l9.21-2.2a146.73 146.73 0 0 1 147.32 38.94l31.92 31.14"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 180.21l9.85-2.18a148.73 148.73 0 0 1 149 42.26l31.2 31.43"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 185l10.49-2.15a150.77 150.77 0 0 1 150.78 45.57l30.48 31.73"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 189.74l11.12-2.11a152.85 152.85 0 0 1 152.51 48.88l29.77 32"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 194.54l11.76-2.08a155 155 0 0 1 154.24 52.19L374.66 277"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 199.35l12.4-2a157.13 157.13 0 0 1 156 55.5l28.33 32.61"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 204.17l13-2A159.36 159.36 0 0 1 350.35 261L378 293.88"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 209l13.67-2a161.61 161.61 0 0 1 159.44 62.12l26.89 33.2"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 213.87l14.31-1.94a163.9 163.9 0 0 1 161.17 65.44l26.17 33.49"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 218.74l14.95-1.9a166.21 166.21 0 0 1 162.9 68.74l25.45 33.79"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 223.63l15.59-1.87a168.55 168.55 0 0 1 164.63 72.05l24.73 34.08"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 228.52l16.23-1.84a171 171 0 0 1 166.36 75.37l24 34.38"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 233.43l16.86-1.81a173.38 173.38 0 0 1 168.09 78.68l23.3 34.7"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 238.35l17.5-1.77a175.82 175.82 0 0 1 169.82 82l22.58 35"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 243.28l18.14-1.74a178.29 178.29 0 0 1 171.55 85.29l21.86 35.26"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 248.21l18.78-1.7a180.8 180.8 0 0 1 173.28 88.61l21.14 35.55"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 253.16l19.39-1.67a183.34 183.34 0 0 1 175 91.92l20.42 35.85"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 250.93l22.58 2.07a180.77 180.77 0 0 1 169.48 91.35l21.63 38.88"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 249.88l25.74 5.89c66.85-3.42 130.58 31.64 163.94 90.77l22.85 41.92"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 248.83l28.9 9.66c64.64-2.05 126.06 32.59 158.41 90.21l24.07 45"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 247.78l32.07 13.44c62.42-.7 121.54 33.53 152.87 89.63l25.28 48"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 246.73l35.23 17.21c60.21.66 117 34.48 147.34 89.07l26.49 51"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 245.67l38.39 21c58 2 112.52 35.42 141.81 88.49l27.71 54.07"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 244.62l41.56 24.77c55.78 3.38 108 36.37 136.26 87.93l28.93 57.1"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 243.57l44.72 28.54c53.57 4.75 103.48 37.32 130.73 87.36l30.14 60.14"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 242.52l47.89 32.32c51.34 6.1 99 38.26 125.19 86.79l31.36 63.17"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 241.46l51 36.1c49.13 7.47 94.45 39.21 119.66 86.22L382.89 430"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 240.41l54.21 39.88c46.92 8.82 89.93 40.15 114.12 85.65l33.79 69.25"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 239.36L237 283c44.7 10.18 85.41 41.11 108.58 85.08l35 72.29"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 238.31l60.54 47.43c42.49 11.54 80.9 42.05 103 84.51l36.22 75.32"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 237.26l63.7 51.2c40.28 12.9 76.39 43 97.52 83.94l37.43 78.36"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 236.2l66.87 55c38.06 14.26 71.86 43.94 92 83.37L377.1 456"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 235.15l70 58.76c35.85 15.62 67.35 44.89 86.44 82.8L376 461.14"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 234.1l73.19 62.54c33.64 17 62.84 45.83 80.91 82.23l41.08 87.46"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 233.05L256 299.36A193.05 193.05 0 0 1 331.34 381l42.29 90.51"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 232l79.52 70.1A205.44 205.44 0 0 1 329 383.18l43.51 93.54"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 230.94l82.69 73.87a222.89 222.89 0 0 1 64.29 80.52l44.73 96.58"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.61 229.89l85.85 77.65a247.58 247.58 0 0 1 58.76 79.95l45.94 99.61"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M179.78 242.64l84.81 74.42A241.48 241.48 0 0 1 323 394.12l45 95.67"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M180 255.39l83.77 71.2a235.11 235.11 0 0 1 58.07 74.16l44.15 91.74"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M180.12 268.14l82.72 68a228.71 228.71 0 0 1 57.73 71.27l43.26 87.8"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M180.29 280.89L262 345.64A222.64 222.64 0 0 1 319.36 414l42.36 83.87"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M180.46 293.65l80.64 61.51a216.5 216.5 0 0 1 57 65.48l41.47 79.93"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M180.63 306.4l79.6 58.29a210.69 210.69 0 0 1 56.7 62.58l40.57 76"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M180.8 319.15l78.56 55.06a204.79 204.79 0 0 1 56.35 59.68L355.39 506"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M181 331.9l77.51 51.84a198.87 198.87 0 0 1 56 56.78l38.8 68.13"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M181.15 344.65l76.47 48.61a193.24 193.24 0 0 1 55.66 53.89l37.9 64.2"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M181.32 357.4l75.42 45.39a187.43 187.43 0 0 1 55.32 51l37 60.26"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M181.49 370.15l74.38 42.16a182 182 0 0 1 55 48.1L347 516.73"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M181.66 382.9L255 421.83A176.88 176.88 0 0 1 309.63 467l35.22 52.39"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M181.83 395.65l72.3 35.71a171.41 171.41 0 0 1 54.28 42.31l34.33 48.45"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M182 408.4l71.26 32.48a166.66 166.66 0 0 1 53.94 39.42l33.43 44.52"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M182.17 421.15l70.22 29.26A161.54 161.54 0 0 1 306 486.93l32.54 40.58"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M182.34 433.91l69.18 26a157 157 0 0 1 53.24 33.63l31.65 36.64"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M182.51 446.66l68.13 22.8a152.35 152.35 0 0 1 52.91 30.73l30.75 32.71"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M182.68 459.41L249.77 479a148.12 148.12 0 0 1 52.56 27.84l29.86 28.77"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M182.86 472.16l66 16.35a144 144 0 0 1 52.22 24.94l29 24.83"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M183 484.91L248 498a140.28 140.28 0 0 1 51.87 22.05L328 541"
            transform="translate(-179.06 -60.65)"
          />
          <path
            d="M183.2 497.66l64 9.9a136.66 136.66 0 0 1 51.52 19.15l27.18 17"
            transform="translate(-179.06 -60.65)"
          />
        </svg>
      </div>
      <div
        className={cx(styles.BrainGlow, {
          [styles.BrainGlowHover]: hoveredNode && hoveredNode.color
        })}
        style={BrainGlowColor}
      />
    </div>
  );
};

export default Brain;
