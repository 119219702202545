import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

const ArrowRight = ({
  disabled,
  hidden
}: {
  disabled: boolean;
  hidden: boolean;
}) => (
  <svg width="10" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.001.418L.87 1.582 7.103 8 .87 14.418 2 15.582 9.367 8z"
      fill="#FFF"
      className={cx(styles.Arrow, {
        [styles.Disabled]: disabled,
        [styles.Hidden]: hidden
      })}
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowRight;
