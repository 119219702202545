import React from "react";

import styles from "./index.module.scss";

import DimensionIcon from "../../../../components/DimensionIcon";

import DataPointCard from "../../../../components/DataPointCard";
import { OverviewConsumer } from "../../../../contexts/Overview";

import {
  getSkillDomain,
  getFluencyCount
} from "../../../../helpers/skillDataFetchers";

import { DepartmentType } from "../../../../types";

type PropsType = {
  department: DepartmentType;
  skillId?: string;
};

const SkillScoreCard = ({ department, skillId }: PropsType) => {
  const { skillData } = React.useContext(OverviewConsumer);
  if (!skillData) {
    return null;
  }

  let domain: string = "head";
  let goalCount: number = 0;
  let fluentCount: number = 0;
  let learningCount: number = 0;

  if (skillId) {
    domain = getSkillDomain(skillId, skillData);
    goalCount =
      department === "allCompany"
        ? getFluencyCount(skillId, department, "goal", skillData)
        : undefined;
    fluentCount = getFluencyCount(skillId, department, "fluent", skillData);
    learningCount = getFluencyCount(skillId, department, "learning", skillData);
  }

  return (
    <div className={styles.Wrapper}>
      <DataPointCard noBorder heading={domain}>
        <span className={styles.Padded}>
          <DimensionIcon dimension={domain} />
        </span>
      </DataPointCard>
      <DataPointCard heading="fluent" data={fluentCount} />
      <DataPointCard heading="learning" data={learningCount} />
      {goalCount !== undefined ? (
        <DataPointCard heading="goal" data={goalCount} />
      ) : null}
    </div>
  );
};

export default SkillScoreCard;
