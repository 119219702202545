import React from "react";

type PropsType = {
  className?: string;
};

const HeadIcon = ({ className = "" }: PropsType) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
  >
    <path fill="#1A6D86" fillRule="evenodd" d="M7 0L0 7l7 7 7-7z" />
  </svg>
);

export default HeadIcon;
